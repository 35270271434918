import React from "react";

function MailConfigurationTab(props) {
  const {
    messageBirthdayData,
    messageBirthdayDataSet,
    messageRegisterData,
    messageRegisterDataSet,
  } = props;

  // Helper function to handle null values
  const handleNullValue = (data) => (data ? data.replaceAll("\\n", "\n") : '');

  return (
    <div className="tab-body">
      <label htmlFor="msgRegister">Message d'enregistrement</label>
      <textarea
        value={handleNullValue(messageRegisterData)}
        onChange={(e) => {
          messageRegisterDataSet(e.target.value);
        }}
        name="msgRegister"
        className="input-light"
        rows={10}
      />
      <label htmlFor="msgBirthday">Message d'anniversaire</label>
      <textarea
        name="msgBirthday"
        value={handleNullValue(messageBirthdayData)}
        onChange={(e) => {
          messageBirthdayDataSet(e.target.value);
        }}
        rows={10}
        className="input-light"
      />
    </div>
  );
}


export default MailConfigurationTab;
