import { HiOutlineUserCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

function MessageBox() {
  const navigate = useNavigate();

  return (
    <div className="message-box">
      <div className="user-info">
        <span className="user-name">User 1</span>
        <span className="date-message">4hr ago</span>
      </div>
      <div className="desc">
        <HiOutlineUserCircle />
        <p>Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text. Sample text. Click to select the text box.  Click again or double click to start editing the text.</p>
      </div>
      <div className="btn-left">
        <button onClick={() => navigate('/expert/conversation')}>Répondre</button>
      </div>
    </div>
  );
}

export default MessageBox;