import CalendarJs from "calendar-js";

const monthNamesFr = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

const weekNamesFr = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

const calendar = CalendarJs({ months: monthNamesFr, weeks: weekNamesFr });

const getMonthIndexFromName = (monthName) => {
  return monthNamesFr.findIndex((month) => month === monthName);
};

const getCurrentMonth = () => {
  const today = new Date();
  return calendar.detailed(today.getUTCFullYear(), today.getUTCMonth());
};

const getCurrentWeek = () => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  return calendar
    .detailed(todayDate.getUTCFullYear(), todayDate.getUTCMonth())
    .calendar.find((week) =>
      week.some((day) => {
        day.date.setHours(0,0,0,0)
        return todayDate.getTime() === day.date.getTime()
      })
    );
};

const getNextMonth = (currentYear, currentMonth) => {
  if (currentMonth <= 10) {
    return calendar.detailed(currentYear, currentMonth + 1);
  } else {
    return calendar.detailed(currentYear + 1, 0);
  }
};

const getPreviousMonth = (currentYear, currentMonth) => {
  if (currentMonth >= 1) {
    return calendar.detailed(currentYear, currentMonth - 1);
  } else {
    return calendar.detailed(currentYear - 1, 11);
  }
};

const findCurrentWeekFromMonth = (currentMonth) => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  return currentMonth.calendar.findIndex((week) =>
    week.some((day) => {
      day.date.setHours(0,0,0,0)
      return todayDate.getTime() === day.date.getTime()
    })
  );
};

const getWeekByIndex = (weekIndex, month) => {
  return month.calendar[weekIndex];
};

const getDayName = (index) => {
  return weekNamesFr[index];
};

const getMonthName = (index) => {
  return monthNamesFr[index];
};


export {
  calendar,
  getCurrentMonth,
  getNextMonth,
  getPreviousMonth,
  getMonthIndexFromName,
  findCurrentWeekFromMonth,
  getWeekByIndex,
  getDayName,
  getMonthName,
  getCurrentWeek
};
