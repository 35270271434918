import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const fireSuccessAlert = (message) => {
    return MySwal.fire({
        title: "Parfait",
        text: message,
        icon: "success",
      });
};

export const fireInfoAlert = (message) => {
  return MySwal.fire({
      text: message,
      icon: "info",
    });
};

export const fireErrorAlert = (message) => {
    return MySwal.fire({
      title: "Oups...",
      text: message,
      icon: "error",
    }).then(()=>{
      const currentURL = window.location.href;
      if (currentURL.endsWith("/call")) {
        window.location.replace(currentURL.slice(0, -5));
      }
    });
  };
