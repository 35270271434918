import apiClient from "../apiClient";

export const promotionClient = {
  getAll: () => apiClient.get("/promotionals/admins/all/view/"),
  get: (promotionalId) =>
    apiClient.get("/promotionals/admins/view/", {
      params: { productId: promotionalId },
    }),
  add: ({
    codeName,
    promotionalType,
    promotionalEvent,
    promotionalTarget,
    value,
    dayLimit,
  }) =>
    apiClient.put("/promotionals/admins/create/", {
      codeName,
      promotionalType,
      promotionalEvent,
      promotionalTarget,
      value,
      dayLimit,
    }),
  update: ({
    promotionalId,
    codeName,
    promotionalType,
    promotionalEvent,
    promotionalTarget,
    value,
    dayLimit,
  }) =>
    apiClient.post("/promotionals/admins/edit/", {
      promotionalId,
      codeName,
      promotionalType,
      promotionalEvent,
      promotionalTarget,
      value,
      dayLimit,
    }),
  delete: (promotionalId) =>
    apiClient.delete("/promotionals/admins/delete/", { data: { promotionalId} }),
  checkPromoCode: ({codeName, productId}) => apiClient.post('/users/promotionals/products/available', {codeName, productId}),
  mail: {
    checkPromoCode: ({codeName}) => apiClient.post('users/promotionals/mails/available', {codeName}),
  }
};
