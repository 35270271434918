import React from 'react'
import Skeleton from 'react-loading-skeleton';

function BoxConsultationsSekelton() {
    return (
        <div className="box box-consultations">
          <h3
            className="cursor"
          >
            Consultations
          </h3>
          <div className="col-2">
            <div>
              <span className="sub-title-gray">Ce mois-ci</span>
    
              <span className="primary-text"><Skeleton /></span>
              <span className="sub-text">Le mois précédent</span>
              <span className="secondary-text"><Skeleton /></span>
            </div>
            <div>
              <span className="sub-title-gray">Aujourd'hui</span>
    
              <span className="primary-text"><Skeleton /></span>
              <span className="sub-text">Hier</span>
              <span className="secondary-text"><Skeleton /></span>
            </div>
          </div>
        </div>
      );
}

export default BoxConsultationsSekelton