import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExpertBox from "../../components/path/expertBox";
import { profileClient } from "../../network/lib/profile";

function NosExperts() {
  const { officeName, profileUrl } = useParams();

  const [expertListData, expertListDataSet] = useState([]);
  const [listTypeData, listTypeDataSet] = useState("ALL");

  useEffect(() => {
    fetchExperts(listTypeData);

    const intervalId = setInterval(() => {
      fetchExperts(listTypeData);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [listTypeData]); // Adding listTypeData as dependency

  const fetchExperts = (expertType) => {
    listTypeDataSet(expertType);
    profileClient.expert
      .getByCategory(expertType, officeName)
      .then((res) => expertListDataSet(res.data.experts));
  };

  return (
    <div id="nosExperts">
      <div className="group-data">
        <div className="tags">
          <span onClick={() => fetchExperts("ALL")}>Tous</span>
          <span onClick={() => fetchExperts("TAROLOGUES")}>Tarologues</span>
          <span onClick={() => fetchExperts("MEDIUMS")}>Médiums</span>
          <span onClick={() => fetchExperts("VOYANTS")}>Voyants</span>
          <span onClick={() => fetchExperts("ASTROLOGUES")}>Astrologues</span>
          <span onClick={() => fetchExperts("RADIESTHESISTES")}>
            Radiesthésistes
          </span>
          <span onClick={() => fetchExperts("NUMEROLOGUES")}>Numérologues</span>
          <span onClick={() => fetchExperts("CARTOMANCIENS")}>Cartomanciens</span>
        </div>
        <div className="blocks hook">
          {expertListData.map((expert, index) => {
            return (
              <ExpertBox
                key={index}
                id={expert["id"]}
                officeName={officeName}
                name={expert["name"]}
                call={expert["callCount"]}
                category={expert["category"]}
                description={expert["description"]}
                advice={expert["adviceStar"]}
                disponibily={expert["statue"]}
                benefits={expert["benefits"]}
                imageProfile={expert["imageProfile"]}
                imageBanner={expert["imageBanner"]}
                profileUrl={profileUrl}
              />
            );
          })}
        </div>
      </div>
      <footer>
        A service provided by <a href="https://vooxhub.com" target="_blank">VooxHub</a> - 2023 © All rights reserved <br/>
        Developed by <a href="https://shield4Cloud.com" target="_blank">Shield4Cloud</a>
      </footer>
    </div>
  );
}

export default NosExperts;
