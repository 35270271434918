import React, { useContext } from "react";
import Day from "./Day";
import { GrNext, GrPrevious } from "react-icons/gr";
import { CalendarContext } from "../context/CalendarContext";

function formatDateToCustomString(date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}/${month}/${day}`;
}

const Week = ({ weekData, handlePreviousWeekClick, handleNextWeekClick }) => {
  const { displaySlots, availableSlots } =
    useContext(CalendarContext);
  return (
    <div className="scheduler__days">
      <button className="previous-btn previous-btn__week">
        <GrPrevious onClick={handlePreviousWeekClick} />
      </button>
      {weekData &&
        weekData.map((dayData, index) => {
          const apiFormatDateKey = formatDateToCustomString(dayData.date);
          return (
            <Day
              key={index}
              availableSlots={availableSlots[apiFormatDateKey]}
              dayData={{ ...dayData, slots: displaySlots[index] }}
            />
          );
        })}
      <button className="next-btn next-btn__week" onClick={handleNextWeekClick}>
        <GrNext />
      </button>
    </div>
  );
};

export default Week;
