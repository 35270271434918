import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomLoader from "../components/skeletons/customLoader";
import { fetchOfficeData } from "../redux/features/office/officeSlice";
import { fireSuccessAlert } from "./../components/utils/Swal";
import { authenticationClient } from "./../network/lib/authentication";

function LoginPage() {
  const { officeName } = useParams();

  //   const [officeData, officeDataSet] = useState([]);
  const officeState = useSelector((state) => state.office);
  const dispatch = useDispatch();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  function onLogin(e) {
    const auth = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      officeName: officeName,
    };

    authenticationClient.login(auth).then((resp) => {
      const token = resp.data.token;

      if (token !== undefined && token !== null) {
        localStorage.setItem("sessionId", token);

        // const MySwal = withReactContent(Swal);

        //           MySwal.fire({

        //               title: "Parfait",
        //               text: "Vous avez bien été identifier !",
        //               icon: 'success'

        //           })
        fireSuccessAlert("Vous avez bien été identifié !").then(() => {
          const userType = resp.data.userType;

          switch (userType) {
            case "CLIENT":
              window.location.href = "/" + officeName + "/client";
              break;
            case "ADMIN":
              window.location.href = "/" + officeName + "/admin";
              break;
            case "EXPERT":
              window.location.href = "/" + officeName + "/expert";
              break;

            default:
              window.location.href = "/" + officeName + "/client";
              break;
          }
        });
      }
    });
    //       .catch((error) => {

    // 	if (error.response) {

    // 		const MySwal = withReactContent(Swal)

    //     MySwal.fire({
    //       title: "Oups...",
    //       text: error.response.data.message,
    //       icon: "error",
    //     });
    //   }

    //   console.log({ ...error });
    // });
  }

  useEffect(() => {
    dispatch(fetchOfficeData(officeName));
  }, []);

  if (officeState.loading) return <CustomLoader />;
  // if(!officeState.loading && officeState.error) return <h1>Error : {officeState.error}</h1>

  return (
    <div id="loginPage">
      <div className="content">
        <img
          src={
            officeState.data["logoImage"] == null
              ? "/assets/logo.svg"
              : officeState.data["logoImage"]
          }
          alt="Logo cabinet"
        />
        <h2>Content de vous revoir!</h2>
        <span className="sub-title">Entrez vos données</span>

        <div className="form">
          <div className="form-group">
            <label>E-mail</label>
            <input
              placeholder="Entrer votre email"
              ref={emailRef}
              type="email"
            />
          </div>
          <div className="form-group">
            <label>Mot de passe</label>
            <input
              placeholder="Entrer votre password"
              ref={passwordRef}
              type="password"
            />
          </div>
          <input
            type="submit"
            value="CONNEXION"
            onClick={onLogin}
            className="submit"
          />
        </div>
        <a href={"/" + officeName + "/password"} className="btn">
            Mot de passe oublié ?
        </a>
        <a href={"/" + officeName + "/register"} className="btn br">
          Inscription
        </a>
        <a className="link" href={officeState.data["urlMain"]}>
          Revenir au site
        </a>
      </div>
      <div
        className="bg"
        style={{
          backgroundImage: `url(` + officeState.data["bannerImage"] + `)`,
        }}
      >
      </div>
    </div>
  );
}

export default LoginPage;
