import axios from "axios";
import { createContext, useEffect, useReducer } from "react";
import {
  calendar,
  getCurrentMonth,
  getCurrentWeek
} from "../calendar/calendar";
import { appointmentClient } from './../../../network/lib/appointment';
import { reducer } from "./Reducer";

const initialState = {
  calendar: calendar,
  booking: [],
  availableSlots:[],
  displayWeek: [],
  displayMonth: [],
  displaySlots: [],
};

export const CalendarContext = createContext(initialState);

export const CalendarProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    setDisplayMonth(getCurrentMonth());
    setDisplayWeek(getCurrentWeek());
    // setDisplaySlots(getCurrentWeek());
  }, []);

  // Actions
  const addBooking = async (booking) => {
    // try {
      // const response = await axios.post(
      //   "http://localhost:3300/bookings",
      //   booking
      // );
      dispatch({ type: "ADD_BOOKING", payload: booking });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  // const fetchAllBookings = async ({expertId, productId,dates}) => {
  //   try {
  //     const response = await appointmentClient.slot.getAllAvailable({expertId, productId,dates})
  //     // console.log(response.data);
  //     dispatch({ type: "FETCH_ALL_AVAILABLE_SLOTS", payload: response.data.slots });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchAllAvailableSlots = async ({expertId, productId,dates}) => {
    try {
      const response = await appointmentClient.slot.getAllAvailable({expertId, productId,dates})
      // console.log(response.data);
      dispatch({ type: "FETCH_ALL_AVAILABLE_SLOTS", payload: response.data.slots });
    } catch (error) {
      console.log(error);
    }
  };

  // const deleteBooking = async (bookingId) => {
  //   try {
  //     const response = await axios.delete(
  //       `http://localhost:3300/bookings/${bookingId}`
  //     );
  //     console.log(response);
  //     dispatch({ type: "DELETE_BOOKING", payload: bookingId });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const setDisplayWeek = (week) => {
    dispatch({
      type: "SET_DISPLAY_WEEK",
      payload: week,
    });

    // setDisplaySlots(week);
  };

  const setDisplayMonth = (month) => {
    dispatch({
      type: "SET_DISPLAY_MONTH",
      payload: month,
    });

    setDisplayWeek(month.calendar[0]);
  };

  // const setDisplaySlots = (week) => {
  //   dispatch({
  //     type: "SET_DISPLAY_SLOTS",
  //     payload: week,
  //   });
  // };

  const value = {
    calendar: state.calendar,
    booking: state.booking,
    availableSlots: state.availableSlots,
    displayWeek: state.displayWeek,
    displayMonth: state.displayMonth,
    displaySlots: state.displaySlots,
    addBooking,
    fetchAllAvailableSlots,
    setDisplayWeek,
    setDisplayMonth,
    // setDisplaySlots,
  };

  return (
    <CalendarContext.Provider value={value}>
      {children}
    </CalendarContext.Provider>
  );
};
