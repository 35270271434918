import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

import ClientListTableLogic from "../../components/react-tables/clientListTable/clientListTableLogic";

function ClientListPage() {
  return (
    <div id="clientList">
      <div className="top-bar">
        <h2>Clients</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Client
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Compte
          </Link>
        </div>
      </div>

      {/* <ClientlistTable /> */}
      <ClientListTableLogic />
    </div>
  );
}

export default ClientListPage;
