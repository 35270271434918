import { useParams } from "react-router-dom";
import ForfaitBox from "../../components/path/forfaitBox";
import { useEffect, useState } from "react";
import { productClient } from "../../network/lib/product";

function ForfaitsPage() {

	const { officeName } = useParams();

	const [productListData, productListDataSet] = useState([]);

	useEffect(() => {

		// axios
        // .get(
        //     "https://api.vooxhub.com/products/offices/all/view/?productType=CALL&officeName=" + officeName, null
        // )
		productClient.getByCategory("CALL",officeName)
        .then((resp) => {
          productListDataSet(resp.data.products);
        })
        // .catch((error) => {
        //     //window.location.href = "https://vooxhub.com";
        // });

	}, []);

  	return (
		<div id="forfaitsPath">
			<div className="container">
				<div className="blocks">
					{productListData.map((product, index) => {
                        return (
                          	<ForfaitBox key={index} 
								officeName={officeName}
								image={product["image"]}
								name={product["name"]}
								description={product["description"]}
								subdescription={product["subdescription"]}
								price={product["price"]}
								time={product["minute"]}
							/>
                      	)
                    })}
				</div>
			</div>
			<footer>
				A service provided by <a href="https://vooxhub.com" target="_blank">VooxHub</a> - 2023 © All rights reserved <br/>
				Developed by <a href="https://shield4Cloud.com" target="_blank">Shield4Cloud</a>
			</footer>
		</div>
	);
}

export default ForfaitsPage;