import React, { useRef, useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { adviceClient } from "../../../network/lib/advice";
import { fireSuccessAlert } from "../../utils/Swal";
import Modal from "../modal";

const initStarArr = Array.from({ length: 5 }, (_, index) => false);

function RatingModal({call, showModal, setShowModal}) {
  const [fillStar, setFillStar] = useState(initStarArr);
  const [message, setMessage] = useState("");
  const modalRef = useRef(null);

  const handleFillStar = (index) => {
    setFillStar((fillStar) =>
      fillStar.map((elem, currIndex) => {
        if (index > 0) {
          return currIndex <= index ? true : false;
        } else if (index === 0) {
          return currIndex === index ? (elem ? elem : false) : false;
        }
      })
    );
  };

  const countRating = (ratingArr) => {
    return ratingArr.reduce((sum,rating) => {
      if(rating) {
        return sum + 1;
      }
      return sum
    },0)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      expertId: call.expertId,
      message,
      note: countRating(fillStar)
    }

    adviceClient.create(formData).then(res => {
      setShowModal(false);
      fireSuccessAlert("Avis créé")
    })

    setMessage("");
    setFillStar(Array.from({ length: 5 }, (_, index) => false));
    
  };

  console.log(call)
  return (
    <>
      <Modal
        ref={modalRef}
        title="Votre avis est important pour nous"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="60%"
      >
        <div className="flex-column">
          <div className="flex-row flex-align-center mb-2">
            <p className="star-rating-text">Donne une note {"  "}</p>
            <span className="flex-row align-center star-icon-group">
              {fillStar.map((elem, index) => (
                <div
                  key={index}
                  className="flex-row align-center"
                  onClick={() => handleFillStar(index)}
                >
                  {elem ? <AiFillStar /> : <AiOutlineStar />}
                </div>
              ))}
            </span>
          </div>

          <form className="flex-column rating-form" onSubmit={handleSubmit}>
            <label>Laisser un message</label>
            <textarea
              rows={10}
              style={{ maxWidth: "100%" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />

            <button
              className="gray-button rating-form__button"
              type="submit"
            >
              Envoyer
            </button>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default RatingModal;
