import { useState, Ref, useEffect } from "react";
import { Icon } from "@iconify/react";
import CreditCardExpiration from "./CreditCardExpiration";

function NewCard(props) {

  const [cardNumberData, cardNumberDataSet] = useState();
  const [cardCVVData, cardCVVDataSet] = useState();
  const [cardYearData, cardYearDataSet] = useState();
  const [cardMonthData, cardMonthDataSet] = useState();

  useEffect(() => {

    const cardData = {
      cardNumber: cardNumberData,
      cardCVV: cardCVVData,
      cardYear: cardYearData,
      cardMonth: cardMonthData
    };

    // Maintenant, cardData contient toutes les valeurs des champs d'entrée sous forme d'un objet
    console.log(cardData);

    // Vous pouvez également passer cardData à une fonction de rappel pour effectuer d'autres actions
    props.cardNewSet(cardData);

  }, [cardNumberData, cardCVVData, cardYearData, cardMonthData]);

  return (
    <div id="myMethodsPayementClient">
      <div className="box">
        <span className="title">NOUVELLE CARTE</span>

        <div>
          <div className="form-group">
            <label>Numéro de carte</label>
            <div class="input-container">
              <div className="icon">
                <Icon icon="ic:baseline-payment" color="#8AC923" />
              </div>
              <input
                className="input-field no-anime"
                type="text"
                value={cardNumberData}
                onChange={(e) => {
                  cardNumberDataSet(e.target.value);
                }}
                name="nbCard"
              />
            </div>
          </div>
          <div className="col-2">
            <CreditCardExpiration setMonth={cardMonthDataSet} 
              setYear={cardYearDataSet}/>
            <div className="form-group">
              <label>CVC</label>
              <div class="input-container">
                <div className="icon">
                  <Icon icon="material-symbols:lock" color="#8AC923" />
                </div>
                <input
                  className="input-field no-anime"
                  value={cardCVVData}
                  onChange={(e) => {
                    cardCVVDataSet(e.target.value);
                  }}
                  maxLength={"3"}
                  type="text"
                  name="cvc"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-flex">
          <Icon icon="logos:visaelectron" />
          <Icon icon="logos:mastercard" />
        </div>
        <div className="col-flex">
          <Icon icon="material-symbols:lock" color="#F5CE00" />
          <span>Paiement sécurisé</span>
        </div>
      </div>
    </div>
  );
}

export default NewCard;
