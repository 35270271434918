import { HiOutlineUserCircle } from "react-icons/hi";
import { Icon } from "@iconify/react";
import { useLocation, useNavigate } from "react-router-dom";
import customHistory from "../../router/customHistory";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function ConversationClient() {

  const officeState = useSelector((state) => state.office);
  const navigate = useNavigate();
  const location = useLocation();

  const [mail, setMail] = useState();

  useEffect(() => {

    if (location.state) {

      setMail(location.state.data);

      console.log(location.state.data);
    
    } else {

      customHistory.back();

    }

  }, []);

  const redirect = () => {navigate(`/${officeState.data.officeName}/client/mails`);};

  return (
    <div id="conversation">
      <div className="top-bar-back">
        <Icon
          icon="humbleicons:arrow-go-back"
          onClick={() => redirect()}
        />
      </div>
      <div className="chat-box">
        <div className="top-bar">
          <div className="user">
            <HiOutlineUserCircle />
            <div className="user-name">
              {mail ? <span className="name">{mail["expertName"]}</span> : ""}
              <span className="actif">Expert</span>
            </div>
          </div>
        </div>
      </div>
      <div className="zone">
          {
            mail ? <div className="converssation">

            {
              mail["messageExpert"] ? <div className="disc left">
                <span className="date">{mail["date"]}</span>
                <div className="bulle">
                  {mail["messageExpert"]}
                </div>
              </div> : ""
            }

            <div className="disc">
              <span className="date">{mail["date"]}</span>
              <div className="bulle">
                {mail["messageClient"]}
                {mail["image"] ? <img alt={"client"} src={mail["image"]} /> : ""}
              </div>
            </div>
            
          </div> : ""
          }
      </div>
    </div>
  );
}

export default ConversationClient;
