import React from "react";
import { useState } from "react";
import { MultiSelect } from "primereact/multiselect";
import { useEffect } from "react";

const DropDownChipSelect = ({ expert, benefits, updateBenefits }) => {
  const [value, setValue] = useState([]);
  const options = [
    {
      label: "CALL",
    },
    {
      label: "MEET",
    },
    {
      label: "CHAT",
    },
    {
      label: "MAIL",
    },
  ];
  console.log(benefits);

  const handleBenefitsChange = (e) => {
    setValue(e.value);
    // console.log(e.value.map(item => item.label))
    updateBenefits({ expertId: expert?.id, benefits: e.value.map(item => item.label) });
  };

  useEffect(() => {
    if (benefits && benefits.length > 0) {
      setValue(benefits.map((benefit) => ({ label: benefit })));
    }
  }, []);

  console.log(value);
  return (
    <div className="dropdown-chip">
      <MultiSelect
        value={value}
        onChange={handleBenefitsChange}
        options={options}
        optionLabel="label"
        display="chip"
        placeholder="Ajouté un service"
        maxSelectedLabels={4}
        className="w-full md:w-20rem"
      />
    </div>
  );
};

export default DropDownChipSelect;
