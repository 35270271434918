import React from "react";
import { useState } from "react";

import "./tab.scss";

function TabGroup({ tabs }) {
  const [active, setActive] = useState(tabs[0].title);

  const renderOutlet = (active) => {
    return tabs.filter((tab) => tab.title === active)[0].body;
  };

  return (
    <div className="tab-group">
      <div className="flex-row tab-row">
        {tabs.map(({ title }) => (
          <button
            key={title}
            onClick={() => {
              setActive(title);
            }}
            className={`tab ${active === title ? "active" : ""}`}
          >
            {title}
          </button>
        ))}
      </div>

      <div className="tab-body">{renderOutlet(active)}</div>
    </div>
  );
}

export default TabGroup;
