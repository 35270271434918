import { Icon } from "@iconify/react";
import LogoutHandler from "../../utils/logout";


function BatMenuOwner(props) {

    const { navigation } = props;
  
    return (
      <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
        <div>
          <span className="title">FONDATEUR</span>
          <ul className="menu">
            <li
              className="selected"
              onClick={() => navigation("/" + props.officeName + "/owner/")}
            >
              <Icon icon="ion:logo-tableau" />
              <span>Cabinets</span>
            </li>
            <li
              onClick={() => navigation("/" + props.officeName + "/owner/")}
            >
              <Icon icon="fluent-mdl2:product-list" />
              <span>Factures</span>
            </li>
          </ul>
        </div>
  
        <div>
          <span className="title">AUTRE</span>
          <ul className="menu">
            <LogoutHandler officeName={props.officeName} />
          </ul>
        </div>
      </div>
    );
  }

  export default BatMenuOwner;