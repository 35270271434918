import React from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { useState } from "react";
import { fireErrorAlert, fireSuccessAlert } from "./../utils/Swal";
import { uploadClient } from "../../network/lib/upload";

function PaymentConfirmS4C(props) {
  const {
    paymentUrl,
    paymentData,
    paymentCardId,
    paymentCardNew,
    codeName,
    imageFile,
  } = props;
  const [disabledButton, setDisabledButton] = useState(false);

  const validatePayment = () => {
    const config = {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    };

    paymentData.codeName = codeName;

    if (paymentUrl.includes("mails") && !paymentData.expertId) {
      fireErrorAlert(
        "Désolé, vous avez oublié de cliquer sur le bouton validé ci-dessus."
      );
      return;
    }

    setDisabledButton(true);

    axios
      .put("https://api.vooxhub.com/" + paymentUrl, paymentData, config)
      .then((resp) => {
        const data = resp.data;
        if (imageFile) {
          uploadClient
            .mailUploadUpload({
              transactionId: data.transactionId,
              file: imageFile,
            })
            .then(() => {
              window.location.href = data.redirect;
            });
        } else {
          window.location.href = data.redirect;
        }
      })
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
        console.log(error);
        setDisabledButton(false);
      });
  };

  console.log(paymentData);

  return (
    <>
      <div className="col-2-button">
        <button
          disabled={disabledButton}
          className="valide"
          onClick={() => validatePayment()}
        >
          Payer
        </button>
        <div className="col-flex">
          <Icon icon="material-symbols:lock" color="#F5CE00" />
          <span>Paiement sécurisé</span>
        </div>
      </div>
    </>
  );
}

export default PaymentConfirmS4C;
