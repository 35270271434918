import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fireSuccessAlert } from "./../../../components/utils/Swal";
import { productClient } from "./../../../network/lib/product";
import { uploadClient } from "../../../network/lib/upload";

function NewPackage() {
  const navigate = useNavigate();
  const officeState = useSelector((state) => state.office);

  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [subdescription, setSubdescription] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [price, setPrice] = useState("");
  const [minutes, setMinutes] = useState("");

  const options = [{ type: "CALL" }, { type: "MEET" }];

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let formData = {
      title: name,
        description,
        subdescription,
        productType: selectedTypeOption.type,
        price,
        minute: minutes,
    }

    if(imageFile && imageFile.size < 2097152) {

      productClient.add(formData).then((res) => {

        fireSuccessAlert("Forfait ajouté avec succès");

        uploadClient.productUpload({
          productId : res.data.productId,
          file : imageFile
        }).then((res) => {
            fireSuccessAlert("Envois de l'image éffectuer !");
        });
        navigate(`/${officeState.data.officeName}/admin/configurationPackage`);

      });

    }
  };

  return (
    <div>
      <h2 className="mb-2">Nouveau Forfait</h2>
      <form className="new-package-form" onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="nom">Nom</label>
          <input
            type="text"
            id="nom"
            name="nom"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <Dropdown
            value={selectedTypeOption}
            onChange={(e) => setSelectedTypeOption(e.target.value)}
            options={options}
            placeholder="Select Type"
            optionLabel="type"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subdescription">Sous-Description</label>
          <input
            type="text"
            id="subdescription"
            name="subdescription"
            onChange={(e) => setSubdescription(e.target.value)}
            value={subdescription}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            name="image"
            className="custom-file-input"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tarif">Tarif</label>
          <input
            type="text"
            id="tarif"
            name="tarif"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
          />
        </div>
        <div className="form-group">
          <label htmlFor="minutes">Minutes</label>
          <input
            type="text"
            id="minutes"
            name="minutes"
            onChange={(e) => setMinutes(e.target.value)}
            value={minutes}
          />
        </div>

        <button className="primary">Submit</button>
      </form>
    </div>
  );
}

export default NewPackage;
