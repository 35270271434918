import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setBooking } from "../../../redux/features/booking/bookingSlice";
import { CalendarContext } from "../context/CalendarContext";

const Slot = ({ slotData, dayData }) => {
  const dispatch = useDispatch();
  const { booking, addBooking } = useContext(CalendarContext);
  const checkBooked = (calendarId, label) => {
    return slotData.calendarId === calendarId && slotData.label === label;
  };

  const handleSlotClick = () => {
    console.log(slotData.label);
    const newBooking = {
      calendarId: slotData.calendarId,
      slots: slotData.label,
    };
    dispatch(setBooking(newBooking));
    addBooking(newBooking);
  };

  return (
    <div
      className={`scheduler__slot ${
        checkBooked(booking.calendarId, booking.slots) && "booked"
      } `}
      onClick={handleSlotClick}
    >
      <span>{slotData.label}</span>
    </div>
  );
};

export default Slot;
