import { LayoutClient } from "../../components/layout/layout";
import MeetingTable from "../../components/tables/meetingTable";
import { useParams } from "react-router-dom";

function MyMeetingClient() {
  return (
    <div id="myMeetingClient">
      <h2>Mes Rendez-Vous</h2>

      <MeetingTable />
    </div>
  );
}

export default MyMeetingClient;
