import { useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import InfoMessageBoxTD from "../react-tables/utils/infoMessageBox";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import { userClient } from "./../../network/lib/user";

function MeetingTable() {
  const [userAppointmentData, userAppointmentDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userClient.client.appointment
      .getAll()
      .then((resp) => {
        userAppointmentDataSet(resp.data.appointments);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  if (isLoading) return <TableSkeleton columnCount={5} rowCount={4} />;
  return (
    <div className="tableContainer">
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>PRESTATION</Th>
            <Th>N°COMMANDE</Th>
            <Th>EXPERT</Th>
            <Th>ÉTAT</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userAppointmentData.length === 0 ? (
            <InfoMessageBoxTD colSpan={5} />
          ) : (
            userAppointmentData.map((appointment, index) => {
              return (
                <Tr key={index}>
                  <Td className="color-red">{appointment["date"]}</Td>
                  <Td>{appointment["productName"]}</Td>
                  <Td>{appointment["orderId"]}</Td>
                  <Td className="color-green">{appointment["expertName"]}</Td>
                  <Td className="color-purple">{appointment["state"]}</Td>
                </Tr>
              );
            })
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export default MeetingTable;
