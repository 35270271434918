import React from "react";
import "./availability-chip.scss";

import { FaDotCircle } from "react-icons/fa";
import { useState } from "react";
import { userClient } from "./../../network/lib/user";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function AvailabilityChip() {
  // const officeState = useSelector((state) => state.office);
  const user = useSelector((state) => state.user);
  const [online, setOnline] = useState(false);

  const handleAvailability = () => {
    userClient.expert.activity
      .setActivity(online ? "ON_BREAK" : "AVAILABLE")
      .then((res) => {
        setOnline(!online);
      });
  };

  useEffect(() => {
    const activity = user.data.activity;
    setOnline(activity === "AVAILABLE" ? true : false);
  }, []);

  return (
    <div className="chip" onClick={handleAvailability}>
      <span>{online ? "Pause" : "Reprendre"}</span>
      <span>
        <FaDotCircle
          style={{ fontSize: "1rem", transform: "translateY(2px)" }}
          className={`${online ? "text-success" : "text-danger"}`}
        />
      </span>
    </div>
  );
}

export default AvailabilityChip;
