import apiClient from "../apiClient";

export const callClient = {
  expert: {
    call: ({ appointmentId }) => apiClient.post("/users/experts/calls/meets/create", { appointmentId }),
  },
  client: {
    call: ({ expertId }) => apiClient.post("/users/calls/create", { expertId }),
  },
  admin: {
    unlock: ({ expertId }) => apiClient.post("/users/admins/experts/call/unlock/", { expertId }),
  },
};
