import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { productClient } from "../../../network/lib/product";
import { fireSuccessAlert } from "../../../components/utils/Swal";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { uploadClient } from "../../../network/lib/upload";

function EditPackage() {
  const navigate = useNavigate();
  const officeState = useSelector((state) => state.office);
  const location = useLocation();

  const [productId, setProductId] = useState("");
  const [selectedTypeOption, setSelectedTypeOption] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [subdescription, setSubdescription] = useState("");
  const [price, setPrice] = useState("");
  const [minutes, setMinutes] = useState("");

  const options = [{ type: "CALL" }, { type: "MEET" }];

  const updatePackage = (formData) => {
    productClient.update(formData).then((res) => {
      fireSuccessAlert("Forfait ajouté avec succès");
      navigate(`/${officeState.data.officeName}/admin/configurationPackage`);
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let formData = {
      productId: productId,
      title: name,
      description,
      subdescription,
      productType: selectedTypeOption.type,
      price,
      minute: minutes,
    };

    //  
    updatePackage(formData);
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      uploadClient.productUpload({productId, file})
        .then((res) => {
          fireSuccessAlert("Modification éffectuer !");
        });
    }
  };

  useEffect(() => {
    if (location) {
      const { id, name, description, subdescription, productType, price, minute } =
        location.state.data;

      console.log(location.state.data);
      setProductId(id);
      setSelectedTypeOption({ type: productType });
      setName(name);
      setDescription(description);
      setPrice(price);
      setMinutes(minute);
      setSubdescription(subdescription);
    }
  }, []);

  return (
    <div>
      <h2 className="mb-2">Nouveau Forfait</h2>
      <form className="new-package-form" onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="nom">Nom</label>
          <input
            type="text"
            id="nom"
            name="nom"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>
        <div className="form-group">
          <label htmlFor="type">Type</label>
          <Dropdown
            value={selectedTypeOption}
            onChange={(e) => setSelectedTypeOption(e.value)}
            options={options}
            placeholder="Select Type"
            optionLabel="type"
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            id="description"
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subdescription">Sous-Description</label>
          <input
            type="text"
            id="subdescription"
            name="subdescription"
            onChange={(e) => setSubdescription(e.target.value)}
            value={subdescription}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            className="custom-file-input"
            onChange={(e) => onImageChange(e)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="tarif">Tarif</label>
          <input
            type="text"
            id="tarif"
            name="tarif"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
          />
        </div>
        <div className="form-group">
          <label htmlFor="minutes">Minutes</label>
          <input
            type="text"
            id="minutes"
            name="minutes"
            onChange={(e) => setMinutes(e.target.value)}
            value={minutes}
          />
        </div>

        <button className="primary">Update</button>
      </form>
    </div>
  );
}

export default EditPackage;
