
function HomeExpertTable(props) {

  return (
    <>
    <table>
        <thead>
          <tr>
            <td colSpan={3}>GAINS</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>APPEL</th>  
            <th>{props.statsData["gainToDayCall"]}</th>
            <th>{props.statsData["gainThisMonthCall"]}</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>RDV</th>  
            <th>{props.statsData["gainToDayRdv"]}</th>
            <th>{props.statsData["gainThisMonthRdv"]}</th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th>{props.statsData["gainToDayAll"]}</th>
            <th>{props.statsData["gainThisMonthAll"]}</th>
          </tr>  
        </tbody>  
      </table>
      <table>
        <thead>
          <tr>
            <td colSpan={3}>CONSULTATIONS</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>APPEL</th>  
            <th>{props.statsData["consultationsToDayCall"]}</th>
            <th>{props.statsData["consultationsThisMonthCall"]}</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>RDV</th>  
            <th>{props.statsData["consultationsToDayRdv"]}</th>
            <th>{props.statsData["consultationsThisMonthRdv"]}</th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th>{props.statsData["consultationsToDayAll"]}</th>
            <th>{props.statsData["consultationsThisMonthAll"]}</th>
          </tr>  
        </tbody>  
      </table>
      <table>
        <thead>
          <tr>
            <td colSpan={3}>APPELS MANQUÉS</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>APPEL</th>  
            <th>{props.statsData["missedCallToDayCall"]}</th>
            <th>{props.statsData["missedCallThisMonthCall"]}</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>RDV</th>  
            <th>{props.statsData["missedCallToDayRdv"]}</th>
            <th>{props.statsData["missedCallThisMonthRdv"]}</th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th>{props.statsData["missedCallToDayAll"]}</th>
            <th>{props.statsData["missedCallThisMonthAll"]}</th>
          </tr>  
        </tbody>  
      </table>
    </>
  )
}

export default HomeExpertTable;