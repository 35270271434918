import { Icon } from "@iconify/react";
import StarRating from "../utils/StarRating";
import { useNavigate } from 'react-router-dom';

function ButtonStatus(props) {

    const { disponibily } = props;
    const { redirectProfil } = props;

    let button;

    switch(disponibily) {

        case "FREE":
            button = <button className="online" onClick={redirectProfil}>Je consulte</button>;
            break;

        case "ON_WORK":
            button = <button className="consult">En consultation</button>;
            break;

        default:
            button = <button className="disable">Hors-Ligne</button>;
            break;
        
    }

  
    return (
      <>
        {button}
      </>
    );
  }

function BenefitsSupport(props) {

    let { benefits } = props;

    if(!benefits) {

        benefits = [];

    }
  
    return (
      <>
        {benefits.includes('CALL') ?  <Icon icon="mdi:telephone"/> : ""}
        {benefits.includes('CHAT') ?  <Icon icon="eva:message-circle-fill"/> : ""}
        {benefits.includes('MAIL') ?  <Icon icon="mdi:email"/> : ""}
        {/*<Icon icon="mdi:calendar" color={benefits.includes('MEET') ? '#3ea336' : '#FFF'} />*/}
      </>
    );
  }

function ExpertBox(props) {

    const navigate = useNavigate();

    let { benefits } = props;

    if(!benefits) {benefits = [];}

    const redirectProfil = () => {
        let profileUrl = props.profileUrl;
        if(profileUrl) {
            window.open(atob(profileUrl) + "?profileId=" + props.id, '_blank', 'noreferrer');
        } else {
            navigate("/" + props.officeName + "/client/expertList/" + props.id + "/");
        }
    };

    return (
        <div className="block">
            <div className="preview" style={{ 
                        backgroundImage: `url("`+ props.imageBanner +`")` 
                    }}>
                <div className="text">
                    <div className="avatar" onClick={redirectProfil} style={{ 
                        backgroundImage: `url("`+ props.imageProfile +`")` 
                    }}></div>
                </div>
            </div>
            <div className="top-bar">
                <div className="left">
                    <div className="stars">
                        <StarRating advice={props.advice} />
                    </div>
                    <span>{props.call} Consult.</span>
                </div>
                <div className="icons">
                    <BenefitsSupport benefits={props.benefits} />
                </div>
            </div>
            <div className="profil">
                <div className="title">{props.name}</div>
                <div className="meet">
                    {
                        benefits.includes('MEET') ? 

                        <Icon icon="mdi:calendar" color="#4ddaa6" /> : 
                        <Icon icon="mdi:calendar" color="#6c757d" />

                    }
                    <span>RDV</span>
                </div>
            </div>
            <div className="subtitle">{props.category}</div>
            <p>{props.description}</p>
            <div className="btn">
                <ButtonStatus redirectProfil={redirectProfil}
                    disponibily={props.disponibily}/>
            </div>
        </div>
    );
}

export default ExpertBox;