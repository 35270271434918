import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DropdownButton from "../../dropdownButton/DropdownButton";
import Modal from "../../modal/modal";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import { userClient } from "./../../../network/lib/user";
import { fireSuccessAlert } from "./../../utils/Swal";
import FilterBar from "./filterBar";

function AdminAccountTable() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [newAdminEmail, setNewAdminEmail] = useState("");

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  const handleBan = (id) => {
    userClient.admin.banAdmin(id).then((res) => {
      fireSuccessAlert("l'utilisateur est banni");
    });
  };

  const handleUnban = (id) => {
    userClient.admin.unbanAdmin(id).then((res) => {
      fireSuccessAlert("l'utilisateur n'est pas banni");
    });
  };

  const handleDelete = (id) => {
    userClient.admin.removeAdmin(id).then((res) => {
      fireSuccessAlert("Admin a supprime");
      setData((prevState) => prevState.filter((user) => user.id !== id));
    });
  };

  const handleNewAdminFormSubmit = (e) => {
    e.preventDefault();
    userClient.admin.addAdmin(newAdminEmail).then((res) => {
      setShowModal(false);
      fireSuccessAlert("Admin a ajoute").then(() => {
        fetchData();
      });
    });
  };

  const fetchData = () => {
    userClient.admin.profile.getAll().then((res) => {
      // console.log(res.data)
      setData(res.data.admins);
    });
  };

  useEffect(() => {
    // setData(createFakeData("admin-account"));
    fetchData();
    setIsLoading(false);
  }, []);

  // Admin Object
  //   {
  //     "id": "b4a707f1-f01d-4b43-ad65-24f96db9b37b",
  //     "idShort": "#B4A707",
  //     "name": "mytest",
  //     "email": "mytest@test.com",
  //     "phone": "+16531648569",
  //     "timeRegister": "31/07/2023"
  // }
  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "idShort",
        cell: (info) => info.getValue(),
      },

      {
        header: "Nom",
        accessorKey: "name",
        cell: (info) => info.getValue(),
      },

      {
        header: "E-mail",
        accessorKey: "email",
        cell: (info) => info.getValue(),
      },
      {
        header: "Téléphone",
        accessorKey: "phone",
        cell: (info) => info.getValue(),
      },

      {
        header: "Date de création",
        accessorKey: "timeRegister",
        cell: (info) => info.getValue(),
      },
      // {
      //   header: "Admin",
      //   accessorKey: "accountType",
      //   cell: (info) => <div className="type">{info.getValue()}</div>,
      // },
      {
        header: "Action",
        accessorKey: "id",
        cell: (info) => {
          const id = info.getValue();
          const items = [
            {
              label: "Bannir",
              command: (e) => {
                console.log(id);  
                handleBan(id);
              },
            },
            {
              label: "Unbannir",
              command: () => {
                handleUnban(id);
              },
            },
            {
              label: "Supprimer",
              command: () => {
                handleDelete(id);
              },
            },
          ];
          return <DropdownButton items={items} />;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },

    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const searchableProps = { globalFilter, setGlobalFilter, table, isLoading };
  const filterProps = { globalFilter, setGlobalFilter, table };

  return (
    <>
      <Modal
        title="Entrer l'adresse e-mail"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleNewAdminFormSubmit}
        >
          <label>Email</label>
          <input
            type="text"
            value={newAdminEmail}
            onChange={(e) => setNewAdminEmail(e.target.value)}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <FilterBar
        {...filterProps}
        withModal={true}
        setShowModal={setShowModal}
      />
      {isTabletOrMobile ? (
        <SearchableTableMD {...searchableProps} />
      ) : (
        <SearchableTableXL {...searchableProps} />
      )}
    </>
  );
}

export default AdminAccountTable;
