import apiClient from "../apiClient";

export const mailClient = {
  expert: {
    set: ({ mailId, message }) => apiClient.post("/mails/experts/edit/", { mailId, message }),
    getAll: () => apiClient.get("/mails/experts/all/view/"),
    get: ({ mailId }) => apiClient.get("/mails/experts/view/", { mailId }),
  },
  client: {
    getAll: () => apiClient.get("/mails/all/view/"),
  },
};
