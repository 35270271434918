import { Icon } from "@iconify/react";

function StarRating(props) {
    const { advice } = props;
  
    const renderStars = () => {
      const stars = [];
      for (let i = 0; i < advice; i++) {
        stars.push(<Icon key={i} icon="material-symbols:star" color="#e9319a" height={15} width={15} />);
      }
      return stars;
    };
  
    return (
      <>
        {renderStars()}
      </>
    );
  }


export default StarRating;