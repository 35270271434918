import apiClient from "../apiClient";

export const appointmentClient = {
  slot: {
    getAllAvailable: ({ expertId, productId, dates }) =>
      apiClient.post("/users/appointments/slots/view/", {
        expertId,
        productId,
        dates,
      }),
  },
};
