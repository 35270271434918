import {useEffect, useState } from 'react';
import axios from "axios";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import InfoMessageBoxTD from '../react-tables/utils/infoMessageBox';
import { userClient } from './../../network/lib/user';

function PurchaseTable() {

  const [userPurchaseData, userPurchaseDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    userClient.client.purchase.getAll()
        .then((resp) => {
          userPurchaseDataSet(resp.data.purchases);
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false);
            //window.location.href = "https://vooxhub.com";
        });
  }, []);
  if (isLoading) return <TableSkeleton columnCount={4} rowCount={4} />;
  return (
    <div className='tableContainer'>
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>PRODUIT</Th>
            <Th>N°COMMANDE</Th>
            <Th>TYPE</Th>
            <Th>MONTANTS</Th>
          </Tr>
        </Thead>
        <Tbody>
        {userPurchaseData.length === 0 ? <InfoMessageBoxTD colSpan={4} /> : userPurchaseData.map((purchase, index) => {
                        return (
                          <Tr key={index}>
                            <Td className="color-red">{purchase["date"]}</Td>
                            <Td>{purchase["productName"]}</Td>
                            <Td>{purchase["orderId"]}</Td>
                            <Td className="color-green">{purchase["purchaseType"]}</Td>
                            <Td className="color-purple">{purchase["price"]}</Td>
                          </Tr>
                      )
                    })}
        </Tbody>
      </Table>
    </div>
  );
}

export default PurchaseTable;