import { RiAccountCircleLine } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
import { callClient } from "../../network/lib/call";
import { fireSuccessAlert } from "../utils/Swal";

function UserInfo(props) {
  const { data, active } = props;

  function unlockCall() {

    alert(data["expertId"]);
  
    callClient.admin.unlock({expertId : data["expertId"]}).then((resp) => {
      fireSuccessAlert("Appelle débloquer");
    });

  }

  return (
    <div className="user-info">
      <div className="info-contact">
        <div>
          {data["imageProfile"] ? (
            <img src={data["imageProfile"]} />
          ) : (
            <RiAccountCircleLine color="#81C313" className="userIcon" />
          )}
          <h3>{data["expertName"]}</h3>
          <span></span>
        </div>
        <div>
          <span className="idClient">{data["idShort"]}</span>
          <span className="clientBadge">{data["callType"]}</span>
          <span>{data["hourly"]}</span>
        </div>
      </div>
      <div className="info-call">
        <div className="box">
          <BsFillTelephoneFill />
          <span>{data["clientName"]}</span>
        </div>
        <div className="box">{data["duration"]} min</div>
        {active ? <div className="box-button">
          <button className="primary" onClick={()=>unlockCall()}>
            Débloquer
          </button>
        </div> : ""}
      </div>
    </div>
  );
}

export default UserInfo;
