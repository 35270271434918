import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { officeClient } from "../../../network/lib/office"

const initialState = {
    loading: true,
    data: {},
    error: ""
    
}

const fetchOfficeData = createAsyncThunk('office/fetchOfficeData',(officeName) => {
    return officeClient.getOffice(officeName).then(res => {
        res.data = {...res.data, officeName: officeName}
        // console.log(res)
        return res.data
    })
})

const officeSlice = createSlice({
    name: 'office',
    initialState,
    extraReducers: (builder) => {
        // Handling fetOffice Promise cases - pending, fulfilled, rejected
        builder.addCase(fetchOfficeData.pending,(state) => {
            state.loading = true;
        })

        builder.addCase(fetchOfficeData.fulfilled, (state,action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = '';
        })

        builder.addCase(fetchOfficeData.rejected, (state,action) => {
            state.loading = false;
            state.error = action.error.message;
            state.data = {};
        })

    }
})

export { fetchOfficeData }
export default officeSlice.reducer;