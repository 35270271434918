import { useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import InfoMessageBoxTD from "../react-tables/utils/infoMessageBox";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import { paymentClient } from "./../../network/lib/payment";
import { fireSuccessAlert } from "./../utils/Swal";

function CardTable() {
  const [userCardData, userCardDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const onRemoveCard = (cardIdList) => {
    paymentClient.card.delete(cardIdList).then((resp) => {
      fireSuccessAlert("Carte supprimer !").then(() => {
        userCardDataSet(prevState => prevState.filter(card => card.cardId !== cardIdList))
        // window.location.reload();
      });
    });
  };

  useEffect(() => {
    paymentClient.card.getAll()
      .then((resp) => {
        userCardDataSet(resp.data.cards);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  if (isLoading) return <TableSkeleton columnCount={4} rowCount={4} />;
  return (
    <div className="tableContainer">
      <Table>
        <Thead>
          <Tr>
            <Th>CARTE</Th>
            <Th>DATE DE SAISIE</Th>
            <Th>CVV</Th>
            <Th>ACTION</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userCardData.length === 0 ? (
            <InfoMessageBoxTD colSpan={4} />
          ) : (
            userCardData.map((card, index) => {
              return (
                <Tr key={index}>
                  <Td>{card["title"]}</Td>
                  <Td>
                    {card["month"]}/{card["year"]}
                  </Td>
                  <Td>XXX</Td>
                  <Td>
                    <button
                      onClick={() => onRemoveCard(card["cardId"])}
                      className="btn"
                    >
                      Supprimer
                    </button>
                  </Td>
                </Tr>
              );
            })
          )}
        </Tbody>
      </Table>
    </div>
  );
}

export default CardTable;
