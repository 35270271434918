import React from "react";
import DebouncedInput from "../utils/debouncedInput";

function FilterBar({
  dateRange,
  globalFilter,
  setGlobalFilter,
  table,
  setShowModal,
  withModal,
}) {
  return (
    <div>
      <div className="filter-bar">
        {withModal ? (
          <button className="nouveau" onClick={() => setShowModal(true)}>
            <span className="plus">+</span>
            <span className="text">Nouveau</span>
          </button>
        ) : (
          <button className="nouveau">
            <span className="plus">+</span>
            <span className="text">Nouveau</span>
          </button>
        )}

        <div className="recherche">
          <DebouncedInput
            type="text"
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Rechercher"
          />
        </div>
      </div>
    </div>
  );
}

export default FilterBar;
