import React from "react";

const CustomLoader = () => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "5rem", height: "5rem" }}>
        <img
          src={process.env.PUBLIC_URL + "/assets/Hourglass.gif"}
          alt="loading..."
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default CustomLoader;
