import { AiFillStar } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useEffect, useState } from "react";
import axios from "axios";
import ExpertInfoBoxSkeleton from "../skeletons/expert/expertInfoBoxSkeleton";
import { useSelector } from 'react-redux';

function ExpertInfoBox(props) {
  const [userData, userDataSet] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  const userState = useSelector(state => state.user)

  // const config = {
  //   headers: {
  //     sessionId: localStorage.getItem("sessionId"),
  //   },
  // };

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://api.vooxhub.com/users/experts/view/?officeName=" +
  //         props.officeName,
  //       config
  //     )
  //     .then((resp) => {
  //       userDataSet(resp.data);
  //     })
  //     .then(() => setIsLoading(false))
  //     .catch((error) => {
  //       //window.location.href = "https://vooxhub.com";
  //     });
  // }, []);

  useEffect(() => {
    userDataSet(userState.data)
  },[userState])

  if (userDataSet.loading) {
    return <ExpertInfoBoxSkeleton />;
  }

  function StarDisplay({ count }) {
    const stars = [];
  
    for (let i = 0; i < count; i++) {
      stars.push(<AiFillStar key={i} color="#E7CE4C" />);
    }
  
    return <div className="stars">{stars}</div>;
  }

  return (
    <div className="expert-info-box">
      <div>
        {userData["picture"] ? (
          <img className="image" src={userData["picture"]} />
        ) : (
          <HiOutlineUserCircle color="#3EA336A0" className="user-logo" />
        )}
      </div>
      <div className="user-info">
        <span className="name">{userData["name"]}</span>
        <span className="category">{userData["tags"]}</span>
        <div className="review">
          <StarDisplay count={userData["rate"]} />
          <span className="note">{userData["rate"]}</span>
        </div>
        <span className="nb-consultation">
          {userData["consultation"]} consultation(s) depuis le début
        </span>
      </div>
    </div>
  );
}

export default ExpertInfoBox;
