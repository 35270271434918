import { id } from "date-fns/locale";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fireSuccessAlert } from "../../../components/utils/Swal";
import { promotionClient } from "../../../network/lib/promotion";

function EditPromoCode() {
  const navigate = useNavigate();
  const officeState = useSelector((state) => state.office);
  const location = useLocation();

  const [promotionalId, setPromotionalId] = useState("");
  const [codeName, setCodeName] = useState("");
  const [promotionalType, setPromotionalType] = useState("");
  const [promotionalEvent, setPromotionalEvent] = useState("");
  const [promotionalTarget, setPromotionalTarget] = useState("");
  const [value, setValue] = useState("");
  const [dayLimit, setDayLimit] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formData = {
      promotionalId,
      codeName,
      promotionalType,
      promotionalEvent,
      promotionalTarget,
      value,
      dayLimit,
    };

    promotionClient.update(formData).then((res) => {
      fireSuccessAlert("Mise à jour effectuée avec succès du code promotionnel");
      navigate(`/${officeState.data.officeName}/admin/promoCode`);
    });
  };

  // API Response structure
  //   {
//     "id": "e2d7da74-bbe7-47aa-a1f5-907a5d25261f",
//     "codeName": "test",
//     "promotionalType": "PRICE",
//     "promotionalEvent": "ONCE",
//     "value": 1,
//     "dayLimit": 10,
//     "date": "13/07/2023",
//     "used": 0
// },


  useEffect(() => {
    
    if (location) {
      const {
        id,
        codeName,
        promotionalType,
        promotionalEvent,
        promotionalTarget,
        value,
        dayLimit,
      } = location.state.data;

      // console.log(location.state.data);
      setPromotionalId(id);
      setPromotionalType(promotionalType);
      setCodeName(codeName);
      setPromotionalEvent(promotionalEvent);
      setPromotionalTarget(promotionalTarget);
      setValue(value);
      setDayLimit(dayLimit);
    }
  }, []);

  console.log(promotionalType);
  return (
    <div>
      <h2 className="mb-2">Modifier Code Promo</h2>
      <form className="new-promo-code-form" onSubmit={handleFormSubmit}>
        <div className="form-group">
          <label htmlFor="nom">Nom du code promo</label>
          <input
            type="text"
            id="nom"
            name="nom"
            onChange={(e) => setCodeName(e.target.value)}
            value={codeName}
          />
        </div>

        <div className="form-group">
          <label>Event</label>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-event-register"
              name="event"
              value="REGISTER"
              onChange={(e) => setPromotionalEvent(e.target.value)}
              checked={promotionalEvent === "REGISTER"}
            />
            <label htmlFor="promo-event-register">Register</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-event-birthday"
              name="event"
              value="BIRTHDAY"
              onChange={(e) => setPromotionalEvent(e.target.value)}
              checked={promotionalEvent === "BIRTHDAY"}
            />
            <label htmlFor="promo-event-birthday">Birthday</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-event-once"
              name="event"
              value="ONCE"
              onChange={(e) => setPromotionalEvent(e.target.value)}
              checked={promotionalEvent === "ONCE"}
            />
            <label htmlFor="promo-event-once">Once</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-event-endlessly"
              name="event"
              value="ENDLESSLY"
              onChange={(e) => setPromotionalEvent(e.target.value)}
              checked={promotionalEvent === "ENDLESSLY"}
            />
            <label htmlFor="promo-event-endlessly">Endlessly</label>
          </div>
        </div>

        <div className="form-group">
          <label>Type</label>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-type-percentage"
              name="type"
              value="PERCENTAGE"
              onChange={(e) => setPromotionalType(e.target.value)}
              checked={promotionalType === "PERCENTAGE"}
            />
            <label htmlFor="promo-type-percentage">Percentage</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-type-price"
              name="type"
              value="PRICE"
              onChange={(e) => setPromotionalType(e.target.value)}
              checked={promotionalType === "PRICE"}
            />
            <label htmlFor="promo-type-price">Price</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="promo-type-time"
              name="type"
              value="TIME"
              onChange={(e) => setPromotionalType(e.target.value)}
              checked={promotionalType === "TIME"}
            />
            <label htmlFor="promo-type-time">Time</label>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="value">Value</label>
          <input
            type="number"
            id="value"
            name="value"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="day-limit">Day Limit</label>
          <input
            type="number"
            id="day-limit"
            name="day-limit"
            value={dayLimit}
            onChange={(e) => setDayLimit(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Target</label>
          <div className="radio-group">
            <input
              type="checkbox"
              id="promo-target-all"
              name="target"
              value="ALL"
              onChange={(e) => setPromotionalTarget(e.target.value)}
              checked={promotionalTarget === "ALL"}
            />
            <label htmlFor="promo-target-all">ALL</label>
          </div>
          <div className="radio-group">
            <input
              type="checkbox"
              id="promo-target-call"
              name="target"
              value="CALL"
              onChange={(e) => setPromotionalTarget(e.target.value)}
              checked={promotionalTarget === "CALL"}
            />
            <label htmlFor="promo-target-call">CALL</label>
          </div>
          <div className="radio-group">
            <input
              type="checkbox"
              id="promo-target-meet"
              name="target"
              value="MEET"
              onChange={(e) => setPromotionalTarget(e.target.value)}
              checked={promotionalTarget === "MEET"}
            />
            <label htmlFor="promo-target-meet">MEET</label>
          </div>
          <div className="radio-group">
            <input
              type="checkbox"
              id="promo-target-mail"
              name="target"
              value="MAIL"
              onChange={(e) => setPromotionalTarget(e.target.value)}
              checked={promotionalTarget === "MAIL"}
            />
            <label htmlFor="promo-target-mail">MAIL</label>
          </div>
        </div>

        <button className="primary">Update</button>
      </form>
    </div>
  );
}

export default EditPromoCode;
