import React, { useState } from "react";
import CustomSelect from "../../../components/utils/CustomSelect";
import FrenchCalendar from "../../calendar/frenchCalendar";
import DebouncedInput from "../utils/debouncedInput";
import Modal from "../../modal/modal";
import { userClient } from "../../../network/lib/user";
import { fireInfoAlert, fireSuccessAlert } from "../../utils/Swal";

function FilterBar({ globalFilter, setGlobalFilter, table, dateRange, printStats }) {
  const numOfRows = Object.keys(table.getRowModel().rowsById).length;

  const activeColumn = table
    .getAllColumns()
    .filter((column) => column.columnDef.accessorKey === "active")[0];
    // console.log(activeColumn)
  const activeColumnFilterValue = activeColumn.getFilterValue();

  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showRefundCheckModal, setShowRefundCheckModal] = useState(false);
  const [orderId, setOrderId] = useState("");

  const handleRefund = (e) => {
    e.preventDefault();
    userClient.admin.refund(orderId).then((res) => {
      setShowRefundModal(false);
      fireSuccessAlert("Remboursement effectué.");
    });
  };

  const handleRefundCheck = (e) => {
    e.preventDefault();
    userClient.admin.refundCheck(orderId).then((res) => {
      setShowRefundModal(false);
      fireInfoAlert(res.data.message);
    });
  };

  return (
    <>
      <Modal
          title="Effectuer un remboursement"
          show={showRefundModal}
          onClose={() => setShowRefundModal(false)}
          width="40%"
        >
          <form
            className="experts-modal-form flex-column gap-1"
            onSubmit={handleRefund}
          >
            <label>N°COMMANDE (Exemple: O1BF53459)</label>
            <input
              type="text"
              onChange={(e) => setOrderId(e.target.value)}
              value={orderId}
            />
            <button type="submit" className="gray-button">
              Soumettre
            </button>
          </form>
        </Modal>

        <Modal
          title="Vérifier un achat"
          show={showRefundCheckModal}
          onClose={() => setShowRefundCheckModal(false)}
          width="40%"
        >
          <form
            className="experts-modal-form flex-column gap-1"
            onSubmit={handleRefundCheck}
          >
            <label>N°COMMANDE (Exemple: O1BF53459)</label>
            <input
              type="text"
              onChange={(e) => setOrderId(e.target.value)}
              value={orderId}
            />
            <button type="submit" className="gray-button">
              Soumettre
            </button>
          </form>
        </Modal>
      <div>
        <div className="form">
          <DebouncedInput
            type="text"
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Client"
          />
          <FrenchCalendar  dateFormat="dd MM, yy"
              value={dateRange}
              onChange={(e) => printStats(e.value)}
              selectionMode="range"
              id="inputRange"/>
          {/* <FrenchCalendar /> */}
          <div className="search-bar">
            <button className="nouveau" onClick={() => setShowRefundModal(true)}>
                <span className="text">Rembousement</span>
            </button>
            <button className="nouveau" onClick={() => setShowRefundCheckModal(true)}>
                <span className="text">Vérifier un achat</span>
            </button>
          </div>
        </div>
        <div className="nbResult">{numOfRows} clients trouvés</div>
      </div>
    </>
  );
}

export default FilterBar;
