import apiClient from "../apiClient";

export const officeClient = {
  getOffice: (officeName) =>
    apiClient.get("/offices/view/", {
      params: {
        officeName: officeName,
      },
    }),

  getOfficePaymentProvider: (officeName) =>
    apiClient.get("/offices/payment/provider/view/", {
      params: {
        officeName: officeName,
      },
    }),

  admin: {
    getOffice: () => apiClient.get("/users/admins/offices/view/"),
    updateOffice: ({
      email,
      logo,
      fondImage,
      easyPrivateKey,
      urlMain,
      urlCGV,
      expertCallRate,
      expertChatRate,
      expertMailRate,
      expertMeetRate,
      mailPrice,
      messageBirthday,
      messageRegister,
    }) =>
      apiClient.post("/users/admins/offices/edit/", {
        email,
        logo,
        fondImage,
        easyPrivateKey,
        urlMain,
        urlCGV,
        expertCallRate,
        expertChatRate,
        expertMailRate,
        expertMeetRate,
        mailPrice,
        messageBirthday,
        messageRegister,
      }),
  },

  owner: {
    getAllOffice: () => apiClient.get("/users/owners/offices/view/"),
    updateOfficeStatus: ({ name, officeStatus }) =>
      apiClient.put("/users/owners/offices/status/update/", {
        name,
        officeStatus,
      }),
    createOffice: ({ name }) =>
      apiClient.post("/users/owners/offices/create/", {
        name,
      }),
    addAdmin: ({ name, email }) =>
      apiClient.put("/users/owners/offices/add/admin/", {
        name,
        email,
      }),
  },
};
