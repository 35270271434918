function BoxTd(props) {
  const { stats } = props;
  return (
    <div className="box box-td">
      <h3>Appels manqués</h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">Ce mois-ci</span>

          <span className="primary-text mb-3">{stats.monthCallLost}</span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text">{stats.lastMonthCallLost}</span>
        </div>
        <div>
          <span className="sub-title-gray">Aujourd’hui</span>

          <span className="primary-text mb-3">{stats.todayCallLost}</span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text">{stats.yesterdayCallLost}</span>
        </div>
      </div>
    </div>
  );
}

export default BoxTd;
