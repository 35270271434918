import Skeleton from "react-loading-skeleton";

function ExpertInfoBoxSkeleton() {
  return (
    <div className="expert-info-box">
      <div style={{maxWidth: "8rem"}}>
        <Skeleton circle style={{width: "7rem", height: "7rem"}}/>
      </div>
      <div className="user-info" style={{width: "100%"}}>
        <span className="name"><Skeleton width="30%"/></span>
        <span className="category"><Skeleton width="30%" /></span>
        <div className="review">
          <div className="stars">
          <Skeleton width="8rem"/>
          </div>
          <span className="note"><Skeleton width="30%"/></span>
        </div>
        <span className="nb-consultation">
        <Skeleton width="40%"/>
        </span>
      </div>
    </div>
  );
}

export default ExpertInfoBoxSkeleton;
