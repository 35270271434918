import React, { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

function CalendarDay({ details, index, elem, bookingStatus }) {
  const { day, addTiming, removeTiming, daysDates, calendarData } = details;
  const { bookingDate, loading, action } = bookingStatus;
  const [calendarId, setCalendarId] = useState("");

  function toggleAddRemove() {

    let shouldAddTiming = true;

    if (!calendarData[day][parseInt(elem)]) {
      addTiming(daysDates[index], parseInt(elem));
    } else {
      calendarData[day][parseInt(elem)].forEach(function(dayData) {
        if(dayData.calendarId) {
          removeTiming(dayData);
          setCalendarId(dayData.calendarId);
          shouldAddTiming = false;
          return;
        }
      });

      if (shouldAddTiming) {
        addTiming(daysDates[index], parseInt(elem));
      }
    }
  }

  function renderBooking() {
    if (action === "add") {
      if (
        bookingDate.day === daysDates[index] &&
        bookingDate.hour === parseInt(elem) &&
        loading
      ) {
        return <BeatLoader color="#fe4181" />;
      }
    }

    if (
      action === "remove" &&
      bookingDate.calendarId === calendarId &&
      loading
    ) {
      return <BeatLoader color="#fe4181" />;
    }

    return calendarData[day] && calendarData[day][parseInt(elem)]
      ? calendarData[day][parseInt(elem)].map((calendar, index) => {
          return (
            <span
              key={index}
              style={{ backgroundColor: calendar["color"] }}
              className="expert-badge"
            >
              {calendar["expertName"]}
            </span>
          );
        })
      : null;
  }

  return (
    <div key={day}>
      <div onClick={toggleAddRemove}>{renderBooking()}</div>
    </div>
  );
}

export default CalendarDay;
