import Skeleton from "react-loading-skeleton";

function AdviceBoxSkeleton({ count }) {
  return Array.from({ length: count }, (_, index) => {
    return (
      <div className="advice-box" key={index}>
        <Skeleton width="30%" />
        <Skeleton width="20%" />
        <Skeleton width="15%" />

        <p style={{ marginTop: "20px" }}>
          <Skeleton height={80} />
        </p>
      </div>
    );
  });
}

export default AdviceBoxSkeleton;
