import axios from "axios";
import { fireErrorAlert } from "../components/utils/Swal";
import customHistory from "../router/customHistory";

let store;

export const injectStore = (_store) => {
  store = _store;
};

const apiClient = axios.create({
  baseURL: `https://api.vooxhub.com`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const apiClientUpload = axios.create({
  baseURL: `https://api.vooxhub.com`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const addInterceptorResponse = () => {
  return {
    response: (response) => {
      // console.log("Interceptor Response", response);
      return response;
    },
    error: (error) => {
      let officeName = store.getState().office.data.officeName;
      console.log(error);

      if(!officeName) {officeName = window.location.pathname.split('/')[1];}
   
      if (error.response) {
        const errorData = error.response.data;
        switch (error.response.status) {
          case 400:
            // Handle Bad Request calls here
            if(errorData.message 
              && errorData.message.includes("Bad Office")) {
                customHistory.replace(`/${officeName}/login`);
                break;
            }
            fireErrorAlert(errorData.reason || errorData.message);
            break;
          case 401:
            // Handle Unauthorized calls here
            customHistory.replace(`/${officeName}/login`);
            break;
          case 403:
            // Handle 403 here
            customHistory.back();
            break;
          case 405:
            fireErrorAlert(errorData.reason || errorData.message);
            break;
          case 500:
            fireErrorAlert(errorData.reason || errorData.message);
            break;
          default:
            fireErrorAlert(errorData.reason || errorData.message);
        }
      }
    
      return Promise.reject(error);
    },
  };
};

const addInterceptorRequests = () => {
  return {
    request: (config) => {
      const sessionId = localStorage.getItem("sessionId");
      if (sessionId) {
        config.headers.sessionId = sessionId;
      }
      // console.log("Interceptor Config", config)
      return config;
    },
    error: (error) => {
      // console.log("Interceptor error", error);
      if (error) {
        console.log(error);
      }
      return Promise.reject(error);
    },
  };
};

const interceptorResponse = addInterceptorResponse();

apiClient.interceptors.response.use(
  interceptorResponse.response,
  interceptorResponse.error
);
apiClientUpload.interceptors.response.use(
  interceptorResponse.response,
  interceptorResponse.error
);

const interceptor = addInterceptorRequests();

apiClient.interceptors.request.use(
  interceptor.request,
  interceptor.error);
apiClientUpload.interceptors.request.use(
  interceptor.request, 
  interceptor.error);

export default apiClient;
export {apiClientUpload};
