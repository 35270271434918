import React from "react";

function ExpertConfigurationTab(props) {

  const {expertCallRateData,
        expertCallRateDataSet,
        expertChatRateData,
        expertChatRateDataSet,
        expertMailRateData,
        expertMailRateDataSet,
        expertMeetRateData,
        expertMeetRateDataSet} = props;

  return (
    <div className="tab-body">
      <label htmlFor="expertReversementCall">
        Tarifs au Expert pour les Appelles (€ par heure)
      </label>
      <input
        name="expertReversementCall"
        type="number"
        value={expertCallRateData}
        onChange={(e) => {expertCallRateDataSet(e.target.value)}}
        className="input-light"
      />
      <label htmlFor="expertReversementChat">
        Tarifs au Expert pour les Chats (€ par heure)
      </label>
      <input
        name="expertReversementChat"
        type="number"
        value={expertChatRateData}
        onChange={(e) => {expertChatRateDataSet(e.target.value)}}
        className="input-light"
      />
      <label htmlFor="expertReversementMail">
        Tarifs au Expert pour les Mails (% du Prix de Mail)
      </label>
      <input
        name="expertReversementMail"
        type="number"
        value={expertMailRateData}
        onChange={(e) => {expertMailRateDataSet(e.target.value)}}
        className="input-light"
      />
      <label htmlFor="expertReversementRdv">
        Tarifs au Expert pour les Rendez-vous (% du Prix du Rendez-vous)
      </label>
      <input
        name="expertReversementRdv"
        type="number"
        value={expertMeetRateData}
        onChange={(e) => {expertMeetRateDataSet(e.target.value)}}
        className="input-light"
      />
    </div>
  );
}

export default ExpertConfigurationTab;
