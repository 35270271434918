import React, { useState } from 'react';
import { Icon } from "@iconify/react";

function CreditCardExpiration(props) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    let value = e.target.value;

    if (value.length > 7) {
        value = value.slice(0, 7);
    }

    value = value.replace(/\D/g, '');

    if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2);
    }

    setInputValue(value);

    // Séparez la valeur en mois et année
    const [mm, yyyy] = value.split('/');
    props.setMonth(mm);
    props.setYear(yyyy);
  };

  return (
    <div className="form-group">
        <label>Date d’expiration</label>
        <div class="input-container">
            <div className="icon">
                <Icon
                    icon="material-symbols:calendar-month-sharp"
                    color="#8AC923"
                />
            </div>
            <input
                  className="input-field no-anime"
                  type="text"
                  name="nbCard"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="MM/AAAA"
            />
        </div>
    </div>
  );
}

export default CreditCardExpiration;
