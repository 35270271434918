import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import ExpertsTableLogic from "../../components/react-tables/expertsTable/expertsTableLogic";
// import ExpertsTable from "../../components/tables/expertsTable";

function ExpertPage() {
  return (
    <div id="expert">
      <div className="top-bar">
        <h2>Experts</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Expert
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Compte
          </Link>
        </div>
      </div>
      <ExpertsTableLogic />
    </div>
  );
}

export default ExpertPage;
