import { Calendar } from "primereact/calendar";
import { useEffect, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import CustomLoader from "../components/skeletons/customLoader";
import { fireSuccessAlert } from "./../components/utils/Swal";
import { authenticationClient } from "./../network/lib/authentication";
import { officeClient } from "./../network/lib/office";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import FrenchCalendar from "../components/calendar/frenchCalendar";

function RegisterPage() {
  const { officeName } = useParams();

  const [officeData, officeDataSet] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [phoneData, setPhoneData] = useState();

  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const nameRef = useRef(null);
  const emailingRef = useRef(null);
  const majorityRef = useRef(null);

  useEffect(() => {
    officeClient.getOffice(officeName).then((res) => {
      officeDataSet(res.data);
    });
  }, []);

  function onRegister(e) {
    const formData = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      name: nameRef.current.value,
      officeName: officeName,
      phone: phoneData,
      brithDay: startDate.getDate(),
      birthMonth: startDate.getMonth() + 1,
      birthYear: startDate.getFullYear(),
      emailing: emailingRef.current.checked,
      majority: majorityRef.current.checked,
    };

    // console.log(formData)
    authenticationClient.register(formData).then((resp) => {
      const token = resp.data.token;

      if (token !== undefined && token !== null) {
        localStorage.setItem("sessionId", token);

        // const MySwal = withReactContent(Swal);

        // MySwal.fire({
        //   title: "Parfait",
        //   text: "Vous avez bien été identifier !",
        //   icon: "success",
        // })
        fireSuccessAlert("Vous avez bien été identifié !").then(() => {
          const userType = resp.data.userType;

          switch (userType) {
            case "CLIENT":
              window.location.href = "/" + officeName + "/client";
              break;
            case "ADMIN":
              window.location.href = "/" + officeName + "/admin";
              break;
            case "EXPERT":
              window.location.href = "/" + officeName + "/expert";
              break;

            default:
              window.location.href = "/" + officeName + "/client";
              break;
          }
        });
      }
    });
    // .catch((error) => {
    //   if (error.response) {
    //     const MySwal = withReactContent(Swal);

    //     MySwal.fire({
    //       title: "Oups...",
    //       text: error.response.data.reason,
    //       icon: "error",
    //     });
    //   }

    //   console.log({ ...error });
    // });
  }

  if (officeData.length === 0) return <CustomLoader />;
  return (
    <div id="registerPage">
      <div className="content">
        <img
          src={
            officeData["logoImage"] == null
              ? "/assets/logo.svg"
              : officeData["logoImage"]
          }
          className="logo"
          alt="Logo cabinet"
        />
        <h2>Content de vous revoir!</h2>
        <span className="sub-title">Entrez vos données</span>

        <div className="form">
          <div className="form-group">
            <label>Pseudo ou prénom</label>
            <input
              placeholder="PSEUDO ou PRENOM"
              ref={nameRef}
              type="text"
            />
          </div>
          <div className="form-group">
            <label>E-mail</label>
            <input placeholder="VOTRE E-MAIL *" ref={emailRef} type="email" />
          </div>
          <div className="form-group">
            <label>Numéro de télephone.</label>
            <PhoneInput
              placeholder="Votre numéro de télephone"
              value={"+33"}
              onChange={setPhoneData}
              country="FR"
            />
          </div>
          <div className="form-group">
            <label>Date de naissance</label>
            {/* <DatePicker
              className="input-date-naissance"
              dateFormat="dd/MM/yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            /> */}
            <FrenchCalendar
              value={startDate}
              onChange={(e) => setStartDate(e.value)}
              dateFormat="dd/mm/yy"
            />
          </div>
          <div className="form-group">
            <input
              placeholder="MOT DE PASSE (8 caracteres minimum) *"
              ref={passwordRef}
              type="password"
            />
          </div>
          <div className="checkbox">
            <input ref={emailingRef} type="checkbox" id="reciveOffer" />
            <label htmlFor="reciveOffer">
              Je souhaite recevoir des offres exclusives
            </label>
          </div>
          <div className="checkbox">
            <input ref={majorityRef} type="checkbox" id="ihave18" />
            <label htmlFor="ihave18">
              J’ai plus de 18 ans, j’ai lu et j’approuve les
              <br />
              <a href={officeData["urlCGV"]}>Conditions générales d'utilisation</a>
            </label>
          </div>
          <input
            onClick={onRegister}
            type="submit"
            value="VALIDER"
            className="submit"
          />
        </div>
      </div>
      <div
        className="bg"
        style={{ backgroundImage: `url(` + officeData["bannerImage"] + `)` }}
      >
      </div>
    </div>
  );
}

export default RegisterPage;
