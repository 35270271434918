import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useState } from "react";
import { useEffect } from "react";

const DropdownButton = ({ items }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="dropdown-button">
      <Button
        className="d-button"
        label="Actions"
        icon="pi pi-angle-down"
        iconPos="right"
        onClick={(e) => setShowMenu(!showMenu)}
      />
      {showMenu && (
        <div onClick={(e) => setShowMenu(false)}>
          <Menu model={items} className="d-menu"/>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
