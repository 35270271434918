import { useState } from "react";
import { useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Advice from "../../components/advice/advice";
import CustomSelect from "../../components/utils/CustomSelect";
import { adviceClient } from "../../network/lib/advice";
import AdviceBoxSkeleton from "../../components/skeletons/expert/adviceBoxSkeleton";

function AdvicePage() {
  const [opinions, setOpinions] = useState(null);

  useEffect(() => {
    adviceClient.getAll().then((res) => {
      console.log(res.data.opinions);
      setOpinions(res.data.opinions);
    });
  }, []);
 
  return (
    <div id="advice">
      <div className="top-bar">
        <h2>Avis</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Avis
          </Link>
        </div>
      </div>

      <div className="content-box">

        {!opinions ? (
          <AdviceBoxSkeleton count={4} />
        ) : (
          opinions.map((advice, index) => (
            <Advice advice={advice} key={index} setOpinions={setOpinions} />
          ))
        )}
      </div>
    </div>
  );
}

export default AdvicePage;
