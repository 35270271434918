import { Icon } from '@iconify/react';

function BoxPackage(props) {
  return (
    <div className="box">
      <div className="box-top">
        <span>{props.title}</span>
        <div className="badge">
          <Icon icon={props.icon} />
        </div>
      </div>
      <div className='box-body'>
        <h4>{props.bigTitle}</h4>
        <div className='pourcent' style={{color: props.color}}>
          <Icon icon={props.iconPourcent} style={{color: props.color}} />
          <span style={{color: props.color}} >{props.pourcent}</span>
        </div>
        <span style={{color: "#5F5F5F"}}>en 30 jours</span>
      </div>
    </div>
  );
}

export default BoxPackage