import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExpertInfoBox from "../../components/expert/expertInfoBox";
import HomeExpertTableSkeleton from "../../components/skeletons/expert/homeExpertTableSkeleton";
import HomeExpertTable from "../../components/tables/homeExpertTable";
import { statsClient } from "./../../network/lib/stats";

function HomePageExpert() {
  const { officeName } = useParams();

  const [statsExpertHomeData, statsExpertHomeDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    statsClient.expert.home
      .get()
      .then((resp) => {
        statsExpertHomeDataSet(resp.data);
      })
      .then((res) => setIsLoading(false))
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  return (
    <div id="homeExpert">
      <ExpertInfoBox officeName={officeName} />

      <div className="tableContainer">
        {isLoading ? (
          <>
            <HomeExpertTableSkeleton />
          </>
        ) : (
          <>
            <HomeExpertTable statsData={statsExpertHomeData} />
          </>
        )}
      </div>
    </div>
  );
}

export default HomePageExpert;
