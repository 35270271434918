import apiClient from "../apiClient";

export const calendarClient = {
  expert: {
    postCalendar: ({calendarType, dates}) => apiClient.post('/users/experts/calendars/view/', {calendarType, dates}),
    addTiming: ({ year, month, hour, day, calendarType }) =>
      apiClient.put("/users/experts/calendars/create/", {
        year,
        month,
        hour,
        day,
        calendarType,
      }),
    deleteTiming: (calendarId) => apiClient.delete('/users/experts/calendars/delete/',{data: {calendarId: calendarId}}),
  },

  admin: {
    postCalendar: ({calendarType, dates}) => apiClient.post('/users/admins/calendars/view/', {calendarType, dates}),
    addTiming: ({ expertId,year, month, hour, day, calendarType }) =>
      apiClient.put("/users/admins/calendars/create/", {
        expertId,
        year,
        month,
        hour,
        day,
        calendarType,
      }),
    deleteTiming: (calendarId) => apiClient.delete('/users/admins/calendars/delete/',{data: {calendarId: calendarId}}),
  },
};
