import Skeleton from "react-loading-skeleton";

function HomeExpertTableSkeleton() {

  return (
    <>
    <table>
        <thead>
          <tr>
            <td colSpan={3}>GAINS</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>APPEL</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>RDV</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>  
        </tbody>  
      </table>
      <table>
        <thead>
          <tr>
            <td colSpan={3}>CONSULTATIONS</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>APPEL</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>RDV</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>  
        </tbody>  
      </table>
      <table>
        <thead>
          <tr>
            <td colSpan={3}>APPELS MANQUÉS</td>  
          </tr>
        </thead>
        <tbody>
          <tr>
            <th></th>  
            <th>Aujourd'hui</th>
            <th>Ce mois-ci</th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>APPEL</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>
          <tr>
            <th style={{color: "#E82B2B"}}>RDV</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>
          <tr>
            <th style={{color: "#8AC923"}}>Total</th>  
            <th><Skeleton /></th>
            <th><Skeleton /></th>
          </tr>  
        </tbody>  
      </table>
    </>
  )
}

export default HomeExpertTableSkeleton;