import apiClient from "../apiClient";

export const paymentClient = {
  card: {
    getAll: () => apiClient.get("/payments/cards/all/view/"),
    getStripeData: () => apiClient.put("/payments/cards/save"),
    delete: (cardId) =>
      apiClient.delete("/payments/cards/delete", { data: { cardId: cardId } }),
  },
  processPayment: (tokenId) => apiClient.post("/process-payment", { tokenId }),
  checkout: (paymentIntentId) =>
    apiClient.post("/payments/checkout", { paymentIntentId }),
};
