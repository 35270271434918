import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";

import React, { useEffect, useMemo, useState } from "react";
import CustomSelect from "../../utils/CustomSelect";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import FilterBar from "./filterBar";

import { profileClient } from "./../../../network/lib/profile";
import { userClient } from "./../../../network/lib/user";
import { fireSuccessAlert } from "./../../utils/Swal";
import DropdownButton from "../../dropdownButton/DropdownButton";
import Modal from "./../../modal/modal";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';

function ClientListTableLogic() {
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState([
    new Date(2023, 0, 1),
    new Date(),
  ]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const [showModalPhone, setShowModalPhone] = useState(false);


  const [balance, setBalance] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [selectedClientId, setSelectedClientId] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  const handleAddBalance = (e) => {
    e.preventDefault();
    const balanceData = { clientId: selectedClientId, balance };
    // console.log(balanceData)
    userClient.admin.client.balance.set(balanceData).then((res) => {
      setShowModal(false);
      fireSuccessAlert("Balance ajouté avec succèss").then((res) => {
        printStats(dateRange);
      });
    });
  };

  const handleSetPassword = (e) => {
    e.preventDefault();
    const passwordData = { clientId: selectedClientId, password };
    // console.log(balanceData)
    userClient.admin.client.password.set(passwordData).then((res) => {
      setShowModalPassword(false);
      fireSuccessAlert("Mot de passe éditer avec succèss");
    });
  };

  const handleSetEmail = (e) => {
    e.preventDefault();
    const emailData = { clientId: selectedClientId, email };
    // console.log(balanceData)
    userClient.admin.client.email.set(emailData).then((res) => {
      setShowModalEmail(false);
      fireSuccessAlert("Email éditer avec succèss");
    });
  };

  const handleSetPhone = (e) => {
    e.preventDefault();
    const phoneData = { clientId: selectedClientId, phone };
    // console.log(balanceData)
    userClient.admin.client.phone.set(phoneData).then((res) => {
      setShowModalPhone(false);
      fireSuccessAlert("Numéro de télèphone éditer avec succèss");
    });
  };

  function setBan(expertId) {
    userClient.admin.banAdmin(expertId).then((resp) => {
      fireSuccessAlert("Utilisateur bannis!");
    });
  }

  function setUnban(expertId) {
    userClient.admin.unbanAdmin(expertId).then((resp) => {
      fireSuccessAlert("Utilisateur débanni!");
    });
  }

  function printStats(dates) {
    setDateRange(dates);

    if (dates[0] && dates[1]) {
      const dateStart = dates[0].toLocaleDateString("fr-FR");
      const dateEnd = dates[1].toLocaleDateString("fr-FR");

      profileClient.client
        .getAll(dateStart, dateEnd)
        .then((resp) => {
          console.log(resp.data);
          setData(resp.data.clients);
        })
        .catch((error) => {
          // Gérer l'erreur
        });
    }
  }

  useEffect(() => {
    // setData(createFakeData("clients"));
    printStats(dateRange);

    setIsLoading(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "idShort",
        cell: (info) => (
          <span style={{ color: "#81c313" }}>{info.getValue()}</span>
        ),
      },

      // {
      //   header: "Pseudo",
      //   accessorKey: "pseudo",
      //   cell: (info) => {
      //     const { name, badge } = info.getValue();
      //     return (
      //       <div className="flex-column">
      //         <span>{name}</span>
      //         <span className="badge">{badge}</span>
      //       </div>
      //     );
      //   },
      // },

      // {
      //   header: "Client",
      //   accessorKey: "client",
      //   cell: (info) => info.getValue(),
      // },
      {
        header: "Nom",
        accessorKey: "name",
        cell: (info) => info.getValue(),
      },
      {
        header: "E-mail",
        accessorKey: "email",
        cell: (info) => info.getValue(),
      },

      {
        header: "Téléphone",
        accessorKey: "phone",
        cell: (info) => info.getValue(),
      },
      {
        header: "Inscription",
        accessorKey: "timeRegister",
        cell: (info) => {
          let [day, month, year] = info.getValue().split("/");
          const date = new Date(+year, +month - 1, +day);
          return `${date.getDate()}/${
            date.getMonth() + 1
          }/${date.getFullYear()}`;
        },
      },
      {
        header: "Balance",
        accessorKey: "balance",
        cell: (info) => {
          return info.getValue();
        },
      },
      {
        header: "Actif",
        accessorKey: "active",
        cell: (info) => (
          <span className="badge">
            {info.getValue() ? "ACTIVE" : "NOT ACTIVE"}
          </span>
        ),
      },
      {
        header: "Action",
        accessorFn: (row) => row,
        cell: ({ cell }) => {
          const row = cell.getValue();
          const id = row.id;
          // console.log(row);

          const items = [
            {
              label: "Balance",
              command: (e) => {
                setShowModal(true);
                setSelectedClientId(id);
                setBalance(row.balanceNumber);
              },
            },
            {
              label: "Voir",
              command: () => {
                window.location.href = "./client/" + id;
              },
            },
            {
              label: "Password",
              command: (e) => {
                setShowModalPassword(true);
                setSelectedClientId(id);
              },
            },
            {
              label: "Email",
              command: (e) => {
                setShowModalEmail(true);
                setSelectedClientId(id);
                setEmail(row.email);
              },
            },
            {
              label: "Téléphone",
              command: (e) => {
                setShowModalPhone(true);
                setSelectedClientId(id);
                setPhone(row.phone);
              },
            },
            {
              label: "Bannir",
              command: () => {
                setBan(id);
              },
            },
            {
              label: "Débannir",
              command: () => {
                setUnban(id);
              },
            },
          ];
          return <DropdownButton items={items} />;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },

    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const propsData = {
    globalFilter,
    setGlobalFilter,
    table,
    isLoading,
    dateRange,
    printStats,
  };

  return (
    <>
      <Modal
        title="Modifier le solde"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleAddBalance}
        >
          <label>Balance</label>
          <input
            type="text"
            onChange={(e) => setBalance(e.target.value)}
            value={balance}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>

      <Modal
        title="Modifier le mot de passe"
        show={showModalPassword}
        onClose={() => setShowModalPassword(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleSetPassword}
        >
          <label>Mot de passe</label>
          <input
            type="text"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <Modal
        title="Modifier l'email"
        show={showModalEmail}
        onClose={() => setShowModalEmail(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleSetEmail}
        >
          <label>Email</label>
          <input
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <Modal
        title="Modifier le numéro"
        show={showModalPhone}
        onClose={() => setShowModalPhone(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleSetPhone}
        >
          <label>Téléphone</label>
          <PhoneInput
              placeholder="Téléphone"
              value={phone}
              onChange={setPhone}
              country="FR"
            />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <FilterBar {...propsData} />
      {isTabletOrMobile ? (
        <SearchableTableMD {...propsData} />
      ) : (
        <SearchableTableXL {...propsData} />
      )}
    </>
  );
}

export default ClientListTableLogic;
