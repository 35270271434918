export const frenchLocale = {
  closeText: "Fermer",
  prevText: "Précédent",
  nextText: "Suivant",
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
  monthNamesShort: [
    "Jan",
    "Fév",
    "Mar",
    "Avr",
    "Mai",
    "Jun",
    "Jul",
    "Aoû",
    "Sep",
    "Oct",
    "Nov",
    "Déc",
  ],
  dayNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ],
  dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
  weekHeader: "Semaine",
  firstDay: 1,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: "",
  timeOnlyTitle: "Choisir l'heure",
  timeText: "Heure",
  hourText: "Heures",
  minuteText: "Minutes",
  secondText: "Secondes",
  currentText: "Maintenant",
  ampm: false,
  month: "Mois",
  week: "Semaine",
  day: "Jour",
  allDayText: "Toute la journée",
};
