import { HiOutlineUserCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function MailBox(props) {

  const officeState = useSelector((state) => state.office);
  const navigate = useNavigate();

  const handle = (data) => {

    if(officeState.data.officeName){

     navigate(`/${officeState.data.officeName}/${props.type}/conversation`, {
       state: { data },
     });

    }

   };

  return (
    <div className="mail-box">
      <div className="top-bar">
        <div className="user">
          <HiOutlineUserCircle />
          <span className="user-name">{ props.userName }</span>
        </div>
        <span className="date">{ props.date }</span>
      </div>

      <div className="title">{ props.title }</div>
      <p>{ props.text }</p>

      <div className="btn">
        <button onClick={() => handle(props.data)}>Répondre</button>
      </div>
    </div>
  );
}

export default MailBox;