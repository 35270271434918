import apiClient from "../apiClient";

export const adviceClient = {
  getAll: () => apiClient.get("/users/admins/opinions/all/view/"),
  create: ({ expertId, message, note }) =>
    apiClient.post("/users/opinions/create/", {
      expertId,
      message,
      note,
    }),
  update: ({ opinionId, message }) =>
    apiClient.post("/users/admins/opinions/edit/", { opinionId, message }),
  delete: (opinionId) =>
    apiClient.delete("/users/admins/opinions/delete/", { data: { opinionId } }),

  action: {
    update: ({ opinionId, action }) =>
      apiClient.post("/users/admins/opinions/actions/", { opinionId, action }),
  },
};
