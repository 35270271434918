import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import axios from "axios";
import { Calendar } from "primereact/calendar";
import { useEffect, useState } from "react";
import AnalyseexpertTable from "../../components/tables/analyseexpertTable";
import FrenchCalendar from "../../components/calendar/frenchCalendar";
import { statsClient } from './../../network/lib/stats';

function AnalyseExpertPage() {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [userStatsData, userStatsDataSet] = useState([]);
  const [isLoading,setIsLoading] = useState(true)

  const config = {
    headers: {
      sessionId: localStorage.getItem("sessionId"),
    },
  };

  function printStats(dates) {
    setDateRange(dates);

    if (dates[0] && dates[1]) {
      const dateStart = dates[0].toLocaleDateString("fr-FR");
      const dateEnd = dates[1].toLocaleDateString("fr-FR");

      statsClient.admin.expert.getByDateRange(dateStart,dateEnd)
        .then((resp) => {
          userStatsDataSet(resp.data.experts);
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false)
          // Gérer l'erreur
        });
    }
  }

  useEffect(() => {
    printStats(dateRange);
  }, []);

  return (
    <div id="analyseExpert">
      <div className="top-bar">
        <h2>Analyse Experts</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Expert
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Analyse
          </Link>
        </div>
      </div>

      <div className="content-box">
        <div className="filter">
          <div className="date">
            <label htmlFor="inputRange">Date</label>
            <FrenchCalendar
              dateFormat="dd MM, yy"
              value={dateRange}
              onChange={(e) => printStats(e.value)}
              selectionMode="range"
              id="inputRange"
            />
          </div>
        </div>

        <AnalyseexpertTable experts={userStatsData} isLoading={isLoading}/>
      </div>
    </div>
  );
}

export default AnalyseExpertPage;
