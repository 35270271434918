import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CalendarProvider } from "../../components/schedulingApp/context/CalendarContext";
import SchedulingApp from "../../components/schedulingApp/schedulingApp";
import { fireSuccessAlert } from "./../utils/Swal";

function PaymentRdv({
  options,
  selectedProduct,
  setSelectedProduct,
  setPaymentData,
  expertId,
  dates,
}) {
  const bookingState = useSelector((state) => state.booking);
  const [checkTC, setCheckTC] = useState(false);

  const handleFormSubmit = (e) => {
    // console.log(e);
    e.preventDefault();
    if (!bookingState.loading && checkTC) {
      setPaymentData({
        productId: selectedProduct.id,
        expertId: expertId,
        calendarId: bookingState.data.calendarId,
        slots: bookingState.data.slots,
      });
      fireSuccessAlert("Proceed to Payment Below");
    }
  };

  return (
    <div>
      <h2 className="mb-2 header-2 underline">Information complementaires</h2>
      <form className="payment-rdv-form" onSubmit={handleFormSubmit}>
        <h4 className="mb-2">Sélectionnez votre heure de rendez-vous</h4>
        <div className="form-group">
          <label htmlFor="type">Durée d'appel</label>
          <Dropdown
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.value)}
            options={options}
            placeholder="Select Duration"
            optionLabel="title"
          />
        </div>
        {selectedProduct && (
          <CalendarProvider>
            <SchedulingApp
              expertId={expertId}
              productId={selectedProduct.id}
              dates={dates}
            />
          </CalendarProvider>
        )}

        <div className="check-form mb-1">
          <input
            id="schedule-tc-check"
            type="checkbox"
            checked={checkTC}
            onChange={(e) => setCheckTC(!checkTC)}
          />
          <label className="ml-1" htmlFor="schedule-tc-check">
            J'ai lu les termes et conditions
          </label>
        </div>
        <button type="submit" className="w-full primary">
          Valider
        </button>
      </form>
    </div>
  );
}

export default PaymentRdv;
