import { Icon } from "@iconify/react";
import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { userClient } from "../../../network/lib/user";
import DropdownButton from "../../dropdownButton/DropdownButton";
import Modal from "../../modal/modal";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import { fireSuccessAlert } from "./../../utils/Swal";
import FilterBar from "./filterBar";
import { MultiSelect } from "primereact/multiselect";
import DropDownChipSelect from "../../dropdownChipSelect/DropDownChipSelect";

function ExpertTableLogic() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [newExpertEmail, setNewExpertEmail] = useState("");

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  const handleUpdateBenefits = ({expertId,benefits}) => {
    userClient.expert.benefits.update({expertId, benefits}).then(res => {
      fireSuccessAlert("Service ajouté avec succès")
    })
  }

  const handleBan = (id) => {
    userClient.admin.banAdmin(id).then((res) => {
      fireSuccessAlert("l'utilisateur est banni");
    });
  };

  const handleUnban = (id) => {
    userClient.admin.unbanAdmin(id).then((res) => {
      fireSuccessAlert("l'utilisateur n'est plus banni");
    });
  };

  const handleUnrank = (id) => {
    userClient.admin.unrank(id).then((res) => {
      fireSuccessAlert("Utilisateur est devenue client!");
      setData((prevState) => prevState.filter((user) => user.id !== id));
    });
  };

  function getAllExperts() {
    userClient.expert
      .getAll()
      .then((resp) => {
        setData(resp.data.experts);
        console.log(resp.data.experts);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        // Gérer l'erreur
      });
  }

  useEffect(() => {
    getAllExperts();
  }, []);

  const handleAddNewExpertSubmit = (e) => {
    e.preventDefault();
    let data = { email: newExpertEmail };
    userClient.expert
      .add(data)
      .then(() => {
        // console.log(res);
        fireSuccessAlert("Expert ajouté avec succès.");
        setShowModal(false);
        setIsLoading(true);
        getAllExperts();
      })
      .catch((error) => console.log(error));
  };

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "idShort",
        cell: (info) => (
          <span style={{ color: "#81c313" }}>{info.getValue()}</span>
        ),
      },

      {
        header: "Nom",
        accessorKey: "name",
        cell: (info) => {
          const imageProfile = info.row.original.imageProfile;
          const name = info.getValue();
          return (
            <div className="icon">
              {imageProfile ? (
                <img src={imageProfile} alt="profil" />
              ) : (
                <Icon
                  icon="bi:person-circle"
                  width={24}
                  height={24}
                  color="#81C313"
                />
              )}
              <span>{name}</span>
            </div>
          );
        },
      },

      {
        header: "Compétence",
        accessorKey: "tags",
        cell: (info) => {
          return info.getValue()?.map((tag, index) => (
            <span key={index} className="badge">
              {tag}
            </span>
          ));
        },
      },
      {
        header: "E-mail",
        accessorKey: "email",
        cell: (info) => info.getValue(),
      },
      {
        header: "Téléphone",
        accessorKey: "phone",
        cell: (info) => info.getValue(),
      },
      {
        header: "Statut",
        accessorKey: "statue",
        cell: (info) => <span className="badge-green">{info.getValue()}</span>,
      },
      {
        header: "Communication",
        accessorFn: row => row,
        cell: (info) => {
          const row = info.getValue();
          console.log(row);
          return <DropDownChipSelect benefits={row.benefits} expert={row} updateBenefits={handleUpdateBenefits}/>;
          // return info.getValue()?.map((benefit, index) => (
          //   <span key={index} className="badge">
          //     {benefit}
          //   </span>
          // ));
        },
      },
      {
        header: "Action",
        accessorKey: "id",
        cell: (info) => {
          const id = info.getValue();
          const items = [
            {
              label: "Bannir",
              command: (e) => {
                // console.log(e)
                console.log("banning", id);
                handleBan(id);
              },
            },
            {
              label: "Débannir",
              command: () => {
                handleUnban(id);
              },
            },
            {
              label: "Dégrader",
              command: () => {
                handleUnrank(id);
              },
            },
          ];
          return <DropdownButton items={items} />;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const searchableProps = { globalFilter, setGlobalFilter, table, isLoading };
  const filterProps = { globalFilter, setGlobalFilter, table };
  return (
    <>
      <Modal
        title="Entrer l'adresse e-mail"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleAddNewExpertSubmit}
        >
          <label>Email</label>
          <input
            type="text"
            onChange={(e) => setNewExpertEmail(e.target.value)}
            value={newExpertEmail}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <FilterBar
        {...filterProps}
        withModal={true}
        setShowModal={setShowModal}
      />
      {isTabletOrMobile ? (
        <SearchableTableMD {...searchableProps} />
      ) : (
        <SearchableTableXL {...searchableProps} />
      )}
    </>
  );
}

export default ExpertTableLogic;
