import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

import AnalyseClientTableLogic from "../../components/react-tables/analyseClientTable/analyseClientTableLogic";

function AnalyseClientPage() {

  return (
    <div id="analyseClient">
      <div className="top-bar">
        <h2>Analyse Clients</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Client
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Analyse
          </Link>
        </div>
      </div>
      <AnalyseClientTableLogic />
    </div>
  );
}

export default AnalyseClientPage;
