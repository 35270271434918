import { useNavigate } from "react-router-dom";

function BoxNewCustomer(props) {
  const navigate = useNavigate();
  const { stats } = props;

  return (
    <div className="box box-consultations">
      <h3
        className="cursor"
        onClick={() => {
          navigate("consultation");
        }}
      >
        Nouveau client
      </h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">Ce mois-ci</span>

          <span className="primary-text">{stats.monthCustomer}</span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text">{stats.lastMonthCustomer}</span>
        </div>
        <div>
          <span className="sub-title-gray">Aujourd'hui</span>

          <span className="primary-text">{stats.todayCustomer}</span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text">{stats.yesterdayCustomer}</span>
        </div>
      </div>
    </div>
  );
}

export default BoxNewCustomer;
