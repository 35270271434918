import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import React from "react";
import NavigateBar from "./navigateBar";
import InfoMessageBox from "../utils/infoMessageBox";
import TableSkeleton from "../../skeletons/expert/tableSkeleton";

function SearchableTableMD({ table, isLoading }) {
  const numOfColumns = table.getAllColumns().length;
  if (isLoading) return <TableSkeleton columnCount={numOfColumns} rowCount={5} md />;
  return (
    <>
      <Box sx={{ width: "100%" }} className="tableContainer">
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {table.getRowModel().rows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2}>
                    <InfoMessageBox />
                  </TableCell>
                </TableRow>
              ) : (
                table.getRowModel().rows.map((row) =>
                  row.getVisibleCells().map((cell, index) => {
                    return (
                      <React.Fragment key={cell.id}>
                        <TableRow
                          key={cell.id}
                          sx={{
                            border: "1.2px solid #d7d7d7 !important",
                          }}
                        >
                          <TableCell
                            sx={{
                              border: "none !important",
                            }}
                          >
                            <span className="text-bold">
                              {cell.column.columnDef.header}
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none !important",
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        </TableRow>
                        {index !== 0 && index % (numOfColumns - 1) == 0 ? (
                          <TableRow sx={{ padding: "0 !important" }}>
                            <TableCell
                              colSpan={2}
                              sx={{
                                padding: "0 !important",
                                position: "relative",
                                height: "4rem",
                                border: "none !important",
                                backgroundColor: "#f9f9f9",
                              }}
                            ></TableCell>
                          </TableRow>
                        ) : null}
                      </React.Fragment>
                    );
                  })
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <NavigateBar table={table} />
    </>
  );
}

export default SearchableTableMD;
