import React from "react";
import Skeleton from "react-loading-skeleton";

function ChartSkeleton() {
  return (
    <div>
      {Array.from({ length: 6 }, (_, index) => (
        <Skeleton style={{marginBottom: "2.5rem"}} key={index}/>
      ))}
    </div>
  );
}

export default ChartSkeleton;
