import { useEffect, useState } from "react";
import ConsultationBox from "../../components/expert/consultationBox";
import { userClient } from "../../network/lib/user";
import ConsultationMeetBox from "../../components/expert/consultationMeetBox";
import { format } from 'date-fns';
import FrenchCalendar from "../../components/calendar/frenchCalendar";
import { useParams } from "react-router-dom";

function ClientPage() {
  const { clientId } = useParams();
  const [userData, userDataSet] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [isLoading, setIsLoading] = useState(true);

  function printStats(dates) {
    setDateRange(dates);

    if (dates[0] && dates[1]) {
      const dateStart = dates[0].toLocaleDateString("fr-FR");
      const dateEnd = dates[1].toLocaleDateString("fr-FR");

      userClient.admin.client.getByDateRange(clientId,dateStart,dateEnd)
        .then((resp) => {
          userDataSet(resp.data);
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          setIsLoading(false)
          // Gérer l'erreur
        });
    }
  }

  useEffect(() => {
    printStats(dateRange);
  }, []);

  return (
    <div id="consultationExpert">
      <h3>Information Client ({userData.clientName})</h3>
      <div className="filter-bar">
        <div>
            <h3>{userData.clientName}</h3>
            <span className="email">{userData.email}</span>
            <span className="email">+{userData.phone}</span>
            <span className="email">{userData.balance}</span>
        </div>
        <div className="date">
          <FrenchCalendar
            dateFormat="dd MM, yy"
            onChange={(e) => printStats(e.value)}
            selectionMode="range"
            value={dateRange}
            id="inputRange"
          />
        </div>
      </div>

      <ConsultationBox
        title="ACHATS EFFECTUÉS"
        titles={["ID", "Nom", "Type", "Date", "Prix"]}
        data={userData.buys}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="APPELS EFFECTUÉS"
        titles={["ID", "Expert", "Durée", "Date", "Status"]}
        data={userData.calls}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="TRANSACTIONS DE PAIEMENTS"
        titles={["ID", "Date", "Heure", "Status", "Prix (en centime)"]}
        data={userData.transacs}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="RENDEZ-VOUS EFFECTUÉS"
        titles={["ID", "Expert", "Date", "Heure", "Nombre d'appelle"]}
        data={userData.meets}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="MAILS EFFECTUÉS"
        titles={["ID", "Expert", "Sujet", "Date", "Effectué"]}
        data={userData.mails}
        isLoading={isLoading}
      />

    </div>
  );
}

export default ClientPage;
