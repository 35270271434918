import { LayoutClient } from "../../components/layout/layout";
import PurchaseTable from "../../components/tables/purchaseTable";
import { useParams } from "react-router-dom";

function MyPurchaseClient() {
  return (
    <div id="myPurchaseClient">
      <h2>Mes achats</h2>

      <PurchaseTable />
    </div>
  );
}

export default MyPurchaseClient;
