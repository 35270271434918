import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { useState } from "react";
import { userClient } from "../../network/lib/user";
import { fireSuccessAlert } from "../../components/utils/Swal";
import { InputText } from "primereact/inputtext";

const Sms = () => {
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      message: message,
    };
    userClient.admin.sms.create(formData).then((res) => {
      fireSuccessAlert("Sms created");
    });
  };

  return (
    <>
      <h2>SMS</h2> <br></br>
      <form className="flex-column admin-email-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="admin-email-message">Message</label>
          <InputTextarea
            id="admin-email-message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
            cols={30}
          />
        </div>
        <div className="form-group">
          <button type="submit" className="primary">
            Envoyer
          </button>
        </div>
      </form>
    </>
  );
};

export default Sms;
