import OfficeTableLogic from "../../components/react-tables/officesTable/officesTableLogic";
// import ExpertsTable from "../../components/tables/expertsTable";

function OwnerHomePage() {
  return (
    <div id="expert">
      <OfficeTableLogic />
    </div>
  );
}

export default OwnerHomePage;
