import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: true,
  data: null,
};

const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setBooking(state, action) {
      // console.log(action.payload)
      state.data = action.payload;
      state.loading = false;
    },
  },
});

export const { setBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
