import { useEffect, useState } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import RatingModal from "../modal/ratingModal";
import InfoMessageBoxTD from "../react-tables/utils/infoMessageBox";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import { userClient } from './../../network/lib/user';

function ConsultationsTable() {
  const [userConsultationsData, userConsultationsDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [callState, setCallState] = useState();

  useEffect(() => {
   
    userClient.client.consultation.getAll()
      .then((resp) => {
        userConsultationsDataSet(resp.data.calls);
        // setFakeRating(4,0)
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  // Data structure of userConsultation
  console.log(userConsultationsData);

  if (isLoading) return <TableSkeleton columnCount={5} rowCount={4} />;
  return (
    <>
      <div className="tableContainer">
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Heure</Th>
              <Th>EXPERT</Th>
              <Th>DURÉE</Th>
              <Th>N°COMMANDE</Th>
              <Th>TYPE</Th>
              <Th>RATING</Th>
            </Tr>
          </Thead>
          <Tbody>
            {userConsultationsData.length === 0 ? (
              <InfoMessageBoxTD colSpan={5} />
            ) : (
              userConsultationsData.map((call, index) => {
                return (
                  <Tr key={index}>
                    <Td className="color-red">{call["date"]}</Td>
                    <Td>{call["hour"]}</Td>
                    <Td className="color-green">{call["expertName"]}</Td>
                    <Td>{call["duration"]}</Td>
                    <Td className="color-purple">{call["orderId"]}</Td>
                    <Td>{call["callType"]}</Td>
                    <Td>
                      <button onClick={() => {
                        setShowModal(true)
                        setCallState(call)
                        }}>
                        Donner une note
                      </button>
                    </Td>
                    <RatingModal
                      call={callState}
                      showModal={showModal}
                      setShowModal={setShowModal}
                    />
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
      </div>
    </>
  );
}

export default ConsultationsTable;
