import { useEffect, useState } from "react";
import MailBox from "../../components/expert/mailBox";
import NavigateBar from "../../components/tables/navigateBar";
import { mailClient } from './../../network/lib/mail';
import AdviceBoxSkeleton from "../../components/skeletons/expert/adviceBoxSkeleton";

function MailsClient() {

  const [mailsData, mailsDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    mailClient.client.getAll()
      .then((resp) => {
        console.log(resp.data.mails)
        mailsDataSet(resp.data.mails);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  const renderWithData = () => {
    return mailsData.map((mail, i) => {
      console.log(mail)
      return (
        <MailBox key={i}
          userName={mail["expertName"]}
          date={mail["date"]}
          title={mail["subject"]}
          text={mail["description"]}
          type={"client"}
          data={mail}
          image={mail["image"]}
        />
      );
    });
  };

  return (
    <div id="mails">
      <h2>Mes Mails</h2>

      {isLoading ? <AdviceBoxSkeleton count={4} /> : renderWithData()}

    </div>
  );
}

export default MailsClient;
