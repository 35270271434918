import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import InfoMessageBoxTD from "../react-tables/utils/infoMessageBox";

function StatistiqueTable(props) {
  const { isLoading } = props;
  if (!props.purchases) {
    return;
  }

  if (isLoading) return <TableSkeleton columnCount={8} rowCount={3} />;

  return (
    <div className="tableContainer">
      <Table>
        <Thead>
          <Tr>
            <Th>Id</Th>
            <Th>Date</Th>
            <Th>Heure</Th>
            <Th>Type</Th>
            <Th>
              Code
              <br />
              Promo
            </Th>
            <Th>Client</Th>
            <Th>Gain</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.purchases.length === 0 ? <InfoMessageBoxTD colSpan={8} /> : props.purchases.map((purchase, index) => (
            <Tr key={index}>
              <Td className="color-green">{purchase["id"]}</Td>
              <Td className="color-red">{purchase["date"]}</Td>
              <Td>{purchase["hour"]}</Td>
              <Td>{purchase["purchaseType"]}</Td>
              <Td>{purchase["promoName"]}</Td>
              <Td>{purchase["clientName"]}</Td>
              <Td>{purchase["expertGain"]}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </div>
  );
}

export default StatistiqueTable;
