import React from "react";
import { useNavigate } from "react-router-dom";
import BatMenuAdmin from "./batMenuByRoles/batMenuAdmin";
import BatMenuExpert from "./batMenuByRoles/batMenuExpert";
import BatMenuClient from "./batMenuByRoles/batMenuClient";
import BatMenuOwner from "./batMenuByRoles/batMenuOwner";

function BatMenu({ role, officeName, showBatMenu, setShowBatMenu }) {

  const navigate = useNavigate();

  const navigation = (url) => {
    navigate(url);
    setShowBatMenu("");
  }

  switch (role) {
    case "owner":
      return (
        <BatMenuOwner
          officeName={officeName}
          showBatMenu={showBatMenu}
          setShowBatMenu={setShowBatMenu}
          navigation={navigation}
        />
      );
    case "admin":
      return (
        <BatMenuAdmin
          officeName={officeName}
          showBatMenu={showBatMenu}
          setShowBatMenu={setShowBatMenu}
          navigation={navigation}
        />
      );
    case "expert":
      return (
        <BatMenuExpert
          officeName={officeName}
          showBatMenu={showBatMenu}
          setShowBatMenu={setShowBatMenu}
          navigation={navigation}
        />
      );
    case "client":
      return (
        <BatMenuClient
          officeName={officeName}
          showBatMenu={showBatMenu}
          setShowBatMenu={setShowBatMenu}
          navigation={navigation}
        />
      );
    default:
      return null;
  } 
}

export default BatMenu;
