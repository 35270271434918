import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import NavigateBar from "./navigateBar";
import { Icon } from "@iconify/react";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import InfoMessageBox from "../react-tables/utils/infoMessageBox";

function AnalyseexpertTable(props) {
  const { experts, isLoading } = props;
  if (isLoading) return <TableSkeleton columnCount={4} rowCount={5} />;
  return (
    <>
      <div className="tableContainer">
        <table>
          <thead>
            <colgroup span="1"></colgroup>
            <tr>
              <td rowSpan="1"></td>
              <td colSpan="4" scope="colgroup" className="head">
                Appels
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="second-head">
              <th scope="col" className="br">
                <div className="sb">
                  Expert
                  <Icon icon="ph:arrows-down-up-thin" color="#A7A7A7" />
                </div>
              </th>
              <th scope="col">
                <Icon icon="bi:telephone-inbound" />
              </th>
              <th scope="col">
                <Icon icon="ph:arrows-down-up-thin" color="#A7A7A7" />
                <Icon icon="uiw:time-o" />
              </th>
              <th scope="col">
                <Icon icon="ph:arrows-down-up-thin" color="#A7A7A7" />
                <Icon icon="material-symbols:euro" />
              </th>
            </tr>

            {experts.length === 0 ? <InfoMessageBox colSpan={4}/> : experts.map((expert, index) => (
              <tr key={index}>
                <th scope="row" className="br">
                  <div className="sb">{expert["name"]}</div>
                </th>
                <td>{expert["callMissed"]}</td>
                <td>{expert["time"]}</td>
                <td>{expert["gain"]} €</td>
              </tr>
            ))}
            
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AnalyseexpertTable;
