import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { useState } from "react";
import { userClient } from "../../network/lib/user";
import { fireSuccessAlert } from "./../../components/utils/Swal";
import { InputText } from "primereact/inputtext";

const Email = () => {
  const [userType, setUserType] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      subject: subject,
      message: message,
      type: userType,
    };
    userClient.admin.email.create(formData).then((res) => {
      fireSuccessAlert("Email created");
    });
  };

  return (
    <form className="flex-column admin-email-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Cible</label>
        <div className="flex-row gap-2">
          <div className="radio-group">
            <input
              type="radio"
              id="user-type-expert"
              name="type"
              value="EXPERT"
              onChange={(e) => setUserType(e.target.value)}
              checked={userType === "EXPERT"}
            />
            <label htmlFor="user-type-expert">Experts</label>
          </div>
          <div className="radio-group">
            <input
              type="radio"
              id="user-type-client"
              name="type"
              value="CLIENT"
              onChange={(e) => setUserType(e.target.value)}
              checked={userType === "CLIENT"}
            />
            <label htmlFor="user-type-client">Clients</label>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="admin-email-subject">Sujet</label>

        <InputText
          id="admin-email-subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="admin-email-message">Message</label>
        <InputTextarea
          id="admin-email-message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows={5}
          cols={30}
        />
      </div>
      <div className="form-group">
        <button type="submit" className="primary">
          Envoyer
        </button>
      </div>
    </form>
  );
};

export default Email;
