import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavLink from "../../components/path/profile/navLink";
import ProfileBar from "../../components/path/profile/profileBar";
import { profileClient } from './../../network/lib/profile';
import { callClient } from "../../network/lib/call";
import { fireSuccessAlert } from "../../components/utils/Swal";

function StarRating(props) {
  const { advice } = props;

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < advice; i++) {
      stars.push(<Icon key={i} icon="material-symbols:star" />);
    }
    return stars;
  };

  return <>{renderStars()}</>;
}

function StringWithNewlinesToParagraphs({ text }) {
  if (!text) {
    return null;
  }
  const paragraphs = text
    .split("\n")
    .map((line, index) => <p key={index}>{line}</p>);

  return <>{paragraphs}</>;
}

function ListWithDynamicContent({ expertId,list, officeName }) {
  const navigate = useNavigate();
  // Check if the 'list' prop exists and is an array
  if (!list || !Array.isArray(list)) {
    return null; // or any other fallback content if desired
  }
  return (
    <div>
      {list.map((item, index) => {
        let divContent = null;

        if (item.includes("CHAT")) {
          divContent = (
            <div className="bar green" key={index} >
              <Icon icon="material-symbols:chat" height={24} width={24} />
              <span>Discuter en ligne</span>
            </div>
          );
        } else if (item.includes("MAIL")) {
          divContent = (
            <div
              className="bar green"
              key={index}
              onClick={() =>
                window.open("/" + officeName + "/client/payment-email/" + expertId , "_blank")
              }
            >
              <Icon icon="material-symbols:mail" height={24} width={24} />
              <span>Consultation par mail</span>
            </div>
          );
        } else if (item.includes("MEET")) {
          divContent = (
            <div
              className="bar green"
              key={index}
              onClick={() =>
                window.open("/" + officeName + "/client/payment-rdv/"+ expertId, "_blank")
              }
            >
              <Icon icon="fa6-solid:calendar-days" height={24} width={24} />
              <span>Planifier un rendez-vous</span>
            </div>
          );
        } else if (item.includes("CALL")) {
          divContent = (
            <div className="bar green" key={index} onClick={() =>
              navigate("/" + officeName + "/client/expertList/" + expertId + "/call")
            }>
              <Icon icon="ic:outline-access-time-filled" height={24} width={24} />
              <span>Démarrer un appel</span>
            </div>
          );
        }

        return divContent;
      })}
    </div>
  );
}

function ExpertProfilePage() {
  const { officeName, expertId, call } = useParams();

  const [expertData, expertDataSet] = useState([]);

  const redirectPayment = () => {
    window.open("/" + officeName + "/client/payement", "_blank");
  };

  useEffect(() => {
    if(call) {
      callClient.client.call({ expertId })
      .then((resp) => {
        fireSuccessAlert("Appel en cours. Merci de répondre (vérifiez bien votre numéro)").then(() => {
          const currentURL = window.location.href;
          // Check and redirect if the URL ends with "/call"
          if (currentURL.endsWith("/call")) {
            window.location.replace(currentURL.slice(0, -5));
          }
        });
      });
    }
    // axios
    //   .get(
    //     "https://api.vooxhub.com/profiles/experts/public/view/?expertId=" +
    //       expertId,
    //     null
    //   )
    profileClient.expert.getPublicProfile(expertId)
      .then((resp) => {
        expertDataSet(resp.data);
      })
      // .catch((error) => {});
  }, [call]);
  
  return (
    <div id="expertListClient">
      <div id="profilePath">
        <a className="back-btn" href={"/" + officeName + "/client/expertList/"}>
          <Icon icon="material-symbols:arrow-back" />
        </a>

        <div className="container">
          <ProfileBar
            name={expertData["name"]}
            voice={expertData["voice"]}
            advice={expertData["advice"]}
            scores={expertData["scores"]}
            callCount={expertData["callCount"]}
            imageProfile={expertData["imageProfile"]}
          />

          <NavLink selected={0} />
          <div className="col2-1">
            <div className="box">
              <StringWithNewlinesToParagraphs
                text={expertData["description"]}
              />
            </div>
            <div className="box">
              <span className="title">Lancer une consultation</span>
              <ListWithDynamicContent
                expertId={expertId}
                officeName={officeName}
                list={expertData["benefits"]}
              />
            </div>
          </div>
          <NavLink selected={1} />
          <div id="forfaits" className="col2-2">
            <div className="box">
              {expertData["plans"]
                ? expertData["plans"].map((plan, index) => {
                    return (
                      <div key={index} className="block">
                        <span className="top-bar">{plan["name"]}</span>
                        <div className="list">
                          <Icon icon="ic:sharp-access-time" />
                          <span>
                            {plan["minutes"]} Minutes
                            <br />
                            pour {plan["prices"]} €
                          </span>
                        </div>
                        <span className="footer-bar" onClick={redirectPayment}>
                          Choisir du forfait
                        </span>
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="box">
              <h3>Disponiblites</h3>
              {expertData["plannings"]
                ? expertData["plannings"].map((planning, index) => {
                    return (
                      <div key={index} className="ligne">
                        <div>
                          <span>{planning["name"]}</span>
                          <span>({planning["date"]})</span>
                        </div>
                        <div>
                          {planning["schedules"].map((schedule, index) => {
                            return <span key={index}>{schedule}</span>;
                          })}
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          <NavLink selected={2} />
          <div id="commentaires" className="col2-3">
            <div className="box">
              <span className="title">Commentaires</span> <br></br>
              {expertData["comments"]
                ? expertData["comments"].map((comment, index) => {
                    return (
                      <>
                      <div className="name">{comment["clientName"]}</div>
                        <div className="stars-box">
                          <div className="stars">
                            <StarRating advice={comment["scores"]} />
                          </div>
                          <span>{comment["date"]}</span>
                        </div>
                        {comment["clientMessage"] ? (
                          <p>{comment["clientMessage"]}</p>
                        ) : (
                          ""
                        )}
                        {comment["expertMessage"] ? (
                          <div className="reponsse">
                            <span className="title_res">
                              Réponse de {comment["expertName"]} :
                            </span>
                            <p>{comment["expertMessage"]}</p>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })
                : ""}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpertProfilePage;
