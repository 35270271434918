import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

function StarRating({ rating }) {
  const fillStar = Array.from({ length: 5 }, (_, index) =>
    index <= rating - 1 ? true : false
  );
  return (
    <span className="flex-row align-center star-icon-group">
      {fillStar.map((elem, index) => (
        <div key={index} className="flex-row align-center">
          {elem ? <AiFillStar /> : <AiOutlineStar />}
        </div>
      ))}
    </span>
  );
}

export default StarRating;
