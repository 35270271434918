import { Icon } from "@iconify/react";
import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import { userClient } from "../../../network/lib/user";
import DropdownButton from "../../dropdownButton/DropdownButton";
import Modal from "../../modal/modal";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import { fireSuccessAlert } from "../../utils/Swal";
import FilterBar from "./filterBar";
import { officeClient } from "../../../network/lib/office";

function OfficeTableLogic() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalUser, setShowModalUser] = useState(false);
  const [newOfficeName, setNewOfficeName] = useState("");
  const [newAdminEmail, setNewAdminEmail] = useState("");

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  const handleSuspend = (name) => {
    officeClient.owner.updateOfficeStatus({name, officeStatus: "SUSPENDED"}).then((res) => {
      fireSuccessAlert("Cabinet est banni").then(() => {
        getAllOffices();
      });
    });
  };

  const handleActive = (name) => {
    officeClient.owner.updateOfficeStatus({name, officeStatus: "ACTIVE"}).then((res) => {
      fireSuccessAlert("Cabinet est débanni").then(() => {
        getAllOffices();
      });
    });
  };

  function getAllOffices() {
    officeClient.owner
      .getAllOffice()
      .then((resp) => {
        setData(resp.data.offices);
        console.log(resp.data.offices);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        // Gérer l'erreur
      });
  }

  useEffect(() => {
    getAllOffices();
  }, []);

  const handleAddNewOfficeSubmit = (e) => {
    e.preventDefault();
    let data = { name: newOfficeName };
    officeClient.owner
      .createOffice(data)
      .then(() => {
        // console.log(res);
        fireSuccessAlert("Cabinet ajouté avec succès.");
        setShowModal(false);
        setIsLoading(true);
        getAllOffices();
      })
      .catch((error) => console.log(error));
  };

  const handleAddNewAdminSubmit = (e) => {
    e.preventDefault();
    let data = { name: newOfficeName, email: newAdminEmail };
    officeClient.owner
      .addAdmin(data)
      .then(() => {
        // console.log(res);
        fireSuccessAlert("Admin ajouté avec succès.");
        setShowModalUser(false);
      })
      .catch((error) => console.log(error));
  };

  const columns = useMemo(
    () => [

      {
        header: "Nom",
        accessorKey: "name",
        cell: (info) => info.getValue(),
      },

      {
        header: "Site",
        accessorKey: "url",
        cell: (info) => info.getValue(),
      },
      {
        header: "E-mail",
        accessorKey: "email",
        cell: (info) => info.getValue(),
      },
      {
        header: "Auth Link",
        accessorKey: "name",
        cell: (info) => {
          const name = info.getValue();
          return (
            <a href={"https://app.vooxhub.com/" + name + "/login"}>{"https://app.vooxhub.com/" + name + "/login"}</a>
          );
        },
      },
      {
        header: "Statut",
        accessorKey: "officeStatus",
        cell: (info) => <span className="badge-green">{info.getValue()}</span>,
      },
      {
        header: "Action",
        accessorKey: "name",
        cell: (info) => {
          const name = info.getValue();
          const items = [
            {
              label: "Suspendre",
              command: (e) => {
                handleSuspend(name);
              },
            },
            {
              label: "Rétablir",
              command: () => {
                handleActive(name);
              },
            },
            {
              label: "Admin",
              command: () => {
                setNewOfficeName(name);
                setShowModalUser(true);
              },
            },
          ];
          return <DropdownButton items={items} />;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const searchableProps = { globalFilter, setGlobalFilter, table, isLoading };
  const filterProps = { globalFilter, setGlobalFilter, table };
  return (
    <>
      <Modal
        title="Entrer le nom du cabinet"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleAddNewOfficeSubmit}
        >
          <label>Nom</label>
          <input
            type="text"
            onChange={(e) => setNewOfficeName(e.target.value)}
            value={newOfficeName}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <Modal
        title="Entrer l'email de l'utilisateur"
        show={showModalUser}
        onClose={() => setShowModalUser(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleAddNewAdminSubmit}
        >
          <label>E-mail</label>
          <input
            type="text"
            onChange={(e) => setNewAdminEmail(e.target.value)}
            value={newAdminEmail}
          />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
      <FilterBar
        {...filterProps}
        withModal={true}
        setShowModal={setShowModal}
      />
      {isTabletOrMobile ? (
        <SearchableTableMD {...searchableProps} />
      ) : (
        <SearchableTableXL {...searchableProps} />
      )}
    </>
  );
}

export default OfficeTableLogic;
