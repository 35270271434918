import { configureStore } from "@reduxjs/toolkit";
import officeReducer from "../features/office/officeSlice";
import userReducer from "../features/user/userSlice"
import bookingReducer from "../features/booking/bookingSlice"

const store = configureStore({
  reducer: {
    office: officeReducer,
    user: userReducer,
    booking: bookingReducer
  },
});

export default store;
