import React from "react";

function CabinetConfigurationTab(props) {

  const {office,
      emailData,
      emailDataSet,
      urlData,
      urlDataSet,
      cgvData,
      cgvDataSet} = props;

  return (
    <div className="tab-body">
      <label htmlFor="cabinetName">Nom du cabinet</label>
      <input
        name="cabinetName"
        value={office}
        className="input-light"
        disabled
      />
      <label htmlFor="cabinetEmail">Email du cabinet</label>
      <input
        value={emailData}
        onChange={(e) => {emailDataSet(e.target.value)}}
        name="cabinetEmail"
        className="input-light"
      />
      <label htmlFor="urlMain">Site</label>
      <input
        name="urlMain"
        value={urlData}
        onChange={(e) => {urlDataSet(e.target.value)}}
        className="input-light"
      />
      <label htmlFor="urlCgv">Lien des CGV</label>
      <input
        name="urlCgv"
        value={cgvData}
        onChange={(e) => {cgvDataSet(e.target.value)}}
        className="input-light"
      />
    </div>
  );
}

export default CabinetConfigurationTab;
