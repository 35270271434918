import { Icon } from "@iconify/react";
import React from 'react';
import { useNavigate } from "react-router-dom";

const LogoutHandler = (props) => {

    const navigate = useNavigate();

    function handleLogoutClick() {

        localStorage.removeItem('sessionId');
        navigate("/" + props.officeName + "/login/");

    }

    return (
        <li>
            <Icon icon="mingcute:exit-fill" />
            <span onClick={() => handleLogoutClick()}>Se déconnecter</span>
        </li>
    );

}

export default LogoutHandler;