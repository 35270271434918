import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import InfoMessageBoxTD from "../react-tables/utils/infoMessageBox";

function ConsultationBox(props) {
  const dataConsultation = props.data || [];

  return (
    <div className="consultation-box">
      <h4 className="title">{props.title}</h4>
      <div className="tableContainer">
        {props.isLoading ? (
          <TableSkeleton columnCount={5} rowCount={2} />
        ) : (
          <Table>
            <Thead>
              <Tr>
                {props.titles.map((elem, i) => {
                  return <Th key={i}>{elem}</Th>;
                })}
              </Tr>
            </Thead>
            <Tbody>
              <CellRow dataConsultation={dataConsultation} cols={props.titles.length}/>
            </Tbody>
          </Table>
        )}
      </div>
    </div>
  );
}

function CellRow(props) {
  const calls = props.dataConsultation;

  if(calls.length === 0) return <InfoMessageBoxTD colSpan={props.cols}/>
  
  return (
    <>
      {calls.map((call) => (
        <Tr key={call.id}>
          {Object.values(call).map((value, index) => (
            <Td key={index}>{value}</Td>
          ))}
        </Tr>
      ))}
    </>
  );
}

export default ConsultationBox;
