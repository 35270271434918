import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropdownButton from "../../dropdownButton/DropdownButton";
import TableSkeleton from "../../skeletons/expert/tableSkeleton";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import { productClient } from "./../../../network/lib/product";
import { fireSuccessAlert } from "./../../utils/Swal";
import FilterBar from "./filterBar";

function ConfigPackageTable() {
  const navigate = useNavigate();
  const officeState = useSelector((state) => state.office);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  const handleEdit = (data) => {
   if(officeState.data.officeName){
    navigate(`/${officeState.data.officeName}/admin/configurationPackage/edit`, {
      state: { data },
    });
   }
  };

  const handleDelete = (id) => {
    productClient.delete(id).then((res) => {
      fireSuccessAlert("Product deleted");
      setData((prevState) => prevState.filter((product) => product.id !== id));
    });
  };

  useEffect(() => {
    // setData(createFakeData("config-package"));

    productClient
      .getAll()
      .then((resp) => {
        setData(resp.data.products);
        setIsLoading(false)
      })
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
        setIsLoading(false);
      });
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Id",
        accessorKey: "idShort",
        cell: (info) => <span className="color-green">{info.getValue()}</span>,
      },

      {
        header: "Type",
        accessorKey: "productType",
        cell: (info) => {
          return (
            <div>
              <span className="badge">{info.getValue()}</span>
              {/* <span className="badge-green">{info.getValue()[1]}</span> */}
            </div>
          );
        },
      },

      {
        header: "Nom",
        accessorKey: "name",
        cell: (info) => info.getValue(),
      },
      {
        header: "Date de création",
        accessorKey: "date",
        cell: (info) => info.getValue(),
      },

      {
        header: "Tarif",
        accessorKey: "price",
        cell: (info) => info.getValue(),
      },

      {
        header: "Minutes",
        accessorKey: "minute",
        cell: (info) => info.getValue(),
      },

      {
        header: "Nombre d'utilisations",
        accessorKey: "used",
        cell: (info) => info.getValue(),
      },

      {
        header: "Action",
        accessorFn: (row) => row,
        cell: ({cell}) => {
          const row = cell.getValue();
          const items = [
            {
              label: "Modifier",
              command: (e) => {
                // console.log(e)
                handleEdit(row);
              },
            },
            {
              label: "Supprimer",
              command: () => {
                handleDelete(row.id);
              },
            },
          ];
          return <DropdownButton items={items} />;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },

    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    // debugTable: true,
  });

  const searchableProps = { globalFilter, setGlobalFilter, table, isLoading };
  const filterProps = { globalFilter, setGlobalFilter, table };

  return (
    <>
      <FilterBar {...filterProps} />
      {isTabletOrMobile ? (
        <SearchableTableMD {...searchableProps} />
      ) : (
        <SearchableTableXL {...searchableProps} />
      )}
    </>
  );
}

export default ConfigPackageTable;
