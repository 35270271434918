import { Calendar } from "primereact/calendar";
import React from "react";
import { addLocale } from "primereact/api";
import { frenchLocale } from "./calendarLocale";

function FrenchCalendar(props) {
  addLocale("fr", frenchLocale);

  return <Calendar {...props} locale="fr" />;
}

export default FrenchCalendar;
