import { Icon } from "@iconify/react";
import { BiVideo } from "react-icons/bi";
import { FaUserAlt } from "react-icons/fa";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { MdCalendarMonth } from "react-icons/md";
import LogoutHandler from "../../utils/logout";

function BatMenuExpert(props) {

  const { navigation } = props;

  return (
    <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
      <div>
        <span className="title">EXPERT</span>
        <ul className="menu">
          <li
            className="selected"
            onClick={() => navigation("/" + props.officeName + "/expert/")}
          >
            <Icon icon="ion:logo-tableau" />
            <span>Tableau de bord</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/expert/planning/classic")
            }
          >
            <MdCalendarMonth />
            <span>Planning Classique</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/expert/planning/appointment")
            }
          >
            <MdCalendarMonth />
            <span>Planning Rendez-Vous</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/expert/consultation")
            }
          >
            <BiVideo />
            <span>Consultations</span>
          </li>
          <li
            onClick={() => navigation("/" + props.officeName + "/expert/advice")}
          >
            <Icon icon="material-symbols:star-outline" />
            <span>Avis</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/expert/statistique")
            }
          >
            <HiOutlineChartSquareBar />
            <span>Statistiques</span>
          </li>
          {/*<li
            onClick={() =>
              navigation("/" + props.officeName + "/expert/mymessages")
            }
          >
            <Icon icon="material-symbols:mark-unread-chat-alt-outline-rounded" />
            <span>Messages</span>
          </li>*/}
          <li
            onClick={() => navigation("/" + props.officeName + "/expert/mails")}
          >
            <Icon icon="material-symbols:mail-outline-rounded" />
            <span>Mails</span>
          </li>
        </ul>
      </div>

      <div>
        <span className="title">AUTRE</span>
        <ul className="menu">
          <li
            onClick={() => navigation("/" + props.officeName + "/expert/profile")}
          >
            <FaUserAlt />
            <span>Mon profil</span>
          </li>
          <LogoutHandler officeName={props.officeName} />
        </ul>
      </div>
    </div>
  );
}

export default BatMenuExpert;