import apiClient from "./../apiClient";

export const userClient = {
  get: (endpoint) => apiClient.get(endpoint),
  expert: {
    add: ({ email }) => apiClient.put("/users/admins/experts/add/", { email }),
    // update: (data) => apiClient.post(`/users/admins/experts/${data.id}`, data),
    getAll: () => apiClient.get("/profiles/admins/experts/all/view/"),
    // delete: (id) => apiClient.delete(`/users/admins/experts/${id}`),

    profile: {
      get: (officeName) =>
        apiClient.get("/users/experts/profiles/view/", {
          params: { officeName: officeName },
        }),
      update: ({
        description,
        phone,
        tags,
        speciality,
        imageProfile,
        imageBanner,
      }) =>
        apiClient.post("/users/experts/profiles/edit/", {
          description,
          phone,
          tags,
          speciality,
          imageProfile,
          imageBanner,
        }),
    },

    activity: {
      setActivity: (activityType) =>
        apiClient.post("/activity/experts/edit/", {
          activityType: activityType,
        }),
      // getActivity: (officeName) => apiClient.get("/users/experts/view/", {
      //   params:{
      //     officeName: officeName
      //   }
      // }),
    },

    benefits: {
      update: ({ expertId, benefits }) =>
        apiClient.post("users/admins/experts/benefits/edit/", {
          expertId,
          benefits,
        }),
    },
    consultation: {
      getAll: () => apiClient.get("/users/experts/consultations/view/"),
    },
    opinion: {
      getAll: () => apiClient.get("/users/experts/opinions/view/"),
      add: ({ opinionId, message }) =>
        apiClient.post("/users/experts/opinions/create/", {
          opinionId,
          message,
        }),
    },
  },
  admin: {
    profile: {
      getAll: () => apiClient.get("/profiles/admins/all/view/"),
    },
    client: {
      getByDateRange: (clientId, dateStart, dateEnd) =>
        apiClient.get(
          `/profiles/admins/clients/view/?clientId=${clientId}&dateStart=${dateStart}&dateEnd=${dateEnd}`
        ),
      balance: {
        set: ({ clientId, balance }) =>
          apiClient.post("/users/admins/balance/set", { clientId, balance }),
      },
      email: {
        set: ({ clientId, email }) =>
          apiClient.post("/users/admins/email/set", { clientId, email }),
      },
      phone: {
        set: ({ clientId, phone }) =>
          apiClient.post("/users/admins/phone/set", { clientId, phone }),
      },
      password: {
        set: ({ clientId, password }) =>
          apiClient.post("/users/admins/password/set", { clientId, password }),
      },
    },
    refund: (orderId) => apiClient.post("/users/admins/refund/", { orderId }),
    refundCheck: (orderId) =>
      apiClient.post("/users/admins/refund/check/", { orderId }),
    addAdmin: (email) => apiClient.put("/users/admins/add/", { email }),
    removeAdmin: (userId) =>
      apiClient.post("/users/admins/remove/", { userId }),
    banAdmin: (userId) => apiClient.post("/users/admins/ban/add/", { userId }),
    unbanAdmin: (userId) =>
      apiClient.post("/users/admins/ban/remove/", { userId }),
    unrank: (userId) =>
      apiClient.post("/users/admins/experts/remove/", { userId }),
    consultation: {
      getAll: (date) =>
        apiClient.get(`/users/admins/consultations/view/?date=${date}`),
    },
    email: {
      create: ({ subject, message, type }) =>
        apiClient.put("/email/admins/create/", { subject, message, type }),
    },
    sms: {
      create: ({ message }) =>
        apiClient.put("/sms/admins/create/", { message }),
    },
  },
  client: {
    consultation: {
      getAll: () => apiClient.get("/users/consultations/view"),
    },
    appointment: {
      getAll: () => apiClient.get("/users/appointments/view"),
    },
    purchase: {
      getAll: () => apiClient.get("/users/purchases/view"),
    },
    phone: {
      set: ({ phone }) => apiClient.post("/users/phone/set", { phone }),
    },
  },
};
