import apiClient from "../apiClient";

export const productClient = {
  getAll: () => apiClient.get('/products/admins/all/view/'),
  get: (productId) =>
    apiClient.get("/products/admins/view/", { params: { productId } }),
  getAllByCategory: (productType) =>
    apiClient.get("/products/all/view/", {
      params: { productType: productType },
    }),
  getByCategory: (productType, officeName) =>
    apiClient.get("/products/offices/all/view/", {
      params: {
        productType: productType,
        officeName: officeName,
      },
    }),
  add: ({ title, description, image, productType, price, minute }) =>
    apiClient.put("/products/admins/create/", {
      title,
      description,
      image,
      productType,
      price,
      minute,
    }),
  update: ({
    productId,
    title,
    description,
    image,
    productType,
    price,
    minute,
  }) =>
    apiClient.post("/products/admins/edit/", {
      productId,
      title,
      description,
      image,
      productType,
      price,
      minute,
    }),
  delete: (productId) =>
    apiClient.delete("/products/admins/delete/", { data: { productId } }),
  mail:{
    getAll: () => apiClient.get('/products/mails/view/')
  }
};
