import { Icon } from "@iconify/react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fireSuccessAlert } from "./../../components/utils/Swal";
import { userClient } from "./../../network/lib/user";
import { uploadClient } from "../../network/lib/upload";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import { AudioRecorder } from "react-audio-voice-recorder";

function ProfileExpert() {
  // const { officeName } = useParams();
  const officeState = useSelector((state) => state.office);
  const officeName = officeState.data.officeName;

  const [expertName, setExpertName] = useState("");
  const [imageBanner, setImageBanner] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);
  const [tags, setTags] = useState([]);
  const [description, setDescription] = useState("");
  const [phone, setPhone] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [email, setEmail] = useState("");
  const [userData, userDataSet] = useState([]);

  const spcialityOptions = [
    { value: "TAROLOGUES", name: "Tarologues" },
    { value: "MEDIUMS", name: "Médiums" },
    { value: "VOYANTS", name: "Voyants" },
    { value: "ASTROLOGUES", name: "Astrologues" },
    { value: "RADIESTHESISTES", name: "Radiesthésistes" },
    { value: "NUMEROLOGUES", name: "Numérologues" },
    { value: "CARTOMANCIENS", name: "Cartomanciens" },
  ];

  const onImageBannerChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      uploadClient.profileBannerUpload({file})
        .then((res) => {
          fireSuccessAlert("Modification éffectuer !").then(() => fetchProfile());
        });

      reader.onloadend = () => {
        setImageBanner(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onImageProfileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      
      uploadClient.profileImageUpload({file})
        .then((res) => {
          fireSuccessAlert("Modification éffectuer !").then(() => fetchProfile());
        });

      const reader = new FileReader();

      reader.onloadend = () => {
        setImageProfile(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (officeName) {
      // console.log(officeName);
      fetchProfile();
    }
  }, [officeName]);

  const addAudioElement = (blob) => {

    const options = { type: "video/webm" };
    const file = new File([blob], "audio.webm", options);

    uploadClient.profileVoiceUpload({file}).then((res) => {

      fireSuccessAlert("Modification éffectuer !");

    });
  };

  function fetchProfile() {
    userClient.expert.profile.get(officeName).then((res) => {
      // console.log(res.data);
      const {
        name,
        imageBanner,
        email,
        description,
        phone,
        tags,
        speciality,
        imageProfile,
      } = res.data;

      setExpertName(name);
      setImageBanner(imageBanner);
      setImageProfile(imageProfile);
      setTags(tags);
      setDescription(description);
      setEmail(email);
      setPhone(phone);
      setSpeciality(speciality);
    });
  }

  function updateExpert() {
    const formData = {
      description,
      phone,
      tags,
      speciality,
    };

    userClient.expert.profile.update(formData).then((res) => {
      fireSuccessAlert("Modification éffectuer !").then(() => fetchProfile());
    });
  }

  return (
    <div id="profileExpert">
      <div
        className="grey-part"
        style={{ background: imageBanner ? `url(${imageBanner})` : "" }}
      >
        <label className="top-bar" htmlFor="modifyBack">
          <h2></h2>
          <span>Modifier la bannière</span>
        </label>
        <input
          type="file"
          onChange={onImageBannerChange}
          accept="image/jpeg, image/png, image/jpg"
          name="modifyBack"
          id="modifyBack"
        />
        <input
          type="file"
          onChange={onImageProfileChange}
          accept="image/jpeg, image/png, image/jpg"
          name="form"
          id="form"
        />
        <label htmlFor="form" className="drag-drop">
          {imageProfile ? (
            <img alt={"Profile Image"} className="image" src={imageProfile} />
          ) : (
            <Icon icon="material-symbols:photo-library-rounded" />
          )}
          <span>Ajouter une photo</span>
        </label>
      </div>
      <div className="container">
        <span className="name">{expertName}</span>
        <textarea
          defaultValue={description}
          // defaultValue={userData["description"]}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />

        <form>
          <div className="form-group">
            <label>Specialites</label>
            <Dropdown
              value={speciality}
              onChange={(e) => setSpeciality(e.value)}
              options={spcialityOptions}
              optionLabel="name"
              placeholder="Select Speciality"
              className="w-full md:w-14rem"
            />
          </div>
          <div className="form-group">
            <label>E-mail</label>
            <input value={email} type="email" disabled />
          </div>
          <div className="form-group">
            <label>Categories</label>
            <MultiSelect
              value={tags}
              onChange={(e) => setTags(e.value)}
              options={[
                { value: "TAROLOGUES", label: "TAROLOGUES" },
                { value: "MEDIUMS", label: "MEDIUMS" },
                { value: "VOYANTS", label: "VOYANTS" },
                { value: "ASTROLOGUES", label: "ASTROLOGUES" },
                { value: "RADIESTHESISTES", label: "RADIESTHESISTES" },
                { value: "NUMEROLOGUES", label: "NUMEROLOGUES" },
                { value: "CARTOMANCIENS", label: "CARTOMANCIENS" },
              ]}
              optionLabel="label"
              display="chip"
              placeholder="Select Category"
              maxSelectedLabels={4}
              className="w-full md:w-20rem"
            />
            {/* <div className="input-tags">
              {tags &&
                Object.keys(tags).map((tag) => (
                  <div
                    className="tag"
                    key={tag}
                    onClick={() => handleDeleteTag(parseInt(tag))}
                  >
                    {tags[tag]}
                  </div>
                ))}
              <CustomSelect
                options={[
                  { value: "TAROLOGUES", label: "TAROLOGUES" },
                  { value: "MEDIUMS", label: "MEDIUMS" },
                  { value: "VOYANTS", label: "VOYANTS" },
                  { value: "ASTROLOGUES", label: "ASTROLOGUES" },
                  { value: "RADIESTHESISTES", label: "RADIESTHESISTES" },
                  { value: "NUMEROLOGUES", label: "NUMEROLOGUES" },
                  { value: "CARTOMANCIENS", label: "CARTOMANCIENS" },
                ]}
                onChange={handleChangeTag}
              />
            </div> */}
          </div>
          <div className="form-group">
            <label>Téléphone</label>
            <PhoneInput
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
              country="FR"
            />
          </div>
          <div className="form-group">
            <AudioRecorder 
              onRecordingComplete={addAudioElement}
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }} 
              downloadOnSavePress={false}
            />
          </div>
        </form>
        <button onClick={() => updateExpert()}>Enregistré</button>
      </div>
    </div>
  );
}

export default ProfileExpert;
