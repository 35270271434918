import { useContext, useEffect, useState } from "react";
import {
  getMonthIndexFromName,
  getNextMonth,
  getPreviousMonth,
} from "../calendar/calendar";
import { CalendarContext } from "../context/CalendarContext";
import Week from "./Week";
import { datesWithoutHourAreEqual } from "../util/util";

const Month = () => {
  const { displayMonth, setDisplayMonth, displayWeek, setDisplayWeek } =
    useContext(CalendarContext);

  const [firstDateOfMonth, setFirstDateOfMonth] = useState(null);
  const [lastDateOfMonth, setLastDateOfMonth] = useState(null);

  const handlePreviousWeekClick = (e) => {
    e.preventDefault();
    const displayWeekIndex = displayWeek[0].index.week;
    let previousDisplayWeek = displayMonth.calendar[displayWeekIndex - 1];

    if (!previousDisplayWeek) {
      setFirstDateOfMonth(displayWeek[0].date);
      const previousMonth = getPreviousMonth(
        parseInt(displayMonth.year),
        getMonthIndexFromName(displayMonth.month)
      );

      setDisplayMonth(previousMonth);
    } else {
      setDisplayWeek(previousDisplayWeek);
    }
  };

  const handleNextWeekClick = (e) => {
    e.preventDefault();
    const displayWeekIndex = displayWeek[0].index.week;

    let nextDisplayWeek = displayMonth.calendar[displayWeekIndex + 1];

    if (!nextDisplayWeek) {
      setLastDateOfMonth(displayWeek[displayWeek.length - 1].date);
      const nextMonth = getNextMonth(
        parseInt(displayMonth.year),
        getMonthIndexFromName(displayMonth.month)
      );
      setDisplayMonth(nextMonth);
    } else {
      setDisplayWeek(nextDisplayWeek);
    }
  };

  useEffect(() => {
    if (firstDateOfMonth) {
      let updatedDisplayWeekIndex = displayMonth.calendar.findIndex((week) =>
        week.some((day) => {
          console.log(day);
          firstDateOfMonth.setHours(0, 0, 0, 0);
          day.date.setHours(0, 0, 0, 0);
          return day.date.getTime() === firstDateOfMonth.getTime();
        })
      );
      setDisplayWeek(displayMonth.calendar[updatedDisplayWeekIndex - 1]);
      setFirstDateOfMonth(null);
    } else if (lastDateOfMonth) {
      let updatedDisplayWeekIndex = displayMonth.calendar.findIndex((week) =>
        week.some((day) => {
          lastDateOfMonth.setHours(0, 0, 0, 0);
          day.date.setHours(0, 0, 0, 0);
          return day.date.getTime() === lastDateOfMonth.getTime();
        })
      );

      setDisplayWeek(displayMonth.calendar[updatedDisplayWeekIndex + 1]);
      setLastDateOfMonth(null);
    }
  }, [displayMonth]);
  return (
    <>
      <Week
        weekData={displayWeek}
        handlePreviousWeekClick={handlePreviousWeekClick}
        handleNextWeekClick={handleNextWeekClick}
      />
    </>
  );
};

export default Month;
