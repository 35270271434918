import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import TableSkeleton from "../skeletons/expert/tableSkeleton";
import InfoMessageBoxTD from "../react-tables/utils/infoMessageBox";
import { callClient } from "../../network/lib/call";
import { fireSuccessAlert } from "../utils/Swal";

function ConsultationMeetBox(props) {
  const dataConsultation = props.data || [];

  return (
    <div className="consultation-box">
      <h4 className="title">{props.title}</h4>
      <div className="tableContainer">
        {props.isLoading ? (
          <TableSkeleton columnCount={5} rowCount={2} />
        ) : (
          <Table>
            <Thead>
              <Tr>
                {props.titles.map((elem, i) => {
                  return <Th key={i}>{elem}</Th>;
                })}
              </Tr>
            </Thead>
            <Tbody>
              <CellRow dataConsultation={dataConsultation} cols={props.titles.length}/>
            </Tbody>
          </Table>
        )}
      </div>
    </div>
  );
}

const callAppointment = (appointmentId) => {
  callClient.expert.call({appointmentId}).then((res) => {
    fireSuccessAlert("Appel en cours. Merci de répondre (vérifiez bien votre numéro)");
  });
};

function CellRow(props) {
  const calls = props.dataConsultation;

  if(calls.length === 0) return <InfoMessageBoxTD colSpan={props.cols}/>
  
  return (
    <>
    
      {calls.map((call) => (
        <>
          <Tr key={call["id"]}>
            <Td>{call["idShort"]}</Td>
            <Td>{call["date"]}</Td>
            <Td>{call["label"]}</Td>
            <Td>{call["clientName"]}</Td>
            <Td>{call["status"]}</Td>
            <Td><button onClick={()=>callAppointment(call["id"])}>Appeler</button></Td>
          </Tr>
        </>
      ))}
    </>
  );
}

export default ConsultationMeetBox;
