import { Icon } from "@iconify/react";

function NavigateBar({ table }) {
  return (
    <div className="control-navigate">

      <p>
        Page <span className="text-bold">{table.getState().pagination.pageIndex + 1}</span> of{" "}
        <span className="text-bold">{table.getPageCount()}</span>
      </p>

      <button
        className="text-button"
        onClick={() => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
      >
        <Icon icon="streamline:entertainment-control-button-previous-button-television-buttons-movies-skip-previous-video-controls" />
      </button>

      <button
        className="text-button"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <Icon icon="material-symbols:arrow-back-ios-new-rounded" />
      </button>
      <button
        className="text-button"
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <Icon icon="material-symbols:arrow-forward-ios-rounded" />
      </button>
      <button
        className="text-button"
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
      >
        <Icon icon="streamline:entertainment-control-button-next-button-television-buttons-movies-skip-next-video-controls" />
      </button>
    </div>
  );
}

export default NavigateBar;
