import { GrNext, GrPrevious } from "react-icons/gr";

import { useContext, useEffect } from "react";
import "./App.scss";
import {
  getMonthIndexFromName,
  getNextMonth,
  getPreviousMonth
} from "./calendar/calendar";
import Month from "./components/Month";
import { CalendarContext } from "./context/CalendarContext";

function SchedulingApp({expertId, productId, dates}) {
  const { fetchAllAvailableSlots, displayMonth, setDisplayMonth, setDisplayWeek, displayWeek } =
    useContext(CalendarContext);

  useEffect(() => {
    fetchAllAvailableSlots({expertId, productId, dates});
  }, [displayMonth,productId]);

  return (
    <div className="fixed-height-container">
      <div className="scheduler">
        <div className="scheduler__body">
          <Month month={displayMonth} />
        </div>
      </div>
    </div>
  );
}

export default SchedulingApp;
