import { useContext, useEffect, useMemo, useState } from "react";
import Slot from "./Slot";
import { getDayName, getMonthName } from "../calendar/calendar";
import { CalendarContext } from "../context/CalendarContext";

const Day = ({ dayData, availableSlots }) => {
  const { bookings } = useContext(CalendarContext);

  console.log(dayData);

  if (!dayData) return null;

  return (
    <div className="scheduler__day">
      <div className="scheduler__day-title">
        <div className="scheduler__day-title--day-name">
          {getDayOfWeek(dayData.date)}
        </div>
        <div className="scheduler__day-title--date">
          {extractDayFromDate(dayData.date)} {getMonthName(dayData.date.getMonth())}
        </div>
      </div>
      {availableSlots?.map((slot, index) => {
        return <Slot key={index} slotData={slot} dayData={dayData} />;
      })}
    </div>
  );
};

const getDayOfWeek = (date) => {
  const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
};

function extractDayFromDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0'); // Format the day to two digits
  return day;
}

export default Day;
