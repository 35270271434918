import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import Modal from "../../components/modal/modal";
import { useState } from "react";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css';
import { userClient } from "../../network/lib/user";
import { fireSuccessAlert } from "../../components/utils/Swal";
import { useEffect } from "react";

function ProfileClient() {

  const user = useSelector((state) => state.user);

  const [showModal, setShowModal] = useState(false);
  const [phone, setPhone] = useState(null);

  // const { officeName } = useParams();

  // const [userData, userDataSet] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);

  
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log(user)
    // const config = {
    //   headers: {
    //     sessionId: localStorage.getItem("sessionId"),
    //   },
    // };

    // dispatch(fetchUser(officeName, config));
    // axios
    //   .get(
    //     "https://api.vooxhub.com/users/view/?officeName=" + officeName,
    //     config
    //   )
    //   .then((resp) => {
    //     userDataSet(resp.data);
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     //window.location.href = "https://vooxhub.com";
    //     console.log(error)
    //   });
  // }, [user]);
  console.log(user)

  const handleNewAdminFormSubmit = (e) => {
    e.preventDefault();
    userClient.client.phone.set({phone}).then((res) => {

      setShowModal(false);

      fireSuccessAlert("Le numéro de téléphone a été édité.");

    });
  };
  
  useEffect(() => {
    setPhone(user.data.phone);
  }, []);

  return (
    <>
    <Modal
        title="Entrer votre numéro"
        show={showModal}
        onClose={() => setShowModal(false)}
        width="40%"
      >
        <form
          className="experts-modal-form flex-column gap-1"
          onSubmit={handleNewAdminFormSubmit}
        >
          <label>Téléphone</label>
          <PhoneInput
              placeholder="Téléphone"
              value={phone}
              onChange={setPhone}
              country="FR"
            />
          <button type="submit" className="gray-button">
            Soumettre
          </button>
        </form>
      </Modal>
    <div id="profileClient">
      <h2>Mon Profil</h2>

      <div className="box">
        {user.loading ? (
          <>
            <Skeleton width="40%" />
            <Skeleton width="40%" />
            <Skeleton width="40%" />
          </>
        ) : (
          <>
            <div className="ligne">
              <span>Nom complet : </span>
              <span>{user.data.name}</span>
            </div>
            <div className="ligne">
              <span>E-mail : </span>
              <span>{user.data.email}</span>
            </div>
            <div className="ligne">
              <span>Téléphone : </span>
              <span>+{phone ? phone : user.data.phone}</span>
            </div>
          </>
        )}
      </div>

      <div className="btn-group">
        <button onClick={() => setShowModal(true)}>Modifier mon numéro</button>
      </div>
    </div>
    </>
  );
}

export default ProfileClient;
