import { Icon } from "@iconify/react";
import NavLink from "../../components/path/profile/navLink";
import ProfileBar from "../../components/path/profile/profileBar";
import { useParams } from "react-router-dom";
import { Fragment, useState } from "react";
import { useEffect } from "react";
import { profileClient } from "../../network/lib/profile";

function StarRating(props) {
    const { advice } = props;
  
    const renderStars = () => {
      const stars = [];
      for (let i = 0; i < advice; i++) {
        stars.push(<Icon key={i} icon="material-symbols:star" />);
      }
      return stars;
    };
  
    return <>{renderStars()}</>;
  }
  
  function ListWithDynamicContent({ expertId,list, officeName }) {
    // Check if the 'list' prop exists and is an array
    if (!list || !Array.isArray(list)) {
      return null; // or any other fallback content if desired
    }
    return (
      <div>
        {list.map((item, index) => {
          let divContent = null;
  
          if (item.includes("CHAT")) {
            divContent = (
              <div className="bar green" key={index} >
                <Icon icon="material-symbols:chat" height={24} width={24} />
                <span>Discuter en ligne</span>
              </div>
            );
          } else if (item.includes("MAIL")) {
            divContent = (
              <div
                className="bar green"
                key={index}
                onClick={() =>
                  window.open("/" + officeName + "/client/payment-email/" + expertId , "_blank")
                }
              >
                <Icon icon="material-symbols:mail" height={24} width={24} />
                <span>Consultation par mail</span>
              </div>
            );
          } else if (item.includes("MEET")) {
            divContent = (
              <div
                className="bar green"
                key={index}
                onClick={() =>
                  window.open("/" + officeName + "/client/payment-rdv/"+ expertId, "_blank")
                }
              >
                <Icon icon="fa6-solid:calendar-days" height={24} width={24} />
                <span>Planifier un rendez-vous</span>
              </div>
            );
          } else if (item.includes("CALL")) {
            divContent = (
              <div className="bar green" key={index} onClick={() =>
                window.open("/" + officeName + "/client/expertList/" + expertId + "/call", "_blank")
              }>
                <Icon icon="ic:outline-access-time-filled" height={24} width={24} />
                <span>Démarrer un appel</span>
              </div>
            );
          }
  
          return divContent;
        })}
      </div>
    );
  }

function ProfilePage() {

    const { officeName, expertId } = useParams();

    const [expertData, expertDataSet] = useState([]);

    const redirectPayment = () => {
        window.open("/" + officeName + "/client/payement", '_blank');
    };

    useEffect(() => {
        profileClient.expert.getPublicProfile(expertId).then(res =>{ 
            console.log(res.data)
            expertDataSet(res.data)})
    },[])


    return (
        <div id="profilePath">

            <div className="container">
                <ProfileBar name={expertData["name"]}
                    advice={expertData["advice"]}
                    scores={expertData["scores"]}
                    callCount={expertData["callCount"]}
                    imageProfile={expertData["imageProfile"]} />

                <NavLink selected={0} />
                <div className="col2-1">
                    <div className="box">
                        <p>{expertData["description"]}</p>
                    </div>
                    <div className="box">
                        <span className="title">Lancer une consultation</span>
                        <ListWithDynamicContent
                            expertId={expertId}
                            officeName={officeName}
                            list={expertData["benefits"]}
                        />
                    </div>
                </div>
                <NavLink selected={1} />
                <div className="col2-2">
                    <div className="box">
                        {
                            expertData["plans"] ? expertData["plans"].map((plan, index) => {
                                return (
                                    <div key={index} className="block">
                                        <span className="top-bar">{plan["name"]}</span>
                                        <div className="list">
                                            <Icon icon="ic:sharp-access-time" />
                                            <span>{plan["minutes"]} Minutes<br/>pour {plan["prices"]} €</span>
                                        </div>
                                        <span className="footer-bar" onClick={redirectPayment}>Choisir du forfait</span>
                                    </div>
                                )
                            }) : ""
                        }
                    </div>
                    <div className="box">
                        <h3>Disponiblites</h3>
                        {
                            expertData["plannings"] ? expertData["plannings"].map((planning, index) => {
                                return (
                                    <div key={index} className="ligne">
                                        <div>
                                            <span>{planning["name"]}</span>
                                            <span>({planning["date"]})</span>
                                        </div>
                                        <div>
                                            {
                                                planning["schedules"].map((schedule, index) => {
                                                    return (
                                                        <span key={index}>{schedule}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }) : ""
                        }
                    </div>
                </div>
                <NavLink selected={2} />
                <div className="col2-3">
                    <div className="box">
                        <span className="title">Commentaires</span> <br></br>
                        {
                            expertData["comments"] ? expertData["comments"].map((comment, index) => {
                                return (
                                    <Fragment key={index}>
                                      <div className="name">{comment["clientName"]}</div>
                                        <div className="stars-box">
                                            <div className="stars">
                                                <StarRating advice={comment["scores"]} />
                                            </div>
                                            <span>{comment["date"]}</span>
                                        </div>
                                        {
                                            comment["clientMessage"] ? <p>{comment["clientMessage"]}</p> : ""
                                        }
                                        {
                                            comment["expertMessage"] ? 
                                                <div className="reponsse">
                                                    <span className="title_res">Réponse de {comment["expertName"]} :</span>
                                                    <p>{comment["expertMessage"]}</p>
                                                </div> : ""
                                        }
                                    </Fragment>
                                )
                            }) : ""
                        }
                    </div>
                </div>
            </div>
            <footer>
              A service provided by <a href="https://vooxhub.com" target="_blank">VooxHub</a> - 2023 © All rights reserved <br/>
              Developed by <a href="https://shield4Cloud.com" target="_blank">Shield4Cloud</a>
            </footer>
        </div>
    );
}

export default ProfilePage;