import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userClient } from './../../../network/lib/user';

const initialState = {
  loading: true,
  data: {},
  error: "",
};

const getEndpointByRole = (role) => {
  switch (role) {
    case "admin":
      return "https://api.vooxhub.com/users/admins/view/?officeName=";

    case "expert":
      return "https://api.vooxhub.com/users/experts/view/?officeName=";

    case "client":
      return "https://api.vooxhub.com/users/view/?officeName=";

    default:
      return "https://api.vooxhub.com/users/view/?officeName=";
  }
};

const fetchUser = createAsyncThunk("user/fetchUser", ({ officeName, role }) => {

  // console.log(officeName, role)
  return userClient.get(getEndpointByRole(role) + officeName)
    .then((res) => {
      return res.data
    })
    // .catch(err => console.log(err))
});

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    // Handling fetchUser Promise cases - pending, fulfilled, rejected
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = "";
    });

    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.data = {};
    });
  },
});

export { fetchUser };
export default userSlice.reducer;
