import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import ConfigPackageTable from "../../../components/react-tables/configPackageTable/ConfigPackageTable";

function ConfigurationPackagePage() {
  return (
    <div id="configurationPackage">
      <div className="top-bar">
        <h2>Forfaits</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Forfaits
          </Link>
        </div>
      </div>

      <ConfigPackageTable />
    </div>
  );
}

export default ConfigurationPackagePage;
