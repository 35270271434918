import { Link } from "react-router-dom";

import { IoIosArrowForward } from "react-icons/io";
import ConsultationDropDown from "../../components/consultation/consultationDropDown";

import axios from "axios";
import { useEffect, useState } from "react";
import { format } from 'date-fns';
import { userClient } from './../../network/lib/user';

function ConsultationPage() {
  const [callsData, callsDataSet] = useState([]);
  const [expertsData, expertsDataSet] = useState([]);

  useEffect(() => {
    
    userClient.admin.consultation.getAll(format(new Date(), 'dd/MM/yyyy'))
      .then((resp) => {
        callsDataSet(resp.data.calls);
        expertsDataSet(resp.data.expertsOnline);
      })
      // .catch((error) => {
      //   //window.location.href = "https://vooxhub.com";
      // });
  }, []);

  return (
    <div id="consultation">
      <div className="top-bar">
        <h2>Consultations en cours</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Consultations en cours
          </Link>
        </div>
      </div>
      <div className="grid">
        <ConsultationDropDown
          data={callsData}
          title="Experts en consultation"
          active={true}
        />
        <ConsultationDropDown 
          data={expertsData}
          title="Experts disponible"
          active={false}
        />
      </div>
    </div>
  );
}

export default ConsultationPage;
