import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import PromoCodeTable from "../../../components/react-tables/promocodeTable/promoCodeTable";

function PromoCodePage() {
  return (
    <div id="promoCode">
      <div className="top-bar">
        <h2>Promotions</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Gestion
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Promotions
          </Link>
        </div>
      </div>

      <PromoCodeTable />
    </div>
  );
}

export default PromoCodePage;
