import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdInfo, MdModeEdit } from "react-icons/md";
import { fireErrorAlert } from "../utils/Swal";

function PaymentEmail({ expertId, setPaymentData, setImageFile, imageFile }) {
  const [subject, setSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");


  const handleImageUpload = (e) => {

    const futureImage = e.target.files[0];

    if(futureImage && futureImage.size < 2097152) {

      setImageFile(futureImage);

    } else {

      fireErrorAlert("Cette image fait plus de 2 Mo ou est vide");

    }
    
  };

  const handleFormSubmit = (e) => {
    // console.log(e);
    e.preventDefault();
    setPaymentData({
      expertId,
      message: emailBody,
      subject: subject,
    });
  };

  return (
    <div>
      <h2 className="mb-2">Information complementaires</h2>
      <form className="payment-email-form" onSubmit={handleFormSubmit}>
        <div>
          <h4 className="mb-1 text-success text-bold">
            Informations importantes:
          </h4>
          <ul className="mb-1 pl-2 text-sm">
            <li>Détaillez votre question, ajoutez des photos si nécessaire</li>
            <li>N'hésitez pas à mentionner des informations clés</li>
            <li>
              Indiquez <span className="text-bold">impérativement</span> le
              véritable
              <span className="text-bold"> PRENOM</span> et la
              <span className="text-bold"> DATE DE NAISSANCE</span> de toutes
              les personnes concernées
            </li>
            <li>N'oubliez pas de cliquer sur <span className="text-bold">le bouton validé</span> ci-dessous.</li>
          </ul>
        </div>
        <div className="flex-column">
          <div className="form-group">
            <label>Sujet</label>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <MdInfo style={{ fontSize: "1.5rem" }} />
              </span>
              <InputText
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <label>Corps</label>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <MdModeEdit style={{ fontSize: "1.5rem" }} />
              </span>
              <InputTextarea
                placeholder="Votre message doit contenir au minimum 20 caracteres et au maximum 1000 caracteres"
                rows={10}
                value={emailBody}
                onChange={(e) => setEmailBody(e.target.value)}
              />
            </div>
            <p
              className={`text-right ${
                emailBody.length <= 1000 ? "text-success" : "text-danger"
              }`}
            >
              <small>{emailBody.length}/1000</small>
            </p>
          </div>

          <div className="form-group">
            <label>Photo</label>
            <div className="p-inputgroup flex-1">
              <span className="p-inputgroup-addon">
                <FaUser style={{ fontSize: "1rem" }} />
              </span>
              <InputText
                value={imageFile ? imageFile.name : ""}
                disabled
              />

              <span className="p-inputgroup-addon relative">
                <input
                  type="file"
                  className="hidden-file-upload-input"
                  onChange={(e) => handleImageUpload(e)}
                />
                Browse
              </span>
            </div>
          </div>
          <button type="submit" className="primary">
            Valider
          </button>
        </div>
      </form>
    </div>
  );
}

export default PaymentEmail;
