import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaymentEmail from "../../components/client/paymentEmail";
import PaymentConfirm from "../../components/payment/PaymentConfirm";
import AdviceBoxSkeleton from "../../components/skeletons/expert/adviceBoxSkeleton";
import CustomSelectId from "../../components/utils/CustomSelectId";
import { fireErrorAlert, fireSuccessAlert } from "../../components/utils/Swal";
import { paymentClient } from "../../network/lib/payment";
import { productClient } from "../../network/lib/product";
import { profileClient } from "../../network/lib/profile";
import { promotionClient } from "../../network/lib/promotion";
import NewCard from "../../components/payment/card/NewCard";
import { officeClient } from "../../network/lib/office";
import PaymentConfirmS4C from "../../components/payment/PaymentConfirmS4C";

function PaymentEmailPage() {
  const { officeName } = useParams();
  const { expertId } = useParams();
  const [expertData, setExpertData] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [mailPrice, setMailPrice] = useState([]);
  const [codePromo, setCodePromo] = useState("");
  const [cardList, setCardList] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [cardNewData, cardNewDataSet] = useState();
  const [paymentData, setPaymentData] = useState({});
  const [promotionalData, setPromotionalData] = useState([]);
  const [paymentProviderData, paymentProviderDataSet] = useState([]);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  function codePromoCheck() {
    const promotional = {
      codeName: codePromo,
    };

    promotionClient.mail
      .checkPromoCode(promotional)
      .then((resp) => {
        setPromotionalData(resp.data);
        fireSuccessAlert("La réduction à été appliqué");
      })
      .catch((error) => {
        if (error.response) {
          fireErrorAlert("Code invalide");
        }
      });
  }

  useEffect(() => {
    profileClient.expert.getPublicProfile(expertId).then((resp) => {
      setExpertData(resp.data);
      setIsLoading(false);
    });

    productClient.mail.getAll().then((res) => {
      console.log(res.data);
      setMailPrice(res.data.price);
    });

    paymentClient.card.getAll().then((resp) => {
      setCardList(resp.data.cards);
    });
    officeClient.getOfficePaymentProvider(officeName).then((resp) => {
      paymentProviderDataSet(resp.data.shield4pay);
    });
    // .catch((error) => {});
  }, []);

  console.log(paymentData);

  if (isLoading) {
    return <AdviceBoxSkeleton count={6} />;
  }

  return (
    <div id="payementClient">
      <h2 className="mb-2">Acheter un E-mail</h2>
      <div className="mb-2">
        <div className="profile">
          <div
            className="picture"
            style={{
              backgroundImage: `url("` + expertData.imageProfile + `")`,
            }}
          ></div>
          <h3 className="profile-name">{expertData.name}</h3>
        </div>
      </div>
      {/* <div className="form-control">
        <label>Je choisis mon forfait privé</label> */}
      <PaymentEmail
        setPaymentData={setPaymentData}
        expertId={expertId}
        setImageFile={setImageFile}
        imageFile={imageFile}
      />
      {/* </div> */}

      <hr style={{ marginTop: "27px" }} />

      <div className="form-control">
        <label>Code Promo</label>
        <div className="input-control">
          <div className="icon">
            <Icon icon="ic:outline-label" color="#000000" />
          </div>
          <input
            value={codePromo}
            onChange={(e) => setCodePromo(e.target.value)}
            className="no-anime input-field"
          />
          <button onClick={() => codePromoCheck()}>OK</button>
        </div>
      </div>

      <hr style={{ marginTop: "40px" }} />

      <div className="split-line">
        <span>Total</span>
        <span>{mailPrice}€ TTC</span>
      </div>

      {/*<div className="form-control">
        <label>Mes moyens de payement</label>
        <CustomSelectId
          value="Sélectionnez votre carte"
          onChange={(e) => setCardData(cardList[e])}
          options={cardList}
        />
      </div>*/}

      {/* <PaymentConfirm paymentUrl={"payments/mails/create"}
paymentData={{ message: “le message”, image: “en base64”, subject: “le sujet”, expertId: “l’id de l’expert”}}
paymentMethodId={cardData["cardId"]} codeName={promotionalData["codeName"]}
/> */}

      {!paymentProviderData ? (
        !cardData["cardId"] ? (
          <NewCard cardNewSet={cardNewDataSet} />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {paymentProviderData ? (
        <PaymentConfirmS4C
          paymentUrl={"payments/shield4pay/mails/create"}
          paymentData={paymentData}
          codeName={promotionalData["codeName"]}
          imageFile={imageFile}
        />
      ) : cardData["cardId"] || cardNewData ? (
        <PaymentConfirm
          paymentUrl={"payments/easytransac/mails/create"}
          paymentData={paymentData}
          paymentCardId={cardData["cardId"]}
          paymentCardNew={cardNewData}
          codeName={promotionalData["codeName"]}
          imageFile={imageFile}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default PaymentEmailPage;
