import React from "react";
import { useState } from "react";
import "./singleSelectButton.scss";

function SingleSelectButton({ expertData, expertIdDataSet }) {
  const [active, setActive] = useState("");
  
  return (
    <div className="single-select-button-group flex-row gap-1">
      {expertData.map(({ color, expertId, expertName }, index) => (
        <button
          key={index}
          onClick={() => {
            setActive(expertName);
            expertIdDataSet(expertId)
          }}
          className={`${active === expertName ? "active" : ""}`}
          style={{backgroundColor: color}}
        >
          {expertName}
        </button>
      ))}
    </div>
  );
}

export default SingleSelectButton;
