import { Icon } from "@iconify/react";
import { useSelector } from 'react-redux';
import LogoutHandler from "../../utils/logout";

function BatMenuClient(props) {

  const { navigation } = props;

  const userState = useSelector(state => state.user);

  return (
    <div id="bat-menu" className={`${props.showBatMenu ? "show" : ""}`}>
      <div>
        <span className="badge purple">
          Solde
          <br />
          {userState?.data.time}
        </span>
        <span className="title">Client</span>
        <ul className="menu">
          <li onClick={() => navigation("/" + props.officeName + "/client")}>
            <Icon icon="mdi:account" />
            <span>Mon profil</span>
          </li>
          <li onClick={() => navigation("/" + props.officeName + "/client/expertList")}>
            <Icon icon="simple-icons:googlecolab" />
            <span>Je consulte</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/client/myconsultations")
            }
          >
            <Icon icon="ic:baseline-message" />
            <span>Mes consultations</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/client/mypurchase")
            }
          >
            <Icon icon="solar:cart-5-line-duotone" />
            <span>Mes achats</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/client/payement")
            }
          >
            <Icon icon="material-symbols:euro" />
            <span>Créditer mon compte</span>
          </li>
          <li
            onClick={() => navigation("/" + props.officeName + "/client/mails")}
          >
            <Icon icon="material-symbols:mail-outline-rounded" />
            <span>Mes Mails</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/client/mymeeting")
            }
          >
            <Icon icon="ic:baseline-message" />
            <span>Mes rendez-vous</span>
          </li>
          <li
            onClick={() =>
              navigation("/" + props.officeName + "/client/mymethodspayement")
            }
          >
            <Icon icon="ic:baseline-payment" />
            <span>Mes moyens de paiement</span>
          </li>
        </ul>
      </div>

      <div>
        <span className="title">AUTRE</span>
        <ul className="menu">
          <LogoutHandler officeName={props.officeName} />
        </ul>
      </div>
    </div>
  );
}

export default BatMenuClient;
