import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DropdownButton from "../../dropdownButton/DropdownButton";
import { fireSuccessAlert } from "../../utils/Swal";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import { promotionClient } from "./../../../network/lib/promotion";
import FilterBar from "./filterBar";

function PromoCodeTable() {
  const navigate = useNavigate();
  const officeName = useSelector((state) => state.office.data.officeName);
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const actionMenu = useRef(null);

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  const fetchData = () => {
    promotionClient.getAll().then((res) => {
      console.log(res.data.promotionals);
      setData(res.data.promotionals);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    // setData(createFakeData("promo-code"))
    fetchData();
  }, []);

  //   {
  //     "id": "e2d7da74-bbe7-47aa-a1f5-907a5d25261f",
  //     "codeName": "test",
  //     "promotionalType": "PRICE",
  //     "promotionalEvent": "ONCE",
  //     "value": 1,
  //     "dayLimit": 10,
  //     "date": "13/07/2023",
  //     "used": 0
  // },

  const handleEdit = (data) => {
    navigate(`/${officeName}/admin/promoCode/edit`, {
      state: { data },
    });
  };

  const handleDelete = (id) => {
    promotionClient.delete(id).then((res) => {
      fireSuccessAlert("Promotion deleted");
      setData((prevState) =>
        prevState.filter((promotion) => promotion.id !== id)
      );
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Nom du code prom",
        accessorKey: "codeName",
        cell: (info) => info.getValue(),
      },

      // {
      //   header: "État",
      //   accessorKey: "state",
      //   cell: (info) => <span className="badge">{info.getValue()}</span>,
      // },

      {
        header: "Utilisation",
        accessorKey: "used",
        cell: (info) => info.getValue(),
      },
      {
        header: "Type",
        accessorKey: "promotionalType",
        cell: (info) => info.getValue(),
      },

      // {
      //   header: "Mode de consultation",
      //   accessorKey: "consultationMode",
      //   cell: (info) => info.getValue()
      // },
      {
        header: "Crée le",
        accessorKey: "date",
        cell: (info) => info.getValue(),
      },
      // {
      //   header: "Date de début de validité",
      //   accessorKey: "validityStartDate",
      //   cell: (info) => {
      //     const date = info.getValue();
      //     return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
      //   },
      // },
      // {
      //   header: "Date de fin de validité",
      //   accessorKey: "validityEndDate",
      //   cell: (info) => {
      //     const date = info.getValue();
      //     return `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;
      //   },
      // },
      {
        header: "Valeur / Prix",
        accessorKey: "value",
        cell: (info) => info.getValue(),
      },
      // {
      //   header: "Nb Minutes",
      //   accessorKey: "minutes",
      //   cell: (info) => info.getValue(),
      // },
      // {
      //   header: "Nombre d'utilisations",
      //   accessorKey: "numOfUses",
      //   cell: (info) => info.getValue(),
      // },
      {
        header: "Action",
        accessorFn: (row) => row,
        cell: ({ cell }) => {
          const row = cell.getValue();
          const items = [
            {
              label: "Modifier",
              command: (e) => {
                // console.log(e)
                handleEdit(row);
              },
            },
            {
              label: "Supprimer",
              command: () => {
                handleDelete(row.id);
              },
            },
          ];
          return <DropdownButton items={items} />;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },

    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  const searchableProps = { globalFilter, setGlobalFilter, table, isLoading };
  const filterProps = { globalFilter, setGlobalFilter, table };

  return (
    <>
      <FilterBar {...filterProps} />
      {isTabletOrMobile ? (
        <SearchableTableMD {...searchableProps} />
      ) : (
        <SearchableTableXL {...searchableProps} />
      )}
    </>
  );
}

export default PromoCodeTable;
