import { useMediaQuery } from "@mui/material";
import {
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";
import SearchableTableMD from "../shared/SearchableTableMD";
import SearchableTableXL from "../shared/SearchableTableXL";
import { fuzzyFilter } from "../utils";
import { createFakeData } from "../utils/createFakeData";
import FilterBar from "./filterBar";
import { statsClient } from './../../../network/lib/stats';

function AnalyseClientTableLogic() {
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [isLoading, setIsLoading] = useState(true)

  const isTabletOrMobile = useMediaQuery("(max-width: 920px)");

  // useEffect(() => {
  //   setData(createFakeData("analyse-clients"))

  //   setIsLoading(false)
  // },[])

  function printStats(dates) {
    setDateRange(dates);

    if (dates[0] && dates[1]) {
      const dateStart = dates[0].toLocaleDateString("fr-FR");
      const dateEnd = dates[1].toLocaleDateString("fr-FR");

      statsClient.admin.client.getByDateRange(dateStart,dateEnd)
        .then((resp) => {
          // console.log(resp.data.clients)
          setData(resp.data.clients);
          setIsLoading(false)
        })
     
    }
  }

  useEffect(() => {
    printStats(dateRange);
  }, []);

  // Client Structure
//   {
//     "name": "john doe doe",
//     "phone": "+16979624521",
//     "email": "john23@test.com",
//     "registerDate": "28/07/2023",
//     "lastPurchase": "Aucun",
//     "lastConnection": "28/07/2023",
//     "amount": 0.0,
//     "callPurchase": 0
// },

  
  const columns = useMemo(
    () => [
      // {
      //   header: "Rang",
      //   accessorKey: "idShort",
      //   cell: (info) => info.getValue(),
      // },

      {
        header: "Client",
        accessorKey: "name",
        cell: (info) => info.getValue(),
      },

      {
        header: "Téléphone",
        accessorKey: "phone",
        cell: (info) => info.getValue(),
      },
      {
        header: "E-mail",
        accessorKey: "email",
        cell: (info) => <span className="color-green">{info.getValue()}</span>,
      },

      {
        header: "Date Inscription",
        accessorKey: "registerDate",
        cell: (info) => info.getValue(),
      },
      {
        header: "Dernière Connexion",
        accessorKey: "lastConnection",
        cell: (info) => info.getValue(),
      },
      {
        header: "Dernière Achat",
        accessorKey: "lastPurchase",
        cell: (info) => info.getValue(),
      },
      {
        header: "Nombre d'appelle",
        accessorKey: "callPurchase",
        cell: (info) => info.getValue(),
      },
      {
        header: "Montant",
        accessorKey: "amount",
        cell: (info) => info.getValue(),
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },

    globalFilterFn: fuzzyFilter,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  // if (data.length === 0) {
  //   return (
  //     <div className="info-message-box">
  //       Aucune donnée enregistrée pour le moment. Merci de votre compréhension.
  //     </div>
  //   );
  // }

  const searchableProps = { globalFilter, setGlobalFilter, table, isLoading };
  const filterProps = { dateRange,globalFilter, setGlobalFilter, table, printStats };
  
  return (
    <>
      <FilterBar {...filterProps} />
      {isTabletOrMobile ? (
        <SearchableTableMD {...searchableProps} />
      ) : (
        <SearchableTableXL {...searchableProps} />
      )}
    </>
  );
}

export default AnalyseClientTableLogic;
