import { apiClientUpload } from "../apiClient";


export const uploadClient = {
  mailUploadUpload: ({ transactionId, file }) =>
    apiClientUpload.post("/images/mails/upload?transactionId=" + transactionId, getData(file)),
  officeImageUpload: ({ file }) =>
    apiClientUpload.post("/images/offices/logo/upload", getData(file)),
  officeBannerUpload: ({ file }) =>
    apiClientUpload.post("/images/offices/banner/upload", getData(file)),
  productUpload: ({ productId, file }) =>
    apiClientUpload.post("/images/products/upload?productId=" + productId, getData(file)),
  profileImageUpload: ({ file }) =>
    apiClientUpload.post("/images/users/profile/upload", getData(file)),
  profileBannerUpload: ({ file }) =>
    apiClientUpload.post("/images/users/banner/upload", getData(file)),
  profileVoiceUpload: ({ file }) =>
    apiClientUpload.post("/voices/users/upload", getData(file)),
};

function getData(file) {

  const formData = new FormData();

  formData.append("file", file);

  return formData;

}
