import React from "react";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { fireSuccessAlert } from "../../../../components/utils/Swal";
import { uploadClient } from "../../../../network/lib/upload";

function ApparenceConfigurationTab(props) {

  const {
      imageFondData,
      imageFondDataSet,
      imageLogoData,
      imageLogoDataSet} = props;

  const onImageFondChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      uploadClient.officeBannerUpload({file})
        .then((res) => {
          fireSuccessAlert("Modification éffectuer !");
        });

      reader.onloadend = () => {
        imageFondDataSet(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onImageLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      uploadClient.officeImageUpload({file})
        .then((res) => {
          fireSuccessAlert("Modification éffectuer !");
        });

      reader.onloadend = () => {
        imageLogoDataSet(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="tab-body">
      <label htmlFor="logoInput">Logo du cabinet (max. 200x200)</label>
      <img
        src={imageLogoData ? imageLogoData : "/assets/logo.svg"}
        className="logo"
        alt="logo"
      />
      <div className="input-file">
        <input
          type="file"
          id="file"
          accept="image/*"
          onChange={(e) => onImageLogoChange(e)}
          title=" "
        />
        <label className="text">Sélection votre logo</label>
        <label htmlFor="file" className="button">
          <BiDotsHorizontalRounded color="#B0B0B0" />
        </label>
      </div>
      <label htmlFor="logoFondInput">Fond du cabinet (max. 200x200)</label>
      <img
        height={"200px"}
        width={"200px"}
        src={imageFondData ? imageFondData : "/assets/logo.svg"}
        className="logo"
        alt="fondImage"
      />
      <div className="input-file">
        <input
          type="file"
          id="fileFond"
          accept="image/*"
          onChange={(e) => onImageFondChange(e)}
          title=" "
        />
        <label className="text">Sélection votre fond</label>
        <label htmlFor="fileFond" className="button">
          <BiDotsHorizontalRounded color="#B0B0B0" />
        </label>
      </div>
    </div>
  );
}

export default ApparenceConfigurationTab;
