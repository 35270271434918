import React, { useState, useRef, useEffect } from "react";

const CustomDropdownId = ({ options, value, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(
    value
      ? `${value}`
      : `${options.length > 0 ? options[0].label : ''}`
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (event) => {
    setSelectedOption(event.target.getAttribute("value"));
    setIsOpen(false);
    if (onChange) {
      onChange(event.target.getAttribute("data-id"));
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {selectedOption ? selectedOption : `​`}
        <span className={`arrow ${isOpen ? "open" : ""}`}>&#9660;</span>
      </div>
      <ul className={`dropdown-list ${isOpen ? "open" : ""}`}>
        {options.map((option) => (
          <li
            key={option.key}
            data-id={option.key}
            onClick={handleOptionClick}
            value={option.title}
          >
            {option.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomDropdownId;
