import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { flexRender } from "@tanstack/react-table";
import React from "react";

import TableSkeleton from "../../skeletons/expert/tableSkeleton";
import InfoMessageBoxTD from "../utils/infoMessageBox";
import NavigateBar from "./navigateBar";

function SearchableTableXL({ table, isLoading }) {
  const numOfColumns = table.getAllColumns().length;
  if (isLoading)
    return <TableSkeleton columnCount={numOfColumns} rowCount={5} />;
  return (
    <>
      <Box sx={{ width: "100%" }} className="tableContainer">
        <TableContainer component={Paper} sx={{minHeight: "20rem"}}>
          <Table
            sx={{
              minWidth: 650,
            }}
          >
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} align="center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.length === 0 ? (
                <InfoMessageBoxTD colSpan={numOfColumns} />
              ) : (
                table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <NavigateBar table={table} />
    </>
  );
}

export default SearchableTableXL;
