const { Icon } = require("@iconify/react");

function ForfaitBox(props) {

    const redirectPayment = () => {
        window.open("/" + props.officeName + "/client/payement", '_blank');
    };

    return (
        <div className="block">
            <div className="preview" style={{ 
                backgroundImage: `url("`+ props.image +`")` 
            }}>
                <div className="text">
                    {props.name}
                </div>
            </div>
            <span className="forfait">{props.description}</span>
            <div className="price">
                {props.price} € / <span className="text">pour {props.time} minutes</span>
                <span className="forfait">{props.subdescription}</span>
            </div>
            <button className="btn" onClick={redirectPayment}>Choisissez ce forfait {">"}</button>
            <hr />
            <div className="list">
                <Icon icon="material-symbols:nest-clock-farsight-analog-outline-rounded" color="#D00000"/>
                <span>Le forfait peut être réparti en plusieurs consultations, offrant ainsi plus de flexibilité et de possibilités.</span>
            </div>
            <div className="payement">
                <Icon icon="material-symbols:add-shopping-cart" color="#F25858" />
                <span>Paiement sécurisé</span>
            </div>
            <ul>
                <li>Utilisable par téléphone <Icon icon="mdi:telephone" color="#F25858" /></li>
                <li>ou par chat <Icon icon="mdi:message-outline" color="#F25858" /></li>
            </ul>
        </div>
    )
}

export default ForfaitBox;