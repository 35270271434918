import React from "react";
import FrenchCalendar from "../../calendar/frenchCalendar";
import DebouncedInput from "../utils/debouncedInput";

function FilterBar({ dateRange,globalFilter, setGlobalFilter, table, printStats }) {
  return (
    <div>
      <div className="filter-bar">
        <div className="date">
          <label htmlFor="inputRange">Date</label>
          <FrenchCalendar
            dateFormat="dd MM, yy"
            value={dateRange}
            onChange={(e) => printStats(e.value)}
            selectionMode="range"
            id="inputRange"
          />
        </div>
        <div className="recherche">
          <DebouncedInput
            type="text"
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Rechercher"
          />
        </div>
      </div>
    </div>
  );
}

export default FilterBar;
