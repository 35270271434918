import { createEmptySlotsForWeek } from "../util/util";

// const fetchConvertedBookings = (state, rawData) => {
//   const bookings = convertToBookings(rawData);
//   console.log(bookings);
//   return { ...state, bookings: bookings };
// };

export const reducer = (state, action) => {
  switch (action.type) {
    // case "FETCH_ALL_BOOKINGS": 
    // return { ...state, bookings: action.payload };
      // return fetchConvertedBookings(state, action.payload);

    case "FETCH_ALL_AVAILABLE_SLOTS":
       return { ...state, availableSlots: action.payload };
      // return fetchConvertedBookings(state, action.payload);

    case "ADD_BOOKING":
      return { ...state, booking: action.payload };
    // case "DELETE_BOOKING":
    //   let { bookings } = state;
    //   return {
    //     ...state,
    //     bookings: bookings.filter(
    //       (booking) => booking.id.getTime() !== action.payload.getTime()
    //     ),
    //   };
    case "SET_DISPLAY_WEEK":
      return { ...state, displayWeek: action.payload };
    case "SET_DISPLAY_MONTH":
      return { ...state, displayMonth: action.payload };
    // case "SET_DISPLAY_SLOTS":
    //   return {
    //     ...state,
    //     displaySlots: createEmptySlotsForWeek(action.payload),
    //   };
    default:
      return state;
  }
};
