import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fireSuccessAlert } from "../components/utils/Swal";
import { authenticationClient } from "../network/lib/authentication";
import { fetchOfficeData } from "../redux/features/office/officeSlice";

function ModifyPassword() {
  const dispatch = useDispatch();
  const officeState = useSelector((state) => state.office);
  const { officeName, token, email } = useParams();
  const navigate = useNavigate();
  
  const newPassword = useRef();
  const confirmNewPassword = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();

    const password = newPassword.current.value;

    if (newPassword.current.value === confirmNewPassword.current.value ) {
      authenticationClient.forgotPassword
        .modifyPassword({ email, officeName, password, token })
        .then((res) => {
          console.log(res.data);
          fireSuccessAlert("Votre mot de passe a été modifié avec succès !").then(() => {
            navigate("/"+ officeName + "/login");
          });
        });
    }
  };

  useEffect(() => {
    dispatch(fetchOfficeData(officeName));
  }, []);

  return (
    <div id="passwordReset">
      <div className="content">
        <img  src={
            officeState.data["logoImage"] == null
              ? "/assets/logo.svg"
              : officeState.data["logoImage"]
          } alt="logo"/>

        <form onSubmit={handleSubmit}>
        
          <div className="form-group">
            <label>New Password</label>
            <input
              placeholder="Entrer votre password"
              type="password"
              ref={newPassword}
            />
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input
              placeholder="Entrer votre password"
              type="password"
              ref={confirmNewPassword}
            />
          </div>
          <input
            type="submit"
            value="Changer le mot de passe"
            className="submit"
          />
        </form>
      </div>
      <div className="bg" style={{
          backgroundImage: `url(` + officeState.data["bannerImage"] + `)`,
        }}>
      </div>
    </div>
  );
}

export default ModifyPassword;
