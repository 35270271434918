import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import TabGroup from "../../../components/tab";
import CabinetConfigurationTab from "./tabs/cabinetConfiguration";
import { useState } from "react";
import { useEffect } from "react";
import { officeClient } from "../../../network/lib/office";
import { fireSuccessAlert } from "../../../components/utils/Swal";
import ExpertConfigurationTab from "./tabs/expertConfiguration";
import FinancierConfigurationTab from "./tabs/financierConfiguration";
import ApparenceConfigurationTab from "./tabs/apparenceConfiguration";
import MailConfigurationTab from "./tabs/mailConfiguration";

function CabinetConfigurationPage() {

  const [officeData, officeDataSet] = useState([]);
  const [imageLogoData, imageLogoDataSet] = useState([]);
  const [imageFondData, imageFondDataSet] = useState([]);
  const [emailData, emailDataSet] = useState([]);
  const [urlData, urlDataSet] = useState([]);
  const [cgvData, cgvDataSet] = useState([]);
  const [easyTransacKeyData, easyTransacKeyDataSet] = useState([]);
  const [expertCallRateData, expertCallRateDataSet] = useState([]);
  const [expertChatRateData, expertChatRateDataSet] = useState([]);
  const [expertMailRateData, expertMailRateDataSet] = useState([]);
  const [expertMeetRateData, expertMeetRateDataSet] = useState([]);
  const [priceMailData, priceMailDataSet] = useState([]);
  const [messageBirthdayData, messageBirthdayDataSet] = useState([]);
  const [messageRegisterData, messageRegisterDataSet] = useState([]);

  const tabs = [
    { title: "Configuration Générale", body: <CabinetConfigurationTab office={officeData["officeName"]} 
      emailData={emailData}
      emailDataSet={emailDataSet}
      urlData={urlData}
      urlDataSet={urlDataSet}
      cgvData={cgvData}
      cgvDataSet={cgvDataSet}  /> },
    { title: "Expert", body: <ExpertConfigurationTab
      expertCallRateData={expertCallRateData}
      expertCallRateDataSet={expertCallRateDataSet}
      expertChatRateData={expertChatRateData}
      expertChatRateDataSet={expertChatRateDataSet}
      expertMailRateData={expertMailRateData}
      expertMailRateDataSet={expertMailRateDataSet}
      expertMeetRateData={expertMeetRateData}
      expertMeetRateDataSet={expertMeetRateDataSet}/> },
    { title: "Financier", body: <FinancierConfigurationTab
      easyTransacKeyData={easyTransacKeyData}
      easyTransacKeyDataSet={easyTransacKeyDataSet}
      priceMailData={priceMailData}
      priceMailDataSet={priceMailDataSet}/> },
    { title: "Apparence", body: <ApparenceConfigurationTab 
      imageLogoData={imageLogoData}
      imageLogoDataSet={imageLogoDataSet}
      imageFondData={imageFondData}
      imageFondDataSet={imageFondDataSet}  /> },
    { title: "Mail", body: <MailConfigurationTab 
      messageBirthdayData={messageBirthdayData}
      messageBirthdayDataSet={messageBirthdayDataSet}
      messageRegisterData={messageRegisterData}
      messageRegisterDataSet={messageRegisterDataSet} /> }
  ];

  useEffect(() => {

    officeClient.admin.getOffice().then((resp) => {

      const data = resp.data;

      officeDataSet(data);

      imageLogoDataSet(data["logo"]);
      imageFondDataSet(data["fondImage"]);
      emailDataSet(data["email"]);
      urlDataSet(data["urlMain"]);
      cgvDataSet(data["urlCGV"]);
      easyTransacKeyDataSet(data["easyPrivateKey"]);
      expertCallRateDataSet(data["expertCallRate"]);
      expertChatRateDataSet(data["expertChatRate"]);
      expertMeetRateDataSet(data["expertMeetRate"]);
      expertMailRateDataSet(data["expertMailRate"]);
      priceMailDataSet(data["mailPrice"]);
      messageBirthdayDataSet(data["messageBirthday"]);
      messageRegisterDataSet(data["messageRegister"]);

    });
    // .catch((error) => {
    //   // Gérer l'erreur
    // });
  }, []);

  function updateOffice() {

    const formData = {
      email: emailData,
      easyPrivateKey: easyTransacKeyData,
      urlMain: urlData,
      urlCGV: cgvData,
      expertCallRate: expertCallRateData,
      expertChatRate: expertChatRateData,
      expertMailRate: expertMailRateData,
      expertMeetRate: expertMeetRateData,
      mailPrice: priceMailData,
      messageBirthday: messageBirthdayData,
      messageRegister: messageRegisterData,
    };

    officeClient.admin.updateOffice(formData).then((resp) => {
      fireSuccessAlert("Modification éffectuer !");
    });
  }

  return (
    <div id="cabinetConfiguration">
      <div className="top-bar">
        <h2>Cabinet Configuration</h2>
        <div className="pagination">
          <Link to="#" className="link">
            Accueil
          </Link>
          <IoIosArrowForward color="#A7A7A7" />
          <Link to="#" className="link">
            Paramètres
          </Link>
        </div>
      </div>

      <div className="content-box">
        <TabGroup tabs={tabs} />
        <button className="btn-light" onClick={() => updateOffice()}>
          Sauvegarder la configuration
        </button>
      </div>
    </div>
  );
}

export default CabinetConfigurationPage;
