import { Icon } from "@iconify/react";
import {
  addWeeks,
  eachDayOfInterval,
  endOfWeek,
  format,
  startOfWeek,
  subWeeks
} from "date-fns";
import fr from "date-fns/locale/fr";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CalendarDay from "../../components/calendar/calendarDay";
import TableSkeleton from "../../components/skeletons/expert/tableSkeleton";
import { fireSuccessAlert } from './../../components/utils/Swal';
import { calendarClient } from './../../network/lib/calendar';

function MyPlanning() {
  const { officeName } = useParams();
  const { calendarType } = useParams();

  const [date, setDate] = useState(new Date());
  const [calendarData, calendarDataSet] = useState([]);
  const [calendarIsLoading, setCalendarIsLoading] = useState(true);

  const [bookingStatus, setBookingStatus] = useState({
    action: "",
    bookingDate: { day: null, hour: null, calendarId: null },
    loading: false,
  });

  const startOfWeekDate = startOfWeek(date, { weekStartsOn: 1, locale: fr });
  const endOfWeekDate = endOfWeek(date, { weekStartsOn: 1, locale: fr });

  const formattedStartOfWeek = format(startOfWeekDate, "d", { locale: fr });
  const formattedEndOfWeek = format(endOfWeekDate, "d MMMM yyyy", {
    locale: fr,
  });

  const weekRange = `${formattedStartOfWeek} - ${formattedEndOfWeek}`;

  const daysWeek = eachDayOfInterval({
    start: startOfWeekDate,
    end: endOfWeekDate,
  });

  const daysDates = daysWeek.map((jour) =>
    format(jour, "EEE dd/MM", { locale: fr })
  );

  const addWeek = () => {
    const newDate = addWeeks(date, 1);
    setDate(newDate);
    makeCalendar(newDate);
  };

  const subtractWeek = () => {
    const newDate = subWeeks(date, 1);
    setDate(newDate);
    makeCalendar(newDate);
  };

  useEffect(() => {
    makeCalendar(date);
  }, [calendarType]);

  function addTiming(strDayData, hourData) {
    const dayObj = parseDateString(strDayData);

    const calendarTiming = {
      year: date.getFullYear(),
      month: dayObj["month"],
      hour: hourData,
      day: dayObj["day"],
      calendarType: calendarType.toUpperCase(),
    };

    setBookingStatus({
      action: "add",
      bookingDate: { day: strDayData, hour: hourData, calendarId: null },
      loading: true,
    });

    calendarClient.expert.addTiming(calendarTiming)
      .then((resp) => {
        fireSuccessAlert("L'horaire a été ajouté !")
        .then(() => {
          makeCalendar(date);
        });
      })
  }

  const removeTiming = ({ calendarId }) => {

    if (calendarId === null || calendarId === undefined) {
      throw new Error("calendarId is undefinded or null");
    }

    setBookingStatus({
      action: "remove",
      bookingDate: { day: null, hour: null, calendarId: calendarId },
      loading: true,
    });
   
    calendarClient.expert.deleteTiming(calendarId)
      .then((resp) => {
        fireSuccessAlert("L'horaire a été supprimé !")
        .then(() => {
          makeCalendar(date);
        });
      })
  };

  const makeCalendar = (thisDate) => {
    setCalendarIsLoading(true);
    const daysWeeks = eachDayOfInterval({
      start: startOfWeek(thisDate, { weekStartsOn: 1, locale: fr }),
      end: endOfWeek(thisDate, { weekStartsOn: 1, locale: fr }),
    });

    const calendar = {
      calendarType: calendarType.toUpperCase(),
      dates: daysWeeks.map((jour) =>
        format(jour, "dd/MM/yyyy", { locale: fr })
      ),
    };


    calendarClient.expert.postCalendar(calendar)
      .then((resp) => {
        calendarDataSet(resp.data.calendar);
      })
      .then(() => {
        setCalendarIsLoading(false);
        setBookingStatus({
          ...bookingStatus,
          loading: false,
        });
      })
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
        setCalendarIsLoading(false);
        setBookingStatus({
          ...bookingStatus,
          loading: false,
        });
      });
  };

  function parseDateString(dateString) {
    const [dayOfWeekStr, dateStr] = dateString.split(" ");
    const [dayStr, monthStr] = dateStr.split("/");

    const day = parseInt(dayStr);
    const month = parseInt(monthStr);

    return {
      day,
      month,
    };
  }

  return (
    <div id="myPlanning">
      <h2>
        Mon Planning {" "}
        {calendarType.toUpperCase() === "CLASSIC" ? "Classique" : "Rendez-vous"}
      </h2>
      <div className="dateBar">
        <h4>{weekRange}</h4>
        <div className="navigation">
          <button className="btnGreen-arrow" onClick={subtractWeek}>
            <Icon icon="material-symbols:chevron-left" />
          </button>
          <button className="btnGreen-arrow" onClick={addWeek}>
            <Icon icon="material-symbols:chevron-right" />
          </button>
        </div>
      </div>
      {calendarIsLoading ? (
        <TableSkeleton columnCount={8} rowCount={10} />
      ) : (
        <div className="content">
          <div className="emplois">
            <div className="emploi">
              <div className="part min">
                <div className="top"></div>
                {[0, 1, 2, 3, 4, 5, 6].map((elem) => {
                  return (
                    <div key={elem}>
                      <div className="top">{daysDates[elem]}</div>
                    </div>
                  );
                })}
              </div>
              {[
                "00",
                "01",
                "02",
                "03",
                "04",
                "05",
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
              ].map((elem) => {
                return (
                  <div key={elem} className="part">
                    <div className="top">{elem} h</div>
                    {[
                      "MONDAY",
                      "TUESDAY",
                      "WEDNESDAY",
                      "THURSDAY",
                      "FRIDAY",
                      "SATURDAY",
                      "SUNDAY",
                    ].map((day, index) => (
                      <CalendarDay
                        bookingStatus={bookingStatus}
                        details={{
                          day,
                          addTiming,
                          removeTiming,
                          daysDates,
                          calendarData,
                        }}
                        elem={elem}
                        key={day}
                        index={index}
                      />
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MyPlanning;
