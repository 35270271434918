import { useRef } from "react";
import { AiFillStar } from "react-icons/ai";
import { userClient } from "./../../network/lib/user";
import { fireSuccessAlert } from "./../utils/Swal";

function AdviceBox(props) {
  const messageRef = useRef(null);

  function addReply() {
    const opinionReply = {
      opinionId: props.id,
      message: messageRef.current.value,
    };

    userClient.expert.opinion.add(opinionReply).then((resp) => {
      fireSuccessAlert("L'avis a été ajouté !").then(() => {
        // Faire quelque chose ici
      });
    });
  }

  return (
    <div className="advice-box">
      <div className="top-bar">
        <span className="title">
          <span className="purple-text">{props.idShort}</span> - {props.title}
        </span>
        <div className="stars">
          {[...Array(props.score)].map((_, index) => (
            <AiFillStar key={index} color="#E7CE4C" />
          ))}
        </div>
      </div>

      <p>{props.children}</p>

      <hr />

      {props.alert ? (
        <div className="alert-box">
          <span>{props.alert}</span>
        </div>
      ) : (
        <div className="form-group">
          <textarea ref={messageRef} />
          <div className="btn">
            <button onClick={() => addReply()}>Répondre</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdviceBox;
