import Skeleton from "react-loading-skeleton";

function BoxTdSkeleton(props) {

  return (
    <div className="box box-td">
      <h3>Appels manqués</h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">Ce mois-ci</span>

          <span className="primary-text mb-3"><Skeleton /></span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text"><Skeleton /></span>
        </div>
        <div>
          <span className="sub-title-gray">Aujourd’hui</span>

          <span className="primary-text mb-3"><Skeleton /></span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text"><Skeleton /></span>
        </div>
      </div>
    </div>
  );
}

export default BoxTdSkeleton;
