import MessageBox from "../../components/expert/messageBox";
import NavigateBar from "../../components/tables/navigateBar";

function MyMessagesExpert() {
  return (
    <div id="myMessages">
      <h3>Mes messages</h3>

      <MessageBox />
      <MessageBox />
      <MessageBox />

      <NavigateBar />
    </div>
  );
}

export default MyMessagesExpert;
