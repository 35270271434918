import CardTable from "../../components/tables/cardTable";

function MyMethodsPayementClient() {

  return (
    <div id="myMethodsPayementClient">
      <h2>MES CARTES</h2>

      <CardTable />

    </div>
  );
}

export default MyMethodsPayementClient;
