import { Icon } from "@iconify/react";

function NavLink(props) {
    return (
        <div className="navLink">
            <div className={props.selected === 0 ? "selected" : ""}>
                <Icon icon="material-symbols:supervisor-account-sharp" />
                Présentation 
            </div>
            <div className={props.selected === 1 ? "selected" : ""}>
                <Icon icon="tabler:packge-export" />
                Forfaits
            </div>
            <div className={props.selected === 2 ? "selected" : ""}>
                <Icon icon="ic:outline-mode-comment" />
                Commentaires
            </div>
        </div>
    );
}

export default NavLink;