// function InfoMessageBox() {
//   return (
//     <div className="info-message-box">
//       Aucune donnée enregistrée pour le moment. Merci de votre compréhension.
//     </div>
//   );
// }

// export default InfoMessageBox

function InfoMessageBoxTD({ colSpan }) {
  return (
    <tr>
      <td colSpan={colSpan}>
        <div className="info-message-box" style={{textAlign: "center"}}>
          Aucune donnée enregistrée pour le moment. Merci de votre
          compréhension.
        </div>
      </td>
    </tr>
  );
}

export default InfoMessageBoxTD;
