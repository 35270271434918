import { useState } from "react";
import RatingModal from "../../components/modal/ratingModal";
import ConsultationsTable from "../../components/tables/consultationsTable";

function MyConsultationsClient() {
 
  return (
    <div id="myConsultationsClient">
      <h2>Mes consultations</h2>
      
      <ConsultationsTable />
    </div>
  );
}

export default MyConsultationsClient;
