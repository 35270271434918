
import Skeleton from "react-loading-skeleton";

function HeaderSkeleton(props) {
  return (
    <header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "25px",
      }}
    >
      <Skeleton width={200} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          gap: "3rem",
        }}
      >
        <Skeleton width={100} />
        <Skeleton width={100}/>

        <Skeleton width={100}/>
        <Skeleton circle width={50} height={50} />
      </div>
    </header>
  );
}

export default HeaderSkeleton;
