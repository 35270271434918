import { Icon } from "@iconify/react";
import ReactAudioPlayer from "react-audio-player";

function StarRating(props) {
    const { advice } = props;
  
    const renderStars = () => {
      const stars = [];
      for (let i = 0; i < advice; i++) {
        stars.push(<Icon key={i} icon="material-symbols:star" />);
      }
      return stars;
    };
  
    return (
      <>
        {renderStars()}
      </>
    );
  }


function ProfileBar(props) {

    const goTo = (idName) => {
        
        const section = document.querySelector( '#' + idName );
        section.scrollIntoView( { behavior: 'smooth', block: 'start' } );

    };

    return (
        <section id="profileBar">
            <div className="picture" style={{ 
                        backgroundImage: `url("`+ props.imageProfile +`")` 
                    }}></div>
            <div className="info">
                <div className="details">
                    <div>
                        <h3>{props.name}</h3>
                        {props.voice ?  <ReactAudioPlayer
                            src={props.voice}
                            title={"Audio"}
                            controls
                        /> : ""}
                    </div>
                    <div className="avis">
                        <span>{props.advice} avis</span>
                        <div className="stars">
                            <StarRating advice={props.scores} />
                        </div>
                        <span>{props.callCount} consultations</span>
                    </div>
                </div>
                <div className="nav">
                    <div>
                        <Icon icon="material-symbols:supervisor-account-sharp" />
                        <span onClick={() => goTo("profileBar")}>Présentation </span>
                    </div>
                    <div>
                        <Icon icon="tabler:packge-export" />
                        <span onClick={() => goTo("forfaits")}>Forfaits </span>
                    </div>
                    <div>
                        <Icon icon="ic:outline-mode-comment" />
                        <span onClick={() => goTo("commentaires")}>Commentaires </span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProfileBar;