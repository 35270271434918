import apiClient from "../apiClient";

export const authenticationClient = {
  register: ({
    email,
    password,
    name,
    officeName,
    phone,
    brithDay,
    birthMonth,
    birthYear,
    emailing,
    majority,
  }) =>
    apiClient.put("/users/register/", {
      email,
      password,
      name,
      officeName,
      phone,
      brithDay,
      birthMonth,
      birthYear,
      emailing,
      majority,
    }),

  login: ({ email, password, officeName }) =>
    apiClient.put("/users/login/", {
      email,
      password,
      officeName,
    }),
  forgotPassword: {
    sendEmail: (email, officeName) =>
      apiClient.post("/users/forgets/password", {
        email,
        officeName,
      }),
    modifyPassword: ({ email, officeName, password, token }) =>
      apiClient.post("/users/finds/password", {
        email,
        officeName,
        password,
        token,
      }),
  },
};
