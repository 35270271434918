import React from "react";
import Skeleton from "react-loading-skeleton";

function RowSkeleton({ columnCount }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "2rem",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {Array.from({ length: columnCount }, (_, index) => {
        return (
          <div style={{ height: 70, width: "100%" }} key={index}>
            <Skeleton height="100%" width="100%" />
          </div>
        );
      })}
    </div>
  );
}

function TableSkeleton({ columnCount, rowCount, md }) {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "2rem",
        padding: "2rem",
        backgroundColor: "#ffffff",
      }}
    >
      <div style={{ height: "1.5rem", width: "100%", marginBottom: "2rem" }}>
        <Skeleton height="100%" width="100%" />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          alignItems: "flex-start",
          // justifyContent: "space-between",
          width: "100%",
        }}
      >
        {Array.from({ length: rowCount }, (_, index) => {
          return <RowSkeleton columnCount={md ? 2 :columnCount} key={index}/>;
        })}
      </div>
    </div>
  );
}

export default TableSkeleton;
