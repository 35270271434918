import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExpertBox from "../../components/path/expertBox";
import { profileClient } from "../../network/lib/profile";

function Experts() {
  const { expertId, profileUrl } = useParams();

  const [expertData, expertDataSet] = useState([]);

  const styles = `
    button.online {
      background-color: #20d794;
      border-radius: 7px;
    }
    button.consult {
      background-color: #e9319a;
      border-radius: 7px;
      cursor: not-allowed;
    }
    button.disable {
      background-color: #656565;
      border-radius: 7px;
      cursor: not-allowed;
    }
    button {
      background-color: #20d794;
      border: none;
      border-radius: 30px;
      color: #fff;
      cursor: pointer;
      font-family: Poppins, sans-serif;
      font-size: 1.1rem;
      font-weight: 500;
      margin-top: auto;
      padding: 7px 27px;
    }`;

  useEffect(() => {
    fetchExpertStatus(expertId);

    const intervalId = setInterval(() => {
      fetchExpertStatus(expertId);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []); // Adding listTypeData as dependency

  function ButtonStatus(props) {

    const { disponibily } = props;
    const { redirectProfil } = props;

    let button;

    switch(disponibily) {

        case "FREE":
            button = <button className="online" onClick={redirectProfil}>Je consulte</button>;
            break;

        case "ON_WORK":
            button = <button className="consult">En consultation</button>;
            break;

        default:
            button = <button className="disable">Hors-Ligne</button>;
            break;
        
    }

  
    return (
      <>
        {button}
      </>
    );
  }

  const redirectProfil = () => {
    window.open(atob(profileUrl) + "?profileId=" + expertId, '_blank', 'noreferrer');
};

  const fetchExpertStatus = (expertId) => {
    profileClient.expert
      .getStatus(expertId)
      .then((res) => expertDataSet(res.data));
  };

  return (
    <>
      <style>{styles}</style>
      <div className="btn">
        <ButtonStatus redirectProfil={redirectProfil}
            disponibily={expertData.statue}/>
      </div>
    </>
  );
}

export default Experts;
