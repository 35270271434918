import apiClient from '../apiClient'
export const statsClient = {
    expert: {
        overall:{
            get: (dateStart,dateEnd) => apiClient.get(`/stats/experts/overall/view/?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        },
        home: {
            get: () => apiClient.get('/stats/experts/home/view/')
        }
        
    },
    admin: {
        home: {
            get: () => apiClient.get('/stats/admins/home/view/')
        },
        expert:{
            getByDateRange: (dateStart,dateEnd) => apiClient.get(`/stats/admins/experts/view/?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        },
        client:{
            getByDateRange: (dateStart,dateEnd) => apiClient.get(`/stats/admins/clients/view/?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        }
    }
}