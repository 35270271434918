import React from "react";
import DebouncedInput from "../utils/debouncedInput";
import { Calendar } from "primereact/calendar";
import CustomSelect from "../../utils/CustomSelect";
import { useLocation, useNavigate } from "react-router-dom";

function FilterBar({ dateRange, globalFilter, setGlobalFilter, table }) {
  const navigate = useNavigate();
  let location = useLocation();
  return (
    <div>
      <div className="filter-bar">
        <button
          className="nouveau"
          onClick={() => navigate(`${location.pathname}/new`)}
        >
          <span className="plus">+</span>
          <span className="text">Nouveau</span>
        </button>
        <div className="recherche">
          <DebouncedInput
            type="text"
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            placeholder="Rechercher"
          />
        </div>
      </div>
    </div>
  );
}

export default FilterBar;
