import { useEffect, useState } from "react";
import ConsultationBox from "../../components/expert/consultationBox";
import { userClient } from "./../../network/lib/user";
import ConsultationMeetBox from "../../components/expert/consultationMeetBox";
import { format } from 'date-fns';

function ConsultationExpert() {
  const [userConsultationsData, userConsultationsDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userClient.expert.consultation
      .getAll()
      .then((resp) => {
        userConsultationsDataSet(resp.data);
      })
      .then(() => setIsLoading(false))
      .catch((error) => {
        setIsLoading(false);
        //window.location.href = "https://vooxhub.com";
      });
  }, []);

  return (
    <div id="consultationExpert">
      <h3>Consultations {"(" + format(new Date(), 'dd/MM/yyyy') + ")"}</h3>

      <ConsultationMeetBox
        title="RENDEZ-VOUS CONSULTATION"
        titles={["ID", "Date", "Heure", "Client", "Status", ""]}
        data={userConsultationsData.callsMeet}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="ENCOURS CONSULTATION"
        titles={["ID", "Heure", "Client", "Durée", "Type"]}
        data={userConsultationsData.callsInProgress}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="CONSULTATION MANQUÉES"
        titles={["ID", "Heure d'Appelle", "Client", "Type"]}
        data={userConsultationsData.callsMissed}
        isLoading={isLoading}
      />

      <ConsultationBox
        title="DERNIERES CONSULTATION"
        titles={["ID", "Heure", "Client", "Durée", "Type", "Gains"]}
        data={userConsultationsData.calls}
        isLoading={isLoading}
      />
    </div>
  );
}

export default ConsultationExpert;
