import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchOfficeData } from "../redux/features/office/officeSlice";
import { fireSuccessAlert } from './../components/utils/Swal';
import { authenticationClient } from './../network/lib/authentication';
function PasswordReset() {
	const dispatch = useDispatch();
  const officeState = useSelector((state) => state.office);
  const { officeName } = useParams();
  const [email, setEmail] = useState("")

  const handleSubmit = (e) => {
	e.preventDefault()
	console.log({email,officeName})
	authenticationClient.forgotPassword.sendEmail(email,officeName).then(res => {
		console.log(res.data)
		fireSuccessAlert("Le lien de réinitialisation du mot de passe a été envoyé par e-mail.")
		.then(() => {
			// redirect somewhere
		})
	})
  }

  useEffect(() => {
    dispatch(fetchOfficeData(officeName));
  }, []);

  return (
    <div id="passwordReset">
      <div className="content">
	  <img  src={
            officeState.data["logoImage"] == null
              ? "/assets/logo.svg"
              : officeState.data["logoImage"]
          } alt="logo"/>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>E-mail</label>
            <input placeholder="Entrer votre email" type="email" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>
          <input
            type="submit"
            value="Demander un nouveau mot de passe"
            className="submit"
          />
        </form>
      </div>
	  <div className="bg" style={{
          backgroundImage: `url(` + officeState.data["bannerImage"] + `)`,
        }}>
      </div>
    </div>
  );
}

export default PasswordReset;
