import { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import UserInfo from "./userInfo";

function ConsultationDropDown(props) {
  const [showUserInfo, setShowUserInfo] = useState(false);

  return (
    <>
      <div className="dropDown">
        <span>{props.title}</span>
        <MdKeyboardArrowDown
          className={`icon ${showUserInfo ? "show" : ""}`}
          onClick={() => setShowUserInfo(!showUserInfo)}
        />
      </div>

      {props.data.map((data, index) =>
        showUserInfo ? <UserInfo key={index} data={data} active={props.active} /> : ""
      )}
    </>
  );
}

export default ConsultationDropDown;
