import StatistiqueTable from "../../components/tables/statistiqueTable";

import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import FrenchCalendar from "../../components/calendar/frenchCalendar";
import TableSkeleton from "../../components/skeletons/expert/tableSkeleton";
import { statsClient } from "./../../network/lib/stats";

function StatistiqueExpert() {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [userStatsData, userStatsDataSet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function printStats(dates) {
    setDateRange(dates);

    if (dates[0] && dates[1]) {
      const dateStart = dates[0].toLocaleDateString("fr-FR");
      const dateEnd = dates[1].toLocaleDateString("fr-FR");

      statsClient.expert.overall
        .get(dateStart, dateEnd)
        .then((resp) => {
          userStatsDataSet(resp.data);
        })
        .then(() => setIsLoading(false))
        .catch((error) => {
          // Gérer l'erreur
        });
    }
  }

  useEffect(() => {
    printStats(dateRange);
  }, []);

  return (
    <div id="expertStatistique">
      <h2>Statistiques</h2> <br />
      <div className="container">
        <div className="box">
          <div className="top-bar">GAIN</div>
          <div className="area">
            <div className="title">
              {isLoading ? <Skeleton /> : userStatsData["gain"]}
            </div>
          </div>
        </div>
        <div className="box">
          <div className="top-bar">INFO</div>
          <div className="area">
            <span className="sub-title">Taux décroché (Nb appel manqué)</span>
            <span className="title-2">
              {isLoading ? <Skeleton /> : userStatsData["callMissed"]}
            </span>
          </div>
        </div>
        <div className="box">
          <div className="top-bar">AUDIENCES</div>
          <div className="area">
            <span className="sub-title">Nombre consultation</span>
            <span className="title-2">
              {isLoading ? <Skeleton /> : userStatsData["audience"]}
            </span>
            <div className="multi-tags">
              <div className="tag-container">
                <div className="tag">Tel</div>
                <span>
                  {isLoading ? <Skeleton /> : userStatsData["audiencePhone"]}%
                </span>
              </div>
              <div className="tag-container">
                <div className="tag">Chat</div>
                <span>
                  {isLoading ? <Skeleton /> : userStatsData["audienceChat"]}%
                </span>
              </div>
              <div className="tag-container">
                <div className="tag">Mail</div>
                <span>
                  {isLoading ? <Skeleton /> : userStatsData["audienceMail"]}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-bar">
        <label htmlFor="inputRange">Date</label>
        <FrenchCalendar
          dateFormat="dd MM, yy"
          value={dateRange}
          onChange={(e) => printStats(e.value)}
          selectionMode="range"
          id="inputRange"
        />
      </div>
      {isLoading ? (
        <TableSkeleton columnCount={8} rowCount={3} />
      ) : (
        <StatistiqueTable
          purchases={userStatsData["purchases"]}
          isLoading={isLoading}
        />
      )}
    </div>
  );
}

export default StatistiqueExpert;
