import React from "react";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { paymentClient } from "./../../network/lib/payment";
import { fireErrorAlert, fireSuccessAlert } from "./../utils/Swal";
import { uploadClient } from "../../network/lib/upload";

/*function PaymentButton(props) {
  const { urlRedirect, imageFile } = props;

  /*const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js not loaded.");
      return;
    }

    stripe
      .confirmCardPayment(clientSecretId, {
        payment_method: paymentMethod,
      })
      .then(function (result) {
        // Handle result.error or result.paymentIntent
        const MySwal = withReactContent(Swal);

        if (result.error) {
          MySwal.fire({
            title: "Oups...",
            text: "Désolé, paiement refusé.",
            icon: "error",
          }).then(() => {
            window.location.reload();
          });
        }

        if (result.paymentIntent) {
          // const config = {
          //   headers:{
          //     sessionId: localStorage.getItem("sessionId"),
          //   }
          // };

          // axios.post(
          //       "https://api.vooxhub.com/payments/checkout", JSON.stringify({paymentIntentId : result.paymentIntent.id}), config
          //   )
          paymentClient
            .checkout(result.paymentIntent.id)
            .then((resp) => {
              const mailId = resp.data.mailId;
              if(mailId) {
                uploadClient.mailUploadUpload({mailId, file : imageFile});
              }
              fireSuccessAlert("Votre paiement a été accepté.");
            })
            .catch((error) => {
              fireErrorAlert("Désolé, paiement refusé.").then(() => {
                window.location.reload();
              });
            });
        }
      });
  };

  return (
    <>
      <div className="col-2-button">
          <button className="valide" onClick={() => validatePayment()}>
            Payer
          </button>
          <div className="col-flex">
            <Icon icon="material-symbols:lock" color="#F5CE00" />
            <span>Paiement sécurisé</span>
          </div>
        </div>
    </>
  );
}*/

function PaymentConfirm(props) {
  const { paymentUrl, paymentData, paymentCardId, paymentCardNew, codeName, imageFile } = props;
  const [disabledButton, setDisabledButton] = useState(false);

  const validatePayment = () => {
    const config = {
      headers: {
        sessionId: localStorage.getItem("sessionId"),
      },
    };

    if(paymentCardNew.cardNumber) {

      console.log(paymentCardNew)

      paymentData.cardNumber = paymentCardNew.cardNumber;
      paymentData.cvv = paymentCardNew.cardCVV;
      paymentData.year = paymentCardNew.cardYear;
      paymentData.month = paymentCardNew.cardMonth;
      
      paymentData.cardMethod = "NEW_CARD";

    } else {

      paymentData.cardMethod = "SAVE_CARD";
      paymentData.cardId = paymentCardId;

    }

    paymentData.codeName = codeName;

    if(paymentUrl.includes("mails") && !paymentData.expertId) {

      fireErrorAlert("Désolé, vous avez oublié de cliquer sur le bouton validé ci-dessus.");
      return;

    }

    setDisabledButton(true);

    axios
      .put("https://api.vooxhub.com/" + paymentUrl, paymentData, config)
      .then((resp) => {
        const data = resp.data;
        if(imageFile){
          uploadClient.mailUploadUpload({transactionId : data.transactionId, file : imageFile})
          .then(() => {
            window.location.href = data.redirect;
          });
        } else {
          window.location.href = data.redirect;
        }
      })
      .catch((error) => {
        //window.location.href = "https://vooxhub.com";
        console.log(error);
        setDisabledButton(false);
      });
  };

  console.log(paymentData);

  return (
    <>
      <div className="col-2-button">
          <button disabled={disabledButton} className="valide" onClick={() => validatePayment()}>
            Payer
          </button>
          <div className="col-flex">
            <Icon icon="material-symbols:lock" color="#F5CE00" />
            <span>Paiement sécurisé</span>
          </div>
        </div>
    </>
  );
}

export default PaymentConfirm;
