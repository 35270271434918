import React from "react";

function FinancierConfigurationTab(props) {

  const {easyTransacKeyData,
    easyTransacKeyDataSet,
      priceMailData,
      priceMailDataSet} = props;

  return (
    <div className="tab-body">
      <label htmlFor="easyTransacKey">EasyTransacKey</label>
      <input
        name="easyTransacKey"
        value={easyTransacKeyData}
        onChange={(e) => {easyTransacKeyDataSet(e.target.value)}}
        className="input-light"
      />
      <label htmlFor="clientMailPrice">Prix du mail (Au client)</label>
      <input
        name="clientMailPrice"
        type="number"
        value={priceMailData}
        onChange={(e) => {priceMailDataSet(e.target.value)}}
        className="input-light"
      />

    </div>
  );
}

export default FinancierConfigurationTab;
