import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router";
import { fetchOfficeData } from "../../redux/features/office/officeSlice";
import { fetchUser } from "../../redux/features/user/userSlice";
import BatMenu from "./batMenu";
import Header from "./header";

function Layout(props) {
  const { officeName } = useParams();
  const { role } = props;
  const [showBatMenu, setShowBatMenu] = useState(false);

  const officeState = useSelector((state) => state.office);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (officeName) {
      // console.log(officeName, role)
      dispatch(fetchOfficeData(officeName));
      dispatch(fetchUser({ officeName, role }));
    }
  }, [officeName,role]);

  // console.log(user.data)
  return (
    <>
      <Header
        role={role}
        isLoading={user.loading}
        userName={user.data["name"]}
        userImage={user.data["picture"]}
        notification={role === "admin" && user.data.notification}
        logo={
          officeState.data["logoImage"] == null
            ? "/assets/logo.svg"
            : officeState.data["logoImage"]
        }
        showBatMenu={showBatMenu}
        setShowBatMenu={setShowBatMenu}
      />
      <div id="split-window">
        <BatMenu
          role={role}
          officeName={officeName}
          showBatMenu={showBatMenu}
          setShowBatMenu={setShowBatMenu}
        />
        <main className={`${showBatMenu ? "open" : ""}`}>
          <Outlet />
          <footer>
            A service provided by <a href="https://vooxhub.com" target="_blank">VooxHub</a> - 2023 © All rights reserved <br/>
            Developed by <a href="https://shield4Cloud.com" target="_blank">Shield4Cloud</a>
          </footer>
        </main>
      </div>
    </>
  );
}

export default Layout;
