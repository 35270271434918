import { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";
import { ImCross } from "react-icons/im";

import "./modal.scss";

function Modal({ title, children, show, onClose, width }) {
  const handleKeyDown = useCallback(
    (e) => {
      if ((e.charCode || e.keyCode) === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return createPortal(
    <CSSTransition in={show} timeout={{ enter: 0, exit: 300 }} unmountOnExit>
      <div className="modal" onClick={onClose}>
        <div
          className="modal-content"
          onClick={(e) => e.stopPropagation()}
          style={{ width: width }}
        >
          <div className="modal-header flex-row space-between">
            <div className="modal-title ">
              <h3>{title}</h3>
            </div>
            <div className="modal-close flex-row align-center">
              <ImCross onClick={onClose} />
            </div>
          </div>
          <div className="modal-body">{children}</div>
          <div className="modal-footer"></div>
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
}

export default Modal;
