function BoxCa(props) {
  const { stats } = props;

  function statsPourcentage(valeurActuelle, valeurPrecedente) {
    if (valeurPrecedente === 0 && valeurActuelle === 0) {
      return 0;
    }

    let augmentationEnPourcentage;
    if (valeurPrecedente !== 0) {
      augmentationEnPourcentage =
        ((valeurActuelle - valeurPrecedente) / valeurPrecedente) * 100;
    } else {
      augmentationEnPourcentage = 100; // Si la valeur précédente est 0, considérer une augmentation de 100%
    }

    if (Number.isNaN(augmentationEnPourcentage)) {
      return 0;
    }

    return Math.round(augmentationEnPourcentage);
  }

  return (
    <div className="box box-ca">
      <h3>Chiffre d’affaires du cabinet</h3>
      <div className="col-2">
        <div>
          <span className="sub-title-gray">
            Ce mois-ci{" "}
            <span className="badge">
              {statsPourcentage(stats.monthGain, stats.lastMonthGain)}%
            </span>
          </span>

          <span className="primary-text">{stats.monthGain} €</span>
          <span className="sub-text">Le mois précédent</span>
          <span className="secondary-text">{stats.lastMonthGain} €</span>
        </div>
        <div>
          <span className="sub-title-gray">
            Aujourd'hui
            <span className="badge">
              {" " + statsPourcentage(stats.todayGain, stats.yesterdayGain)}%
            </span>
          </span>

          <span className="primary-text">{stats.todayGain} €</span>
          <span className="sub-text">Hier</span>
          <span className="secondary-text">{stats.yesterdayGain} €</span>
        </div>
      </div>
    </div>
  );
}

export default BoxCa;
